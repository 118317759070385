const EditionQueries = {
	getEditionsAdmin: `
		query getAlleditions($limit: Int, $start: Int, $sort:String, $subcategory: JSON  ) {
			editions(where : { subcategory : $subcategory},publicationState: PREVIEW ,  limit:$limit, start:$start, sort: $sort ) {
				id
				title
				published_at
				subcategory{
					id
					category{
						id
					}
				}
			}
		}
	`,
	getAllEditionsAdmin: `
		query getAlleditions( $where : JSON ) {
			editions(where : $where ,publicationState: PREVIEW ) {
				id
			}
		}
	`,
	editionCount: `
		query editionCount( $where : JSON ) {
			editionsConnection(where : $where ){
        aggregate{
          count
          totalCount
        }
      }
		}
	`,
	editionUrlData: `
		query edition($edition_id: ID!){
			edition(id: $edition_id, publicationState: PREVIEW ){
				id
				published_at
				subcategory{
					id
					category{
						id
					}
				}
			}
		}
	`,
	get: `
		query getEdition($edition_id: ID!) {
			edition(id: $edition_id, publicationState : PREVIEW) {
				id
				title
				subtitle
				public
				subcategory{
					id
					name
				}
				url
				date
				cover_image{
					id
					name
					previewUrl
					url
					formats
				}
				file_content
				featured
				long_tail
				content_summary
				archived
				attachment {
					id
					media {
						id
						name
						size
						url
						previewUrl
					}
				}
				author_editions{
					id
					main
					author {
						id
						first_name
						last_name
					}
				}
				edition_sections{
					id
					section {
						id
					}
					position
				}
				contents{
					id
					section{
						id
						name
					}
					title
					url
					body
					position
				}
				tags{
					id
					name
				}
				published_at
			}
		}
	`,
	findEditionByTitle: `
		query getEdition($title: String!) {
			editions(where: { title: $title }) {
				id
				title
			}
		}
	`,
	findEditionById: `
		query getEdition($id: ID!) {
			editions(where: { id: $id }) {
				id
				title
			}
		}
	`,
	count: `
		query countEdition( $where :JSON , $publicationState : PublicationState) {
			editionCount( where : $where , publicationState : $publicationState)
		}
	`,
	delete: `
		mutation deleteEdition(
			$id: ID!,
		){
			deleteEdition( input : { where : { id : $id } }){
				edition{
					id
				}
			}
		}
	`,
	create: `
		mutation createEdition (
			$subcategory: ID!,
			$title : String!,
			$subtitle: String,
			$date: Date,
			$archived: Boolean,
			$featured: Boolean,
			$url: String,
			$longTail: Boolean,
			$contentSummary: Boolean,
			$fileContent: String,
			$tags: [ID],
			$published_at: DateTime
			$coverImage : ID,
			$public: Boolean
		){
			createEdition(
				input : {
					data : {
						subcategory: $subcategory,
						title : $title,
						subtitle: $subtitle,
						date: $date,
						archived: $archived,
						featured: $featured,
						url: $url,
						cover_image : $coverImage,
						long_tail: $longTail,
						content_summary: $contentSummary,
						file_content: $fileContent,
						tags: $tags
						published_at: $published_at,
						public: $public
					}
				}
			) {
				edition {
					id
				}
			}
		}
		
	`,
	update: `
		mutation updateEdition(
			$editionId: ID!
			$subcategory: ID!,
			$title : String!,
			$subtitle: String,
			$date: Date,
			$archived: Boolean,
			$featured: Boolean,
			$url: String,
			$longTail: Boolean,
			$contentSummary: Boolean,
			$fileContent: String,
			$coverImage : ID,
			$authorEditions: [ID],
			$contents:[ID],
			$editionSections:[ID],
			$tags:[ID]
			$published_at: DateTime,
			$public: Boolean
		){
			updateEdition(
				input : {
					where : { id : $editionId }, 
					data : {
						subcategory: $subcategory,
						title : $title,
						subtitle: $subtitle,
						date: $date,
						archived: $archived,
						featured: $featured,
						url: $url,
						long_tail: $longTail,
						content_summary: $contentSummary,
						file_content: $fileContent,
						tags: $tags
						cover_image : $coverImage,
						author_editions: $authorEditions,
						contents : $contents,
						edition_sections : $editionSections,
						published_at: $published_at,
						public: $public
					}
				}				
			){
				edition {
					id
				}
			}
		}
	`,
	getEditionForAlgoliaDataUpdate: `
		query getByID($id: ID!) {
			edition(id: $id) {
				id
				title
				date
				contents {
					body
				}
				subcategory {
					id
					category {
						id
					}
				}
			}
		}  
	`
}


export default	EditionQueries
