import React from 'react';
import {
  InputLabel,
} from '@material-ui/core'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useField } from 'formik'

export default function RichTextInput({
  type, // "description "
  name,
  otherData, // Object containing other data on the rich text content (e.g.: For the Content model, this will be { id: "ckhrgkxo80dcv0a48kpmsm0ie" })
  label, // For editions, this should be the section under which the content belongs
}) {

  const [field] = useField(name)

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']
    ],
  }

  let formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  if(type === 'description') {
    modules.toolbar = [
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			['link'],
		]
  }

  return (
			<div>
				<InputLabel>{label}</InputLabel>
				<ReactQuill
					theme="snow"
					value={field.value}
					onChange={field.onChange(field.name)}
					modules={modules}
					format={formats}
				/>
			</div>
	)
}