import React from 'react'
import { TextField } from '@material-ui/core'
import { useField } from 'formik'

export default function Text ({ name, ...otherProps }) {
	const [field, meta] = useField(name)

	const configField = {
		...field,
		fullWidth: true,
		variant: 'outlined',
		size: 'small',
		...otherProps,
	}

	if (meta && meta.touched && meta.error) {
		configField.error = true
		configField.helperText = meta.error
	}

	return <TextField {...configField} />
}
