import React from 'react'
import Skeleton from "@material-ui/lab/Skeleton"
import {
  Container,
  Box,
  Typography
} from "@material-ui/core"

import { FeedCard, FeaturedCard } from "../"

const CardContainer = ({
	classes,
	cardList,
	cardType,
	heading,
	subheading,
}) => {
	// move 'Here's where to find...' to the top
	cardList = cardList && cardList.sort(
		(x, y) => (x.id === "266") ? 
								-1 : 
								(y.id === "266") ? 
									1 : 
									0
	)

	return (
		<Container>
			{!cardList && (
				<Skeleton variant="rect" animation="wave" width="100%" height={500} />
			)}
	
			{
				// in case there happens to be only either featured or feed items, we don't want to render just the heading of the container without cards, so we check if the array is empty first
				cardList?.length > 0 ? (
					<>
						<Typography className={classes.heading} component="h2">
							{heading}
						</Typography>
						<Typography className={classes.subHeading} component="h2">
							{subheading}
						</Typography>
						<Box className={classes.cardContainer}>
							{cardList.map((feedItem) =>
								cardType === 'featured' ? (
									<FeaturedCard
										key={feedItem.id}
										cardData={feedItem}
										category={feedItem.subcategory.category}
									/>
								) : (
									<FeedCard
										key={feedItem.id}
										cardData={feedItem}
										category={feedItem.subcategory.category}
									/>
								)
							)}
						</Box>
					</>
				) : null
			}
		</Container>
	)
}

export default CardContainer