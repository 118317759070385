import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Form,Text } from '../'
import {	Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { commonFormStyles } from '../../styles'



const useStyles = makeStyles((theme) => ({
	...commonFormStyles(theme),
}))

//Simple dialog form to get a name of a new thing
export default function DialogForm({title ,submitText , fields, onClose ,dialogState, formState , onSubmit , onCancel}) {

  const classes = useStyles()

  const initialValues = {}

  const validationSchema = {}


  fields.forEach( (field) => {
    initialValues[field] = ''
    validationSchema[field] = Yup.string().required('Required')
  })

  return (
		<Dialog onClose={onClose} open={dialogState}>
			<DialogTitle>{title}</DialogTitle>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={Yup.object(validationSchema)}>
				<Form
					classes={classes}
					submitButtonText={submitText}
					performingAction={formState}
					onCancel={onCancel}>
					<FormikForm>
						{fields.map((field , i) => (
							<div key={i}>
								<Typography>{field}</Typography>
								<Text name={field}></Text>
							</div>
						))}
					</FormikForm>
				</Form>
			</Formik>
		</Dialog>
	)
}
