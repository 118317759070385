import { GraphqlRequest } from '../../../services'
import { EditionSectionsQueries, ContentQueries } from '../../../strapiQueries'

async function updateEditionSections(editionId, existingSections , existingContent , updatedData) {

	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient
  
  const existinSectionIds = existingSections.map((item) => (item.id))
  const existinContentIds = existingContent.map((item) => (item.id))

  const updatedSectionIds = []
  const updatedContentIds = []

  for (const [sectionPosition, sectionData] of updatedData.entries()) {
		if (!sectionData.id) {
			try {
				const createRes = await strapiGraphqlRequestClient.request(
					EditionSectionsQueries.create,
					{
						edition: editionId,
						section: sectionData.section,
						position: sectionPosition + 1,
					}
				)
				updatedSectionIds.push(createRes.createEditionSection.editionSection.id)
			} catch (error) {
				console.log('Error Creating Edition section', error)
			}

      await updateContentData(sectionData, editionId, updatedContentIds)
		
		} else {

			await strapiGraphqlRequestClient.request(
				EditionSectionsQueries.update,
				{
					id: sectionData.id,
					section: sectionData.section,
					edition: editionId,
					position: sectionPosition + 1,
				}
			)

			updatedSectionIds.push(sectionData.id)
			await updateContentData(sectionData, editionId, updatedContentIds)
		}
	}

  try {
    
    for (const section of existinSectionIds) {
			if (!updatedSectionIds.includes(section)) {
				 await strapiGraphqlRequestClient.request(
					EditionSectionsQueries.delete,
					{ id: section }
				)
			}
		}
		for (const content of existinContentIds) {
			if (!updatedContentIds.includes(content)) {
				 await strapiGraphqlRequestClient.request(
					ContentQueries.delete,
					{ id: content }
				)
			}
		}


  } catch (error) {
    console.log("error deleting edition section/content entries" , error)
  }  
  
}

const updateContentData = async (sectionData ,editionId, updatedContentIds ) => {

	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	for (const [
		contentPosition,
		contentData,
	] of sectionData.content.entries()) {
		const { id, title, url, body } = contentData

		if (!id) {
			try {
				const createdRes = await strapiGraphqlRequestClient.request(
					ContentQueries.create,
					{
						edition: editionId,
						section: sectionData.section,
						position: contentPosition + 1,
						title,
						url,
						body,
					}
				)

				updatedContentIds.push(createdRes.createContent.content.id)
			} catch (error) {
				console.log('Error Creating Content', error)
			}
		} else {
			try {
				const updatedRes = await strapiGraphqlRequestClient.request(
					ContentQueries.update,
					{
						id,
						edition: editionId,
						section: sectionData.section,
						position: contentPosition + 1,
						title,
						url,
						body,
					}
				)

				updatedContentIds.push(updatedRes.updateContent.content.id)
			} catch (error) {
				console.log('Error Creating Content', error)
			}
		}
	}
}

export default updateEditionSections