const HermesProductIdsQueries = {
	add: `
		mutation createHermesProductId(
			$product_id: Long
			$subcategory_id: ID
			$newsproduct_id : ID
		) {
			createHermesProductId(
				input: {
					data: {
						product_id: $product_id
						subcategory: $subcategory_id
						news_product : $newsproduct_id
					}
				}
			) {
				hermesProductId {
					id
					product_id
					subcategory {
						id
          }
				}
			}
		}
	`,
	delete: `
		mutation deleteHermesProductId(
		$id: ID!,
		){
			deleteHermesProductId( input : { where : { id : $id } }){
				hermesProductId{
					id
				}
			}
		}
	`,
}

export default HermesProductIdsQueries
