import React from 'react'
import {
  Backdrop,
  Container,
  Grid,
  CircularProgress,
  makeStyles
} from '@material-ui/core'

import { LinearProgressWithLabel } from "../"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
}));

export default function LoadingBackdrop({ loading, mutationProgress }) {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <Container>
        <Grid container direction="column" alignItems="center" justifyContent="space-between" spacing={5}>
          <Grid item style={{ width: "100%" }}>
            <LinearProgressWithLabel
              progress={mutationProgress}
            />
          </Grid>

          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Container>
    </Backdrop>
  )
}