import React from 'react'
import { Card, CardContent, Button, CardActions } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle';

export default function DraggableCard({ classes, name, draggable, dragHandle, handleDelete}) {

	return (
		<Card className={classes.subcategoryCard}>
			{draggable ? (
				<CardActions {...dragHandle}>
					<Button>
						<DragHandleIcon />
					</Button>
				</CardActions>
			) : null}
			<CardContent>
				<span>{name}</span>
			</CardContent>
			<CardActions>
				<Button onClick={handleDelete}>
					<DeleteIcon fontSize="small" color="primary" />
				</Button>
			</CardActions>
		</Card>
	)
}
