import React from "react"
import {
  MenuItem,
  Menu,
} from "@material-ui/core"

const OptionsMenu = ({ anchor, id, isOpen, handleOnClose, openResetPassword, signOut }) => (
  <Menu
    anchorEl={anchor}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    id={id}
    keepMounted
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    open={isOpen}
    onClose={handleOnClose}
  >
    <MenuItem onClick={openResetPassword}>Reset Password</MenuItem>
    <MenuItem onClick={signOut}>Sign Out</MenuItem>
  </Menu>
)

export default OptionsMenu