import React, { Component } from "react"
import { Link as RouterLink } from "react-router-dom"
import * as Sentry from '@sentry/react' // for error logging
import {
  withStyles,
  Container, 
  Link
} from "@material-ui/core"
import _ from 'lodash'

import { GraphqlRequest } from "../../services"
import {
  Page,
  CardContainer,
  ButtonContainer
} from "../../blocks"
import { DashboardQueries} from "../../strapiQueries"
import { commonPageStyles } from "../../styles"

const styles = (theme) => ({ ...commonPageStyles(theme) })

class Dashboard extends Component {
	state = {
		ccData: null,
		nonCCData: null,
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	getFrontPageEditions = async () => {
		const { products } = this.props

		try {
			const EditionData = await this.strapiGraphqlRequestClient.request(
				DashboardQueries.getFeatured,
				{
					SubscribedList: products,
				}
			)

			const data = EditionData.categoriesConnection.values

			const editions = []

			data.forEach((category) => {
				const { open, subscribed } = category

				open.forEach((subcategory) => {
					editions.push(...subcategory.editions)
				})

				subscribed.forEach((subcategory) => {
					editions.push(...subcategory.editions)
				})
			})

			return _.orderBy(editions, ['date'], ['desc'])
		} catch (err) {
			console.log('Error Fetching frontpage Editions', err)
		}
	}

	getData = async () => {
		const { openDialog } = this.props

		let cc = []
		let nonCC = []

		try {
			const data = await this.getFrontPageEditions()

			data.map((edition) => {
				if (edition.long_tail) {
					return cc.push(edition)
				} else {
					return nonCC.push(edition)
				}
			})

			this.setState({
				ccData: cc,
				nonCCData: nonCC,
			})
		} catch (err) {
			Sentry.captureException(err)
			openDialog(err)
			console.log(err) // GraphQL response errors
		}
	}

	componentDidMount() {
		this.getData()

		// Segment page load tracking code
		window.analytics.page('Homepage', {
			title: 'Home | Frontier Research',
		})
	}

	render() {
		const { ccData, nonCCData } = this.state

		const { classes, products } = this.props

		return (
			<Page title="Home">
				<Container className={classes.contentContainer} maxWidth="lg">
					<CardContainer
						classes={classes}
						cardList={nonCCData}
						cardType="feed"
						heading="Featured"
					/>
					<ButtonContainer
						classes={classes}
						heading="Want to Dive Deeper? Jump to Our Subcategories"
						longTail={false}
						products={products}
					/>
					<CardContainer
						classes={classes}
						cardList={ccData}
						cardType="feed"
						heading={
							<span>
								Feeling Curious? Head to the{' '}
								<Link
									to="/extended-content"
									component={RouterLink}
									color="secondary">
									Curious Corner!
								</Link>
							</span>
						}
						subheading="Check out a curated collection of additional research and content from us that you may find interesting!"
					/>
					<ButtonContainer
						classes={classes}
						subheading="Subcategories under Curious Corner:"
						longTail={true}
						products={products}
					/>
				</Container>
			</Page>
		)
	}
}

export default withStyles(styles)(Dashboard)
