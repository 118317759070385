function FeedCardStyles(theme) {
  return ({
    root: {
      width: "96%",
      height: 'auto',
      borderRadius: 5,
      display: 'flex',
      [theme.breakpoints.up("md")]: {
        height: '230px',
      },
    },
    textContent: {
      width: "70%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      [theme.breakpoints.up("sm")]: {
        width: "60%",
      },
    },
    mediaContent: {
      width: "30%",
      [theme.breakpoints.up("sm")]: {
        width: "40%",
      },
    },
    media :{
      height: '100%'
    },
  })
}

export default FeedCardStyles