import React, { Component } from "react"
import { withStyles, Container } from "@material-ui/core"
import { GraphqlRequest } from "../../services"
import {
    Page,
    CardContainer,
    ButtonContainer,
    ConfirmationDialog,
    LoadingBackdrop,
    LoadMoreButton,
    Info,
    AdminOptions,
} from "../../blocks"
import _ from "lodash"

import { CategoryPageQueries } from "../../strapiQueries"
import { commonPageStyles } from "../../styles"
import { filterUnauthorizedEditions } from "../../helpers"
import categoryLinks from "../../../redirectData/categories.json"

const styles = (theme) => commonPageStyles(theme)

class Category extends Component {
    state = {
        category: null,
        featured: null,
        feed: null,

        openDialog: false,
        loading: false,
        mutationProgress: 0,

        hasNextPage: true,
        endCursor: 0,
        loadingFeed: false,
    }

    strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

    getInitialData = async () => {
        const {
            openDialog,
            match: { params },
            products,
            history,
            location,
        } = this.props

        const dataPath = location.pathname.replace(/\/$/, "")

        if (dataPath in categoryLinks) {
            history.push(`/${categoryLinks[dataPath]}`)
            return
        }

        try {
            if (isNaN(params.categoryId)) {
                window.location.href = "/error404"
                return
            }

            const categoryDataRes =
                await this.strapiGraphqlRequestClient.request(
                    CategoryPageQueries.getCategoryData,
                    {
                        categoryId: params.categoryId,
                    }
                )

            if (!categoryDataRes.category) {
                window.location.href = "/error404"
                return
            }

            this.setState(
                {
                    category: categoryDataRes.category,
                },
                () => {
                    window.analytics.page(
                        `${categoryDataRes.category.name} Page`,
                        {
                            title: `${categoryDataRes.category.name} | Frontier Research`,
                        }
                    )
                }
            )

            const featuredDataRes =
                await this.strapiGraphqlRequestClient.request(
                    CategoryPageQueries.getFeatured,
                    {
                        categoryId: params.categoryId,
                        subscribedList: products,
                        longTail: false,
                    }
                )

            const data = featuredDataRes.categoriesConnection.values[0]

            const featuredEditions = []

            const { open, subscribed } = data

            open.forEach((subcategory) => {
                featuredEditions.push(...subcategory.editions)
            })

            subscribed.forEach((subcategory) => {
                featuredEditions.push(...subcategory.editions)
            })

            this.setState({
                featured: _.orderBy(featuredEditions, ["date"], ["desc"]),
            })

            const feedDataRes = await this.strapiGraphqlRequestClient.request(
                CategoryPageQueries.getFeed,
                {
                    categoryId: params.categoryId,
                    longTail: false,
                    start: 0,
                    limit: 10,
                }
            )

            const count = feedDataRes.editionsConnection.values.length

            const feedEditions = filterUnauthorizedEditions(
                feedDataRes.editionsConnection.values,
                products
            )

            this.setState({
                feed: feedEditions,
                endCursor: this.state.endCursor + count,
                hasNextPage: count > 9,
            })
        } catch (error) {
            openDialog(error)
            console.log(error)
        }
    }

    unpublishCategory = async () => {
        this.setState({
            loading: true,
            openDialog: false,
            mutationProgress: 10,
        })
        //TODO
        // const { category: { id: categoryId } } = this.state

        // Unpublish Category

        // Get SubCategory Ids

        // Unpublish SubCategories

        // Get the ids for sections and editions from the return value of the unpublished SubCategory

        // Unpublish Sections

        // Unpublish Editions

        // Get ids for the content under the unpublished editions and unpublish those contents

        // Move back to the main category
        this.setState({ loading: false, mutationProgress: 100 })
        window.location.href = "/"
    }

    handleLoadMore = async () => {
        const {
            openDialog,
            match: { params },
            products,
        } = this.props
        const { endCursor, feed } = this.state

        this.setState({ loadingFeed: true })

        try {
            const feedDataRes = await this.strapiGraphqlRequestClient.request(
                CategoryPageQueries.getFeed,
                {
                    categoryId: params.categoryId,
                    longTail: false,
                    start: endCursor,
                    limit: 10,
                }
            )

            const count = feedDataRes.editionsConnection.values.length

            const newFeed = filterUnauthorizedEditions(
                feedDataRes.editionsConnection.values,
                products
            )

            this.setState({
                feed: [...feed, ...newFeed],
                endCursor: this.state.endCursor + count,
                hasNextPage: count > 9,
                loadingFeed: false,
            })
        } catch (error) {
            openDialog(error)
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    componentDidUpdate(prevProps) {
        const {
            match: { params },
        } = this.props

        if (params.categoryId !== prevProps.match.params.categoryId) {
            this.setState({ hasNextPage: true })

            this.getInitialData()
        }
    }

    handleDialogOpen = () => {
        this.setState({ openDialog: true })
    }

    handleDialogClose = () => {
        this.setState({ openDialog: false })
    }

    render() {
        const {
            featured,
            feed,
            category,
            openDialog,
            loading,
            mutationProgress,
            hasNextPage,
            loadingFeed,
        } = this.state

        const { classes, userRole, products } = this.props

        const breadcrumbs = [
            {
                content: category ? category.name : "Loading...",
                href: null,
            },
        ]

        return (
            <Page
                className={classes.breadcrumbsContainer}
                title={`${category ? category.name : "Loading..."}`}
                breadcrumbs={breadcrumbs}
            >
                <Container
                    className={classes.breadcrumbsContainer}
                    maxWidth="lg"
                >
                    <AdminOptions
                        userRole={userRole}
                        editLink={`/admin/category/edit/${
                            category ? category.id : null
                        }`}
                        deleteHandler={this.handledialogOpen}
                        disabled={category ? false : true}
                        modelName="Sub-Category"
                    />
                </Container>
                <Container
                    className={classes.breadcrumbsContainer}
                    maxWidth="lg"
                >
                    <Info
                        classes={classes}
                        title="In this section:"
                        content={category ? category.description : "Loading..."}
                    />
                </Container>

                <Container className={classes.contentContainer} maxWidth="lg">
                    <CardContainer
                        classes={classes}
                        cardList={featured}
                        cardType="featured"
                        heading={category ? `Featured ${category.name}:` : null}
                    />
                    <ButtonContainer
                        classes={classes}
                        heading={
                            category
                                ? `Jump to Subcategories under ${category.name}:`
                                : null
                        }
                        category={category ? category : null}
                        longTail={false}
                        products={products}
                    />
                    <CardContainer
                        classes={classes}
                        cardList={feed}
                        cardType="feed"
                        heading="Recent and Beyond:"
                    />
                    <LoadMoreButton
                        classes={classes}
                        hasNextPage={hasNextPage}
                        handleLoadMore={this.handleLoadMore}
                        loadingFeed={loadingFeed}
                    />
                </Container>

                <LoadingBackdrop
                    loading={loading}
                    mutationProgress={mutationProgress}
                />

                <ConfirmationDialog
                    open={openDialog}
                    handleClose={this.handleDialogClose}
                    handleSubmit={this.unpublishCategory}
                    alertSeverity={"warning"}
                    title="Hold Up!"
                    textContent={`Are you sure you want to un-publish "${
                        category ? category.name : null
                    }"`}
                    alertContent={
                        <>
                            This will un-publish{" "}
                            <strong>
                                all associated sub-categories, editions,
                                sections, content and attachments
                            </strong>{" "}
                            under this Category.
                            <br />
                            <br />
                            This action <strong>is</strong> reversible. Contact
                            the Tech Team to have it reversed, if you need to.
                        </>
                    }
                    confirmButtonText="Yes, I'm sure"
                    cancelButtonText="Cancel"
                />
            </Page>
        )
    }
}

export default withStyles(styles)(Category)
