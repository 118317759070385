import React from 'react'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { useField } from 'formik'

export default function DatePicker({ name, ...otherProps }) {
	const [field, meta, helpers] = useField(name)

	const configField = {
		...field,
		format: 'DD/MM/yyyy',
		name: name,
		onChange: (val) => {
      const date = new Date (val)
      const dateStr = `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`

      helpers.setValue(dateStr)
    },
		...otherProps,
	}

	if (meta && meta.touched && meta.error) {
		configField.error = true
		configField.helperText = meta.error
	}

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker { ...configField } />
    </MuiPickersUtilsProvider>
  )
}