const SubcategorySectionQueries = {
	add: `
		mutation addSubcategorySection(
			$section_id: ID!
			$subcategory_id: ID
			$default_position: Int
		) {
			createSubcategorySection(
				input: {
					data: {
						section: $section_id
						subcategory: $subcategory_id
						default_position: $default_position
					}
				}
			) {
				subcategorySection {
					id
					default_position
					subcategory {
						id
						name
					}
					section {
						id
						name
					}
				}
			}
		}
	`,
	getLastSubcategorySectionPosition: `
		query lastPosition($subcategory_id: ID!) {
			subcategorySections(
				where: { subcategory: $subcategory_id }
				sort: "default_position:DESC"
				limit: 1
			) {
				default_position
			}
		}
	`,
	getSections: `
		query getSubcategorySection( $subcategory_id: ID!) {
			subcategorySections(
				where: { subcategory: $subcategory_id},
				sort: "default_position:ASC"
			) {
				id
				default_position
				section {
					name
					id
				}
			}
		}
	`,
	delete: `
		mutation deleteSubcategorySection(
		$id: ID!,
		){
			deleteSubcategorySection( input : { where : { id : $id } }){
				subcategorySection{
					id
				}
			}
		}
	`,
	update: `
		mutation updateSubcategorySection(
			$subcategorySection_id: ID!
			$section_id: ID
			$subcategory_id: ID
			$default_position: Int
		) {
			updateSubcategorySection(
				input: {
					where : { id : $subcategorySection_id },
					data: {
						section: $section_id
						subcategory: $subcategory_id
						default_position: $default_position
					}
				}
			) {
				subcategorySection {
					id
					default_position
					subcategory {
						id
						name
					}
					section {
						id
						name
					}
				}
			}
		}
	`,
}

export default SubcategorySectionQueries

