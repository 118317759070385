import settings from "../../settings"
const { GraphQLClient } = require('graphql-request')


class GraphqlRequest  {
  constructor() {
    const token = localStorage.getItem('token')
    // console.log(token)
    this.strapiClient = new GraphQLClient (`${settings.strapiURL}/graphql`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
    })
  }
}

export default GraphqlRequest