const settings = {
	title: 'Athena from Frontier Research',

	baseURL:
		process.env.NODE_ENV !== 'development'
			? 'https://frontier-internal.appspot.com'
			: 'https://frontier-internal.appspot.com',

	strapiURL:
		process.env.NODE_ENV !== 'development'
			? 'https://athena-backend-knyrxdjwma-df.a.run.app'
			: 'https://athena-backend-knyrxdjwma-df.a.run.app',
			// : 'http://localhost:1337',

	credentials: {
		firebase: {
			apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
			authDomain: 'frontier-internal.firebaseapp.com',
			databaseURL: 'https://frontier-internal.firebaseio.com',
			projectId: 'frontier-internal',
			storageBucket: 'frontier-internal.appspot.com',
			messagingSenderId: '632841321990',
			appId: '1:632841321990:web:4d66ecfc933afaeedfcd87',
		},
	},
}

export default settings
