import React from 'react';
import {
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useField } from 'formik'

export default function CheckBox({ name, label, ...otherProps }) {
	const [field, meta] = useField(name)

	const { value } = field

	const configField = {
		...field,
		...otherProps,
	}

	if (meta && meta.touched && meta.error) {
		configField.error = true
		configField.helperText = meta.error
	}

  return (
		<FormControlLabel
			label={label}
			control={<Checkbox checked={value} {...configField} />}
		/>
	)
}