
const AuthorQueries = {
	addAuthor: `
		mutation addAuthor(
			$first_name: String!
			$last_name: String!
			$published_at: DateTime
		) {
			createAuthor(
				input: {
					data: {
						first_name: $first_name
						last_name: $last_name
						published_at: $published_at
					}
				}
			) {
				author {
					first_name
					last_name
					id
				}
			}
		}
	`,
	findAuthorByName: `
		query getAuthor($first_name: String!, $last_name: String!) {
			authors(where: { first_name: $first_name, last_name: $last_name }) {
                id
				first_name
				last_name
				author_editions {
					id
				}
			}
		}
	`,
	getList: `
	query getAuthor($sort:String) {
			authors(sort: $sort) {
        id
				first_name
				last_name
			}
		}`,
}

export default AuthorQueries

