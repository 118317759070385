const SectionQueries = {
	add: `
		mutation addSection($name: String!) {
			createSection(input: { data: { name: $name } }) {
				section {
					name
					id
				}
			}
		}
	`,
	findSectionById: `
		query getSection($section_id: ID!) {
			section(id: $secion_id) {
				id
				name
			}
		}
	`,
	getAll: `
		query getAllSections($sort:String){
			sections(sort: $sort) {
				id
				name
			}
		}
	`,
}

export default SectionQueries