/* eslint-disable eqeqeq */
import React , { useState , useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import settings from '../../../settings'
import {
	Grid,
	Typography,
	Button,
	Tooltip,
	makeStyles,
	CardMedia,
	Switch as SwitchUi,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { SectionProvider} from './SectionContext'
import InputItems from './Components/InputItems'
import * as Yup from 'yup'
import { 
	Form,
	Text,
  SelectField,
  DatePicker,
  CheckBox,
  Autocomplete,
	DialogForm,
	Switch,
	LoadingBackdrop
} from '../../blocks'
import FileUpload from './Components/FileUpload'
import SectionForm  from './SectionForm/SectionForm'
import { TagQueries, SubcategorySectionQueries , EditionQueries , AttachmentQueries , AuthorQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'
import SectionSelect from './SectionSelect'
import updateAuthorEditions from './Services/AuthorEditions'
import updateEditionSections from './Services/EditionSections'
import { commonFormStyles } from '../../styles'
import { usePathHistory, algoliaDataUpdate } from '../../helpers'

const useStyles = makeStyles((theme) => ({
	...commonFormStyles(theme),
	coverImage: {
		height: 140,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	coverImageContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	defaultText: {
		fontSize: '0.8rem',
		color: '#9e9e9e',
		fontStyle: 'italic',
	},
	EditionSectionsheader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: theme.spacing(1),
	},
	actionFormFiled: {
		display: 'flex',
		alignItems: 'center',
	},
}))


export default function NewEditionForm({
	action,
	subcategoryList,
	teamList,
	tagList,
	sectionList,
	edition,
	formValues,
}) {

	const pathHistory = usePathHistory()

	const classes = useStyles()
	const [ttEdition, setTtEdition] = useState(false)
	const [hasVideo , setHasVideo] = useState(false)
	const [tags, setTags] = useState(tagList)
	const [team , setTeam] = useState(teamList)
	const [editionSections, setEditionSections] = useState([])

	const [openTagDialog, setOpenTagDialog] = useState(false)
	const [creatingTag, setCreatingTag] = useState(false)
	const [openCreateAuthorDialog, setOpenCreateAuthorDialog] = useState(false)
	const [creatingAuthor, setCreatingAuthor] = useState(false)
	const [openSectionSelect , setOpenSectionselect] = useState(false)
	const [coverImage, setCoverImage] = useState(null)
	const [uploadingImage , setUploadingImage] = useState(false)
	const [attachments , setAttachments] = useState([])
	const [uploadingAttachment , setUploadingAttachment] = useState(false)
	const [submittingForm , setSubmittingForm] = useState(false)
	const [submitProgress, setSubmitprogress] = useState(0)
	// console.log(formValues)

	//POPULATE THE FORM IF EDITING AN EDITION
	useEffect(() => {
		if(edition) {
			const { contents, cover_image, edition_sections , url, attachment} = edition

			const existingEditionSections = []

			edition_sections.forEach((element) => {
				const { id, section, position } = element
				existingEditionSections[position-1] = {
					id,
					section: section.id,
					content: [],
				}
			})

			contents.forEach((content) => {
				const { section, position, id, body, title, url } = content

				const index = existingEditionSections.findIndex(
					(editionSection) => editionSection.section == section.id
				)

				try {
					existingEditionSections[index]['content'][position - 1] = {
						id,
						title,
						url,
						body,
					}
				} catch (error) {
					console.log(error)
				}
			})

			setEditionSections(existingEditionSections)
			setCoverImage(cover_image)
			setHasVideo(url)
			if (attachment) setAttachments(attachment.media)
		}
		
	}, [edition])


	// HELPER FUNCTIONS
	async function fetchSubcategorySections(subcategory_id) {
		const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

		const fetchedSections = await strapiGraphqlRequestClient.request(
			SubcategorySectionQueries.getSections,
			{
				subcategory_id,
			}
		)

		const state = fetchedSections.subcategorySections.map(
			(subcategorySection) => ({
				section: subcategorySection.section.id,
				id: null,
				content: [
					{
						id: null,
						title: '',
						url: '',
						body: '',
					},
				],
			})
		)

		setEditionSections(state)
	}

	const createTag = async (values) => {
		const { Name } = values

		const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

		setCreatingTag(true)

		const createTagRes = await strapiGraphqlRequestClient.request(
			TagQueries.addTag,
			{
				name: Name,
			}
		)
		setTags([ createTagRes.createTag.tag, ...tags])
		setCreatingTag(false)
		setOpenTagDialog(false)
	}

	const createAuthor = async (values) => {
		const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

		const inputVars= {
			first_name : values["First Name"].charAt(0).toUpperCase() + values["First Name"].slice(1) , 
			last_name : values["Last Name"].charAt(0).toUpperCase() + values["Last Name"].slice(1) 
		}

		setCreatingAuthor(true)

		const createAuthorRes = await strapiGraphqlRequestClient.request(
			AuthorQueries.addAuthor,
			inputVars
		)
		
		const newAuthor = createAuthorRes.createAuthor.author

		setTeam([ {
			id : newAuthor.id,
			name : `${newAuthor.first_name} ${newAuthor.last_name}`
		}, ...team])

		setCreatingAuthor(false)
		setOpenCreateAuthorDialog(false)
	}


	const uploadFile = async (file) => {
		const token = localStorage.getItem('token')
		const data = new FormData()
		data.append('files', file)

		try {
			const res = await fetch(`${settings.strapiURL}/upload`, {
				method: 'POST',
				headers: {
					Authorization: token ? `Bearer ${token}` : '',
				},
				body: data,
			})

			const uploadedFile = await res.json()
			return uploadedFile[0]
		} catch (err) {
			console.log(err)
			return false
		}
	}

	function delay() {
		return new Promise((resolve, reject) => {
			setTimeout(resolve , 2000)
		})
	}



	// FORM STATE HANDLERS
	const handleTTswitchChange = (e) => {
		setTtEdition(e.target.checked)
	} 

	const subcategoryChange = async (id) => {
		if (id) {
			await fetchSubcategorySections(id)
		}
	}

	const handleSectionSelect = (values) => {
		const {sections} = values
		const newState = []

		sections.forEach((section, index) => {
			if( editionSections.filter((element) => (element.section === section.id)).length ) {			
				const existingIndex = editionSections.findIndex((element) => (element.section === section.id))

				if(existingIndex != index) {
					newState[index] = editionSections[existingIndex]
				}else{
					newState[index] = editionSections[index]
				}

			}else{
				newState[index] = {
					section : section.id,
					id: null , 
					content : [
						{
							id : null,
							title: '',
							url: '',
							body: ''
						}
					]
				}
			}
		})

		setEditionSections([...newState])
		setOpenSectionselect(false)
	}

	const handleCoverImageInput =async (e) => {
		setUploadingImage(true)
		const file = e.target.files[0]
		const uploadedFile = await uploadFile(file)

		if(uploadedFile) {
			
			const { id , name , url , formats } = uploadedFile
			setCoverImage({ id, name, url, formats })

		}else {
			console.log("uploading failed")
		}

		setUploadingImage(false)

	}

	const handleAttachmentInput = async (e) => {
		setUploadingAttachment(true)
		const file = e.target.files[0]
		const uploadedFile = await uploadFile(file)

		if(uploadedFile) {
			const { id, name } = uploadedFile
			setAttachments([
				...attachments,
				{
					id,
					name,
				},
			])
		}else {
			console.log('uploading failed')
		}

		setUploadingAttachment(false)
	}

	const handleImageClear = () => {
		setCoverImage(null)
	}

	const handleAttachmentClear = (index) => {
		
		const newAttachments = attachments
		newAttachments.splice(index, 1)
		setAttachments([...newAttachments])
	}

	const handleSubmit = async (values) => {
		setSubmittingForm(true)

		const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

		let editionId
		const {subcategory, title , subtitle , fileContent, url , archived, contentSummary, date, featured, longTail, published, tags, mainTeam , supportTeam, public: publicEdition } = values

		
		let editionFormData = {
			subcategory: parseInt(subcategory),
			title,
			subtitle,
			fileContent,
			url : hasVideo ? url : null ,
			archived,
			contentSummary,
			date ,
			featured,
			longTail,
			published_at: published ? new Date().toISOString() : null,
			tags: tags.map((tag) => (parseInt(tag.id))),
			coverImage : coverImage ? coverImage.id : null,
			public: publicEdition
		}	
	
		if(!edition) {
			
			try {

				const createdEdition = await strapiGraphqlRequestClient.request(
					EditionQueries.create,
					editionFormData
				)

				setSubmitprogress(30)

				editionId = createdEdition.createEdition.edition.id
				await strapiGraphqlRequestClient.request(
					AttachmentQueries.create,
					{
						edition: editionId,
						media: attachments.map((item) => item.id),
					}
				)
				setSubmitprogress(40)

				updateAuthorEditions(editionId, [], mainTeam, supportTeam)
				setSubmitprogress(60)


				await updateEditionSections(
					editionId,
					[],
					[],
					editionSections
				)

				setSubmitprogress(100)


			} catch (error) {
				console.log(error)
			}
		} else {
			editionId = edition.id
			editionFormData = {
				...editionFormData,
				editionId,
			}

			try {

				await strapiGraphqlRequestClient.request(
					EditionQueries.update,
					editionFormData
				)

				setSubmitprogress(30)

				if(!edition.attachment){
					 await strapiGraphqlRequestClient.request(
						AttachmentQueries.create,
						{
							edition: editionId,
							media: attachments.map((item) => item.id),
						}
					)
				}else{
					await strapiGraphqlRequestClient.request(
						AttachmentQueries.update,
						{
							id: edition.attachment.id,
							media: attachments.map((item) => item.id),
						}
					)
				}
				
				setSubmitprogress(40)

				await updateAuthorEditions(
					editionId,
					edition.author_editions,
					mainTeam,
					supportTeam
				)

				setSubmitprogress(60)
	

				await updateEditionSections(
					editionId,
					edition.edition_sections,
					edition.contents,
					editionSections
				)

				setSubmitprogress(100)
				await delay()
			} catch (error) {
				console.log("updateing error" , error)
			}

		}

		setSubmittingForm(false)
		try {
			
			const editionData = await strapiGraphqlRequestClient.request(
				EditionQueries.editionUrlData,
				{
					edition_id: editionId,
				}
			) 

			const editionDataForAgoliaUpdate = await strapiGraphqlRequestClient.request(
				EditionQueries.getEditionForAlgoliaDataUpdate, { id: editionId }
			)

			const {
				edition: {
					id: edition_id,
					published_at : isPublished,
					subcategory: {
						id: subcategory_id,
						category: { id: category_id },
					},
				},
			} = editionData

			if (isPublished){
				const { title: editionTitle, date: editionDate, contents, subcategory: editionSubcategory } = editionDataForAgoliaUpdate.edition
		
				const dataObject = {
					objectID: editionId,
					title: editionTitle,
					date: editionDate,
					contents,
					index: 'regularContent',
					subcategory: {
						id: editionSubcategory.id,
						category: {
							id: editionSubcategory.category.id
						}
					}
				}
				
				if (!edition) {
					console.log('add', dataObject)
					algoliaDataUpdate({ ...dataObject, operation: 'add' })
				} else {
					console.log('edit', dataObject)
					algoliaDataUpdate({ ...dataObject, operation: 'edit' })
				}

				pathHistory.push(`/${category_id}/${subcategory_id}/${edition_id}`)
			}else{
				pathHistory.push(`/admin/edition`)
			}

		} catch (error) {
			console.log(error)
		}
	}


	return (
		<>
			<Formik
				initialValues={{ ...formValues }}
				onSubmit={handleSubmit}
				validationSchema={Yup.object({
					title: Yup.string().required('Required'),
					subcategory: Yup.string().required('Required'),
					mainTeam: Yup.array().min(1),
					url: hasVideo
						? Yup.string().required(
								'Video url is required when "Has video" is checked.'
						  )
						: Yup.string().nullable(),
				})}>
				<Form
					classes={classes}
					submitButtonText={action === 'add' ? 'Add Edition' : 'Save Changes'}
					performingAction={submittingForm}>
					<FormikForm>
						<Grid container spacing={3} className={classes.borderedContainer}>
							<Grid item xs={6}>
								<div className={classes.formField}>
									<Typography>Subcategory</Typography>
									<SelectField
										items={subcategoryList}
										name="subcategory"
										changeHandler={subcategoryChange}
									/>
								</div>
								<div className={classes.formField}>
									<Typography>Date</Typography>
									<DatePicker name="date" />
								</div>
								<div className={classes.formField}>
									<Typography>Title</Typography>
									<Text name="title" />
								</div>
								<div className={classes.formField}>
									<Typography>Subtitle</Typography>
									<Text name="subtitle" />
								</div>
								<div className={classes.formField}>
									<div className={classes.actionFormFiled}>
										<Typography style={{ marginRight: '16px' }} className={classes.actionFormText}>
											Main team
										</Typography>
										<Tooltip title="Create a new Author" aria-label="add">
											<AddCircleIcon
												onClick={() => setOpenCreateAuthorDialog(true)}
												fontSize="small"
											/>
										</Tooltip>
									</div>
									<Autocomplete name="mainTeam" items={team} />
								</div>
								<div className={classes.formField}>
									<Typography>Support team</Typography>
									<Autocomplete name="supportTeam" items={team} />
								</div>
								<div className={classes.formField}>
									<div className={classes.actionFormFiled}>
										<Typography style={{ marginRight: '16px' }}>
											Tags
										</Typography>
										<Tooltip title="Create a new Tag" aria-label="add" fontSize="small">
											<AddCircleIcon onClick={() => setOpenTagDialog(true)} />
										</Tooltip>
									</div>
									<Autocomplete name="tags" items={tags} />
								</div>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>
										Published
									</Typography>
									<Switch name="published" />
								</div>
								<div className={classes.formField} style={{
									border: '1px solid #CB2026',
      						margin: '1em',
      						padding: '1em'
								}}>
									<Typography className={classes.formLable}>
										<strong>Public on Trial Version</strong> (not to toggle on unless specified to do so)
									</Typography>
									<Switch name="public" />
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.formField}>
									<Typography>Cover image</Typography>
									<div className={classes.coverImageContainer}>
										{coverImage ? (
											<>
												<CardMedia
													className={classes.coverImage}
													image={coverImage.url}
													title="Contemplative Reptile"
												/>
												<InputItems
													list={[coverImage]}
													handleClear={handleImageClear}
												/>
											</>
										) : (
											<div className={classes.coverImage}>
												<Typography className={classes.defaultText}>
													No Image added.
												</Typography>
											</div>
										)}
									</div>
								</div>
								<div className={classes.formField}>
									<div></div>
									<FileUpload
										name="coverImage"
										fileType="image"
										onInput={handleCoverImageInput}
										loading={uploadingImage}
									/>
								</div>
								<div className={classes.checkBoxGroup}>
									<CheckBox
										name="hasVideo"
										label="Has video"
										onClick={() => setHasVideo(!hasVideo)}
									/>
								</div>
								{hasVideo && (
									<div className={classes.formField}>
										<Typography>URL</Typography>
										<Text name="url" />
									</div>
								)}
								<div className={classes.formField}>
									<Typography>Attachment</Typography>
									<div>
										{attachments.length ? (
											<InputItems
												list={attachments}
												handleClear={handleAttachmentClear}
											/>
										) : (
											<Typography className={classes.defaultText}>
												No files uploaded Yet
											</Typography>
										)}
									</div>
								</div>
								<div className={classes.formField}>
									<div></div>
									<FileUpload
										name="attachment"
										fileType="pdf"
										onInput={handleAttachmentInput}
										loading={uploadingAttachment}
									/>
								</div>
								<div className={classes.formField}>
									<Typography>File Content</Typography>
									<Text name="fileContent" multiline rows="3" />
								</div>
								<div className={classes.checkBoxGroup}>
									<CheckBox name="featured" label="Featured" />
									<CheckBox name="longTail" label="Curious Corner" />
									<CheckBox name="archived" label="Archived" />
								</div>
								<div className={classes.checkBoxGroup}>
									<CheckBox name="contentSummary" label="Content summary" />
								</div>
							</Grid>
						</Grid>

						{
							// sections and content
						}
						<SectionProvider
							value={{ editionSections, setEditionSections, ttEdition }}>
							<Grid container spacing={3} className={classes.borderedContainer}>
								<Grid item xs={12}>
									<div className={classes.EditionSectionsheader}>
										<div className={classes.subsectionsHeader}>
											<Typography>Sections</Typography>
											<Button onClick={() => setOpenSectionselect(true)}>
												<AddCircleIcon />
											</Button>
										</div>
										<div className={classes.subsectionsHeader}>
											<Typography className={classes.formLable}>
												TT Edition
											</Typography>
											<SwitchUi
												name="ttEdition"
												checked={ttEdition}
												onChange={handleTTswitchChange}
											/>
										</div>
									</div>
								</Grid>
								{
									// follows the blocks of sections in this edition + content under those sections
								}
								{editionSections.map((section, index) => (
									<SectionForm
										key={index}
										SectionPosition={index}
										section={sectionList.find(
											(element) => element.id == section.section
										)}
									/>
								))}
							</Grid>
						</SectionProvider>
					</FormikForm>
				</Form>
			</Formik>
			<DialogForm
				title="Create a New Tag"
				dialogState={openTagDialog}
				formState={creatingTag}
				fields={['Name']}
				onClose={() => setOpenTagDialog(false)}
				onCancel={() => setOpenTagDialog(false)}
				onSubmit={createTag}
				submitText="Create Tag"
			/>
			<DialogForm
				title="Add New Team Member"
				dialogState={openCreateAuthorDialog}
				formState={creatingAuthor}
				fields={['First Name', 'Last Name']}
				onClose={() => setOpenCreateAuthorDialog(false)}
				onCancel={() => setOpenCreateAuthorDialog(false)}
				onSubmit={createAuthor}
				submitText="Add"
			/>
			<SectionSelect
				onClose={() => setOpenSectionselect(false)}
				dialogState={openSectionSelect}
				onSubmit={handleSectionSelect}
				onCancel={() => setOpenSectionselect(false)}
				sectionList={sectionList}
				initialValues={{
					sections: sectionList.filter(
						(section) =>
							editionSections.filter((element) => element.section == section.id)
								.length
					),
				}}
			/>
			<LoadingBackdrop
				loading={submittingForm}
				mutationProgress={submitProgress}
			/>
		</>
	)
}
