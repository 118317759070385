function mapAuthors(authors, stringArray) {
  
  authors.map((author, index, authorArray) => {
      
    if(!author) {
      return author
    }
      if (authorArray.length > 1) {
        if (authorArray.length - 1 > index) {
          if (authorArray.length - 2 === index) {
            // Don't place a comma before the one that comes just before the 'and'
            stringArray.push(`${author.first_name} ${author.last_name}`)
          } else {
            stringArray.push(`${author.first_name} ${author.last_name}, `)
          }
        } else {
          stringArray.push(` and ${author.first_name} ${author.last_name}`)
        }
      } else {
        stringArray.push(`${author.first_name} ${author.last_name}`)
      }

      return author // We return author due to es-lint rule of arrow funcions needing a return
    }
  )
}

function makeAuthorString(authors, coAuthors, subcategory) {

  let introString = ""

  if (subcategory.name === "Summary Notes") {
    introString += "Compiled by: "
  } else if (subcategory.category.name === "Videos") {
      introString += "In this Video: "
  } else {
    introString += "Written By: "
  }
  const stringArray = []

  mapAuthors(authors, stringArray)

  if (coAuthors && coAuthors.length > 0) {
    stringArray.push(" with ")
    mapAuthors(coAuthors, stringArray)
  }

  return `${introString} ${stringArray.join('')}`
}

export default makeAuthorString