import React, { useEffect, useState, useCallback , useMemo } from "react"
import * as Sentry from '@sentry/react' // for error logging
import Skeleton from "@material-ui/lab/Skeleton"
import { makeStyles, Box, Button } from "@material-ui/core"
import { Link } from "react-router-dom"

import { makeButtonLink } from "../../helpers"
import { ButtonStyles } from "../../styles"
import { ButtonQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'

const useStyles = makeStyles(theme => ({ ...ButtonStyles(theme) }))

export default function Buttons ({ current, buttonQuery, category, longTail , products}) {
  const [buttons, setButtons] = useState(null)
  const classes = useStyles()

	const strapiGraphqlRequestClient = useMemo(
		() => new GraphqlRequest().strapiClient,
		[]
	)

  const getData = useCallback(async () => {
		try {
			const res = await strapiGraphqlRequestClient.request(
				ButtonQueries.subcategories,
				{
					SubscribedList: products,
					categoryID: category,
					long_tail: longTail,
				}
			)

			const data = res.categoriesConnection.values[0]

			const subcategories = []

			data.open.forEach((item) => {
				if (item.id !== current) {
					// leave the subcategory that the user is currently accessing
					if (item.editions.length) {
						// leave subcategories with no editions in them
						subcategories.push({
							id: item.id,
							name: item.name,
						})
					}
				}
			})

			data.subscribed.forEach((item) => {
				if (item.id !== current) {
					// leave the subcategory that the user is currently accessing
					if (item.editions.length) {
						// leave subcategories with no editions in them
						subcategories.push({
							id: item.id,
							name: item.name,
						})
					}
				}
			})

			return subcategories
		} catch (err) {
			console.log(err) // GraphQL response errors
			Sentry.captureException(err)
		}
	}, [category, current, products, longTail, strapiGraphqlRequestClient])

  useEffect(() => {
		setButtons(null)
		async function setData() {
			const data = await getData()
			setButtons(data)
		}
		setData()
	}, [category, current, buttonQuery, longTail, getData])

  return (
    <Box className={classes.buttonBox}>
      {!buttons && <Skeleton variant="rect" animation="wave" width="100%" height={500} />}

      {buttons && buttons.map((button) => (
        <Link
          key={button.id}
          className={classes.buttonLinkWrapper}
          to={makeButtonLink(longTail, category, button.id)}
        >
          <Button
            className={classes.button}
            variant="outlined"
            size="small"
            color={longTail ? "secondary" : "primary"}
          >
            {button.name}
          </Button>
        </Link>
      ))}
    </Box>
  )
}
