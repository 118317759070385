import React, { Component } from "react"
import * as Sentry from '@sentry/react' // for error logging
import {
  withStyles,
  Container,
} from "@material-ui/core"

import { GraphqlRequest } from '../../services'
import { 
  Page, 
  ConfirmationDialog, 
  LoadingBackdrop 
} from "../../blocks"
import { EditionForm } from "../../forms"
import { LoadingSpinner } from "../../blocks"
import {
	SubCategoryQueries,
	EditionQueries,
  AuthorQueries,
  TagQueries,
  SectionQueries
} from '../../strapiQueries'
import { EditionStyles } from '../../styles'

const styles = (theme) => (EditionStyles(theme))

class EditionFormPage extends Component {
	state = {
		edition: null, // Will be populated if we are editing the edition.
		loading: true, // To show an animation when the form is submitted
		openSubmitDialog: false, // To open the dialog before submitting, so as to give further instructions to the user
		formData: null, // Data from the form to be sent to GraphCMS
		mutationProgress: 0, // Variable to fill the progress bar in the loading backdrop block component
		subcategoryList: [],
		teamList: [],
		tagList: [],
		sectionList: [],
		submitting: false,
		formValues: [],
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	getFromData = async () => {
		const { openDialog } = this.props

		try {
			const subcategoryListRes = await this.strapiGraphqlRequestClient.request(
				SubCategoryQueries.getList,
				{ sort: 'name:ASC' }
			)
			const sectionsRes = await this.strapiGraphqlRequestClient.request(
				SectionQueries.getAll,
				{ sort: 'name:ASC' }
			)

			const teamListRes = await this.strapiGraphqlRequestClient.request(
				AuthorQueries.getList,
				{ sort: 'first_name:ASC' }
			)

			const tagListRes = await this.strapiGraphqlRequestClient.request(
				TagQueries.getList,
				{ sort: 'name:ASC' }
			)

			const subcategoryList = subcategoryListRes.subcategories
			const sectionList = sectionsRes.sections

			const teamList = teamListRes.authors.map((author) => ({
				id: author.id,
				name: `${author.first_name} ${author.last_name}`,
			}))

			const tagList = tagListRes.tags

			//set form date to today
			const date = new Date()
			const dateStr = `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

			const formValues = {
				subcategory: '',
				date: dateStr,
				title: '',
				subtitle: '',
				mainTeam: [],
				supportTeam: [],
				tags: [],
				hasVideo: false,
				url: '',
				fileContent: '',
				published: false,
				featured: false,
				longTail: false,
				archived: false,
				contentSummary: false,
				public: false
			}

			this.setState({
				subcategoryList,
				teamList,
				tagList,
				sectionList,
				formValues,
			})
		} catch (error) {
			openDialog(error)
			Sentry.captureException(error)
		}
	}

	getEditionData = async (id) => {
		const { teamList } = this.state
		const { openDialog } = this.props

		this.setState({
			loading: true,
		})

		try {
			const fetchedEdition = await this.strapiGraphqlRequestClient.request(
				EditionQueries.get,
				{ edition_id: id }
			)

			if (!fetchedEdition.edition) {
				this.props.history.push(`/admin/edition`)
				return
			}

			const {
				title,
				subtitle,
				subcategory,
				published_at,
				url,
				date,
				archived,
				featured,
				long_tail,
				file_content,
				author_editions,
				content_summary,
				tags,
				public: publicEdition
			} = fetchedEdition.edition

			const mainTeam = []
			const supportTeam = []

			author_editions.forEach((record) => {
				if (record.main) {
					mainTeam.push(
						teamList.find((member) => member.id === record.author.id)
					)
				} else {
					supportTeam.push(
						teamList.find((member) => member.id === record.author.id)
					)
				}
			})

			const formValues = {
				subcategory: subcategory.id,
				date,
				title,
				subtitle,
				mainTeam,
				supportTeam,
				tags,
				url: url ? url : '',
				hasVideo: url ? true : false,
				fileContent: file_content,
				featured,
				longTail: long_tail,
				archived,
				contentSummary: content_summary,
				published: published_at ? true : false,
				public: publicEdition ? true : false
			}

			this.setState({
				edition: fetchedEdition.edition,
				loading: false,
				formValues,
			})
		} catch (error) {
			openDialog(error)
			Sentry.captureException(error)
		}
	}

	handleDialogOpen = (formData) => {
		this.setState({
			openSubmitDialog: true,
			formData: formData,
		})
	}

	handleDialogClose = () => {
		this.setState({ openSubmitDialog: false })
	}

	handleSubmit = () => {}

	async componentDidMount() {
		const {
			match: { params },
		} = this.props

		await this.getFromData()

		if (params.id) {
			await this.getEditionData(params.id)
		}

		this.setState({
			loading: false,
		})
	}

	async componentDidUpdate(prevProps) {
		const {
			match: { params },
		} = this.props

		if (params.id) {
			// If there is an ID param, then we check if it's the same as the earlier one and, if not, re-render for a different category
			if (params.id !== prevProps.match.params.id) {
				await this.getEditionData(params.id)
			}
		} else {
			// We need an additional if condition to check if category is already null, otherwise, it falls into an infinite loop of setting state and crashes the app.
			if (this.state.edition !== null) {
				this.setState({
					edition: null,
				})
			}
		}
	}

	render() {
		const {
			edition,
			loading,
			openSubmitDialog,
			mutationProgress,
			subcategoryList,
			teamList,
			tagList,
			sectionList,
			submitting,
			formValues,
		} = this.state

		const { classes } = this.props

		const breadcrumbs = [
			{
				content: `Admin`,
				href: '/admin/edition',
			},
			{
				content: `Edition`,
				href: '/admin/edition',
			},
			{
				content: edition
					? `Editing Edition - ${edition.title}`
					: 'Add an Edition',
				href: null,
			},
		]

		return (
			<Page
				className={classes.breadcrumbsContainer}
				title={`${
					edition && !loading
						? `Editing Edition - ${edition.title}`
						: 'Add an Edition'
				}`}
				breadcrumbs={breadcrumbs}>
				<Container className={classes.contentContainer} maxWidth="lg">
					{
						// 'action' prop is for submit button text in the form component
					}
					{loading ? (
						<LoadingSpinner />
					) : (
						<EditionForm
							action={`${edition ? `edit` : `add`}`}
							edition={edition}
							subcategoryList={subcategoryList}
							teamList={teamList}
							tagList={tagList}
							sectionList={sectionList}
							formValues={formValues}
						/>
					)}
				</Container>

				<LoadingBackdrop
					loading={submitting}
					mutationProgress={mutationProgress}
				/>

				<ConfirmationDialog
					open={openSubmitDialog}
					handleClose={this.handleDialogClose}
					handleSubmit={this.handleSubmit}
					alertSeverity={'warning'}
					title="Hold Up!"
					textContent="This will only save a draft of the edition."
					alertContent={
						<>
							To complete the process, you need to{' '}
							<strong>visit GraphCMS</strong>,{' '}
							<strong>add/edit any images/files</strong> and{' '}
							<strong>publish</strong> the edition.
						</>
					}
					confirmButtonText={edition ? 'Save Changes' : 'Create Edition'}
					cancelButtonText="Wait! I missed something"
				/>
			</Page>
		)
	}
}

export default withStyles(styles)(EditionFormPage)