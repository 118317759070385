const CCDashboardQueries = {
	getFeatured: `
    query getFeatured{
      editionsConnection(
        where : { long_tail : true , archived : false , featured : true  },
        sort : "date:DESC"   
      ){
        values : values{
          id
          title
          subtitle
          subcategory{
            id
            name
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          contents(limit : 1, sort :"created_at:ASC" ){
            id
            body
          }
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
	getFeed: `
    query getFeatured(
      $start : Int!,
      $limit : Int!,
    ){
      editionsConnection(
        start : $start, limit : $limit,
        where : { long_tail : true , archived : false , featured : false  },
        sort : "date:DESC"   
      ){
        values : values{
          id
          title
          subtitle
          subcategory{
            id
            name
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          contents(limit : 1, sort :"created_at:ASC" ){
            id
            body
          }
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
}

export default CCDashboardQueries