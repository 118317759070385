import React, { Component } from "react"
import * as Sentry from '@sentry/react' // for error logging
import {
  withStyles,
  Container,
} from "@material-ui/core"

import { GraphqlRequest } from '../../services'
import { Page, LoadingSpinner } from '../../blocks'
import { SubcategoryForm } from "../../forms"
import { SubCategoryQueries , CategoryQueries, SectionQueries } from '../../strapiQueries'
import { commonPageStyles } from '../../styles'
import settings from "../../../settings"

const styles = (theme) => (commonPageStyles(theme))

class SubcategoryFormPage extends Component {
	state = {
		subcategory: null, // Category will be populated if we are editing the category.
		loading: false, // variable to add a loading backdrop to indicate that the form is being submitted.
		subcategory_sections: [],
		formValues: null,
		categories: [],
		sections: [],
		products: [],
		hermes_product_ids: [],
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	getSelectData = async () => {
		const { openDialog } = this.props
		this.setState({ ...this.state, loading: true })

		try {
			const fetchedCategories = await this.strapiGraphqlRequestClient.request(
				CategoryQueries.getAll
			)

			const fetchedSections = await this.strapiGraphqlRequestClient.request(
				SectionQueries.getAll
			)

			const products = await (
				await fetch(`${settings.baseURL}/api/products/athena`)
			).json()

			this.setState({
				...this.state,
				categories: fetchedCategories.categories,
				sections: fetchedSections.sections,
				loading: false,
				products,
			})
		} catch (err) {
			Sentry.captureException(err)
			openDialog(err)
			console.log(err) // GraphQL response errors
		}
	}

	getCurrentValues = async () => {
		this.setState({ ...this.state, loading: true })

		const {
			openDialog,
			match: { params },
		} = this.props

		try {
			const fetchedSubcategory = await this.strapiGraphqlRequestClient.request(
				SubCategoryQueries.findSubcategoryById,
				{ subcategory_id: params.id }
			)

			const {
				id,
				name,
				description,
				CC_description,
				published_at,
				subcategory_sections,
				category,
				restricted_access,
				hermes_product_ids,
			} = fetchedSubcategory.subcategory

			let formValues = {
				name,
				description: description === null ? '' : description,
				CC_description: CC_description === null ? '' : CC_description,
				published: published_at === null ? false : true,
				restricted: restricted_access === null ? false : restricted_access,
				category: category === null ? '' : category.id,
			}

			this.setState({
				...this.state,
				subcategory: id,
				formValues,
				subcategory_sections,
				hermes_product_ids,
			})
		} catch (err) {
			Sentry.captureException(err)
			openDialog(err)
			console.log(err) // GraphQL response errors
		}
	}

	componentDidMount() {
		const {
			match: { params },
		} = this.props

		if (params.id) {
			this.getCurrentValues()
		}

		this.getSelectData()
	}

	render() {
		const {
			subcategory,
			formValues,
			loading,
			subcategory_sections,
			categories,
			sections,
			hermes_product_ids,
			products,
		} = this.state

		const { classes, openSnackbar } = this.props

		const breadcrumbs = [
			{
				content: 'Admin',
			},
			{
				content: 'Subcategory',
				href: '/admin/subcategory',
			},
			{
				content: subcategory
					? `Editing ${formValues.name} Sub-Category`
					: 'Add a Sub-Category',
				href: null,
			},
		]

		return (
			<Page
				className={classes.breadcrumbsContainer}
				title={`${
					subcategory
						? `Editing ${formValues.name} Sub-Category`
						: 'Add a Sub-Category'
				}`}
				breadcrumbs={breadcrumbs}>
				<Container className={classes.contentContainer} maxWidth="lg">
					{
						// 'action' prop is for submit button text in the form component
					}
					{loading ? (
						<LoadingSpinner />
					) : (
						<SubcategoryForm
							action={`${subcategory ? `edit` : `add`}`}
							values={formValues}
							subcategory={subcategory}
							subcategory_sections={subcategory_sections}
							hermes_product_ids={hermes_product_ids}
							categories={categories}
							openSnackbar={openSnackbar}
							products={products}
							sections={sections}
						/>
					)}
				</Container>
			</Page>
		)
	}
}

export default withStyles(styles)(SubcategoryFormPage)