/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { SubCategoryQueries } from '../../strapiQueries'
import { CategoryQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'
import { Link } from 'react-router-dom'
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Button,
	MenuItem,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { Info, ConfirmationDialog } from '../../blocks'
import { PermissibleRender } from "../../utilities"

function SubcategoryPanel({ userRole, classes, openDialog, openSnackbar }) {
	const [page, setPage] = useState(1)
	const [pageCount, setPageCount] = useState(1)
	const [loading, setLoading] = useState(true)
	const [subcategories, setSubcategories] = useState([])
	const [selectedCategory, setSelectedCategory] = useState('all')
	const [categories, setCategories] = useState([])
	const [renderData, setRenderData] = useState([])
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
	const [focusItem, setFocusItem] = useState(null)

	const rowsperPage = 6

	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	const fetchCategories = async () => {
		const fetchedCategories = (
			await strapiGraphqlRequestClient.request(CategoryQueries.getAll)
		).categories

		return fetchedCategories
	}

	const fetchSubategories = async () => {
		const queryVars = {
			sort: 'published_at:desc',
		}

		const fetchedSubcategories = (
			await strapiGraphqlRequestClient.request(
				SubCategoryQueries.getAll,
				queryVars
			)
		).subcategories

		return fetchedSubcategories
	}

	useEffect(() => {
		const popluateData = async () => {
			try {
				const getCategories = await fetchCategories()
				const getSubcategories = await fetchSubategories()

				setCategories(getCategories)
				setSubcategories(getSubcategories)
				setRenderData(subcategories)

				setLoading(false)
			} catch (error) {
				openDialog(error)
			}
		}

		popluateData()
	}, [])

	useEffect(() => {
		setPageCount(Math.ceil(renderData.length / rowsperPage))
	}, [renderData])

	useEffect(() => {
		if (selectedCategory === 'all') {
			setRenderData(subcategories)
		} else if (selectedCategory === 'none') {
			setRenderData(
				subcategories.filter((subcategory) => subcategory.category === null)
			)
		} else {
			setRenderData(
				subcategories.filter(
					(subcategory) =>
						subcategory.category !== null &&
						subcategory.category.id === selectedCategory
				)
			)
		}
	}, [selectedCategory, subcategories])

	const handleCategorySelect = (event) => {
		setSelectedCategory(event.target.value)
	}

	const handlePageChange = (event, value) => {
		setPage(value)
	}

	const handleDelete = (item) => {
		setFocusItem(item)
		setDeleteDialogOpen(true)
	}

	const handleDeleteSubmit = async () => {
		const queryVars = {
			id: focusItem.id,
		}
		try {
			await strapiGraphqlRequestClient.request(
				SubCategoryQueries.delete,
				queryVars
			)
			openSnackbar(`Category "${focusItem.name}" Deleted Succesfully.`)
		} catch (error) {
			openSnackbar(`Category "${focusItem.name}" Deleting Failed.`)
			console.log(error)
		}

		setDeleteDialogOpen(false)
		setLoading(true)
		const subcategories = await fetchSubategories()
		setSubcategories(subcategories)
		setLoading(false)
	}

	return (
		<>
			<div className={classes.tableControls}>
				<FormControl>
					<InputLabel>Category</InputLabel>
					<Select
						value={selectedCategory}
						onChange={handleCategorySelect}
						label="Category"
						inputProps={{
							name: 'cataegory',
							id: 'category-native',
						}}>
						<MenuItem aria-label="All" value={'all'}>
							All
						</MenuItem>
						{!loading &&
							categories.map((category) => (
								<MenuItem value={category.id}>{category.name}</MenuItem>
							))}
						<MenuItem value={'none'}>No Category</MenuItem>
					</Select>
				</FormControl>
				<div className={classes.addItem}>
					<Typography>{`Add Subcategory`}</Typography>
					<Link to="/admin/subcategory/add">
						<Button className={classes.addItemButton}>
							<AddCircleIcon />
						</Button>
					</Link>
				</div>
			</div>
			{loading ? (
				<div className={classes.spinner}>
					<CircularProgress />
				</div>
			) : (
				<>
					<TableContainer className={classes.content}>
						<Table>
							<TableBody>
								<>
									{renderData.length !== 0 ? (
										<>
											{renderData
												.slice((page - 1) * rowsperPage, page * rowsperPage)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell component="th" scope="row">
															{item.name || item.title}
														</TableCell>
														<TableCell></TableCell>
														<TableCell
															component="th"
															scope="row"
															className={classes.buttonGroup}>
															<Chip
																label={
																	item.published_at ? 'Published' : 'Draft'
																}
															/>
															<Link to={`/admin/subcategory/edit/${item.id}`}>
																<Button>
																	<EditIcon />
																</Button>
															</Link>
															<PermissibleRender
																userPermission={userRole}
																requiredPermissions={["dev", "editor"]}
															>
																<Button onClick={() => handleDelete(item)}>
																	<DeleteIcon />
																</Button>
															</PermissibleRender>
														</TableCell>
													</TableRow>
												))}
										</>
									) : (
										<Info
											classes={classes}
											content="No data under this section :("
										/>
									)}
								</>
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						className={classes.pagination}
						count={pageCount}
						page={page}
						onChange={handlePageChange}
					/>
				</>
			)}
			<ConfirmationDialog
				open={deleteDialogOpen}
				handleClose={() => setDeleteDialogOpen(false)}
				handleSubmit={handleDeleteSubmit}
				alertSeverity={'warning'}
				title="Hold Up!"
				textContent={`Are you sure you want to delete ${focusItem === null ? '' : focusItem.name
					}`}
				alertContent={
					<>
						This will un-publish{' '}
						<strong>
							all associated editions, sections, content and attachments
						</strong>{' '}
						under this subcategory.
						<br />
						<br />
						This action <strong>is</strong> reversible. Contact the Tech Team to
						have it reversed, if you need to.
					</>
				}
				confirmButtonText="Yes, I'm sure"
				cancelButtonText="Cancel"
			/>
		</>
	)
}

export default SubcategoryPanel
