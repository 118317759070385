import React from 'react'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function FileUpload({ name, fileType, onInput, loading, ...otherProps }) {
	const fileInputAcceptValues =
		fileType === 'pdf'
			? 'application/pdf'
			: 'image/bmp, image/jpeg, image/png, image/svg+xml, image/tiff, image/webm'

	const label = fileType === 'pdf' ? 'Upload PDF file' : 'Upload image file'

	return (
		<Button variant="contained" component="label" disabled={loading}>
			{loading ? <CircularProgress color="secondary" size={25} /> : label}
			<input
				type="file"
				accept={fileInputAcceptValues}
				onChange={onInput}
				hidden
			/>
		</Button>
	)
}
