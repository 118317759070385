/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
	SubcategorySectionQueries,
	SubCategoryQueries,
	SectionQueries,
	HermesProductIdsQueries,
} from '../../strapiQueries'
import {
	Grid,
	Typography,
	Button,
	CircularProgress,
	MenuItem,
} from '@material-ui/core'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
	Dialog,
	DialogTitle,
	Tooltip,
	Select,
	InputLabel,
	FormControl,
} from '@material-ui/core'
import {
	Form,
	Text,
	DraggableCard,
	SelectField,
	Switch,
	RichText,
	MultiSelect,
} from '../../blocks'

import { commonFormStyles } from '../../styles'
import { GraphqlRequest } from '../../services'
import { usePathHistory } from '../../helpers'

const useStyles = makeStyles((theme) => ({
	formLable: {
		marginTop: theme.spacing(1),
	},
	...commonFormStyles(theme),
}))

const initialValues = {
	name: '',
	description: '',
	CC_description: '',
	published: false,
	restricted: false,
	category: '',
}


export default function NewSubcategoryForm({
	action,
	values,
	subcategory,
	subcategory_sections,
	hermes_product_ids,
	categories,
	openSnackbar,
	products,
}) {
	const [subcategorySections, setSubcategorySections] = useState([])
	const [sections, addSections] = useState([])
	const [selectedSection, setSelectedSection] = useState('')
	const [addingSection, setAddingSetion] = useState(false)
	const [creatingSection, setCreatingSection] = useState(false)
	const [submittingForm, setSubmittingForm] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)
	const [restricted, setRestricted] = useState(false)
	const [selectedProducts, setSelectedProducts] = useState([])

	const pathHistory = usePathHistory()


	const strapiGraphqlRequestClient = useMemo(
		() => new GraphqlRequest().strapiClient,
		[]
	)

	const fetchSections = async () => {
		const fetchedSections = await strapiGraphqlRequestClient.request(
			SectionQueries.getAll
		)

		return fetchedSections.sections
	}

	
	const createSection = async (values) => {
		const { name } = values
		setCreatingSection(true)

		const createdSection = await strapiGraphqlRequestClient.request(
			SectionQueries.add,
			{ name }
		)

		await fetchData()

		openSnackbar(`Added Section"${createdSection.createSection.section.name}"`)

		setSelectedSection(createdSection.createSection.section.id)

		setCreatingSection(false)
	}

	useEffect(() => {
		let isMounted = true

		if (isMounted) {
			fetchData()
			setSubcategorySections(subcategory_sections)
			if (values) {
				setRestricted(values.restricted)
			}

			if (hermes_product_ids && hermes_product_ids.length) {
				const existingProducts = []

				hermes_product_ids.forEach((record) => {
					const product = _.find(products, { id: record.product_id })

					if (product) {
						existingProducts.push(product)
					}
				})
				setSelectedProducts(existingProducts)
			}
		}

		return () => (isMounted = false)
	}, [])

	useEffect(() => {
		let isMounted = true

		if (isMounted) {
			if (creatingSection === true && selectedSection !== '') {
				handleSectionAdd()
			}
		}

		return () => (isMounted = false)
	}, [selectedSection, createSection])

	const fetchData = async () => {
		const sections = await fetchSections()
		addSections(sections)
	}

	const classes = useStyles()

	const handleSectionSelect = (event) => {
		setSelectedSection(event.target.value)
	}


	const handleSectionAdd = async (id) => {
		if (selectedSection === '') {
			openSnackbar('Please Select a section to add.')
			return
		}

		const SubcategorySectionExists = subcategorySections.filter(
			(subcategorySection) => subcategorySection.section.id === selectedSection
		).length

		if (SubcategorySectionExists) {
			openSnackbar('The section you selected already Exists.')
			return
		}

		const queryVars = {
			section_id: parseInt(selectedSection),
			default_position: subcategorySections.length + 1,
		}

		try {
			setAddingSetion(true)

			const addedSubSection = await strapiGraphqlRequestClient.request(
				SubcategorySectionQueries.add,
				queryVars
			)

			setSubcategorySections([
				...subcategorySections,
				addedSubSection.createSubcategorySection.subcategorySection,
			])
			setAddingSetion(false)
			setSelectedSection('')
			setDialogOpen(false)
		} catch (err) {
			console.log(err)
		}
	}

	const subcategorySectionDelete = async (id) => {
		try {
			setAddingSetion(true)

			await strapiGraphqlRequestClient.request(
				SubcategorySectionQueries.delete,
				{ id: parseInt(id) }
			)
			setSubcategorySections(
				subcategorySections.filter((item) => id !== item.id)
			)

			setAddingSetion(false)
		} catch (error) {
			console.log(error)
		}
	}

	const handleProductSelect = (name, value) => {
		setSelectedProducts(value)
	}

	const submitForm = async (values) => {
		setSubmittingForm(true)

		let subcategoryId = subcategory

		const {
			name,
			description,
			CC_description,
			published,
			category,
			restricted,
		} = values

		const published_at = published ? new Date().toISOString() : null

		const queryVars = {
			name,
			description,
			CC_description,
			published_at,
			Category_id: parseInt(category),
			restricted,
		}
		if (action === 'add') {
			const createdSubcategory = await strapiGraphqlRequestClient.request(
				SubCategoryQueries.add,
				queryVars
			)

			subcategoryId = createdSubcategory.createSubcategory.subcategory.id
		}

		if (action === 'edit') {
			queryVars.subcategory_id = subcategory

			await strapiGraphqlRequestClient.request(
				SubCategoryQueries.update,
				queryVars
			)

			subcategoryId = subcategory
		}

		for (const [index, subcategorySection] of subcategorySections.entries()) {
			const queryVars = {
				subcategorySection_id: parseInt(subcategorySection.id),
				default_position: index + 1,
				subcategory_id: parseInt(subcategoryId),
			}

			try {
				await strapiGraphqlRequestClient.request(
					SubcategorySectionQueries.update,
					queryVars
				)
			} catch (error) {
				console.log(error)
			}

		}

		//adding related products
		const productIdsToAdd = []
		const productIdsTodelete = []

		hermes_product_ids.forEach((product) => {
			const record = _.find(selectedProducts, { id: product.product_id })

			if (!record) {
				productIdsTodelete.push(product.id)
			}
		})

		selectedProducts.forEach((product) => {
			const record = _.find(hermes_product_ids, {
				product_id: product.id,
			})

			if (!record) {
				productIdsToAdd.push(product.id)
			}
		})

		try {
			productIdsToAdd.map(async (product) => {
				const queryVars = {
					product_id: product,
					subcategory_id: subcategoryId,
				}

				await strapiGraphqlRequestClient.request(
					HermesProductIdsQueries.add,
					queryVars
				)
			})

			productIdsTodelete.map(async (product) => {
				await strapiGraphqlRequestClient.request(
					HermesProductIdsQueries.delete,
					{ id: product }
				)
			})
		} catch (error) {
			console.log(error)
		}

		setSubmittingForm(false)
		pathHistory.goBack()
	}

	return (
		<>
			<Formik
				initialValues={action === 'add' ? { ...initialValues } : { ...values }}
				onSubmit={submitForm}
				validationSchema={Yup.object({
					name: Yup.string().required('Required'),
					category: Yup.string().required('Required'),
				})}>
				<Form
					classes={classes}
					submitButtonText={
						action === 'add' ? 'Add Subcategory' : 'Save Changes'
					}
					performingAction={submittingForm}>
					<FormikForm>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>Name</Typography>
									<Text name="name"></Text>
								</div>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>
										Description
									</Typography>
									<RichText name="description" type="description"></RichText>
								</div>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>
										CC Description
									</Typography>
									<RichText name="CC_description" type="description"></RichText>
								</div>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>
										Parent category
									</Typography>
									<SelectField name="category" items={categories}></SelectField>
								</div>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>
										Published
									</Typography>
									<Switch name="published" />
								</div>
								<div className={classes.formField}>
									<Typography className={classes.formLable}>
										Restricted Access
									</Typography>
									<Switch
										name="restricted"
										onClick={() => {
											setRestricted(!restricted)
										}}
									/>
								</div>
								{restricted && (
									<MultiSelect
										name="selecedProducts"
										label="Choose products"
										menuItemsArray={products}
										value={selectedProducts}
										changeHandler={handleProductSelect}
									/>
								)}
							</Grid>
							<Grid item xs={6}>
								<div
									className={classes.subsectionsHeader}
									style={{ marginTop: '0', marginBottom: '24px' }}>
									<FormControl style={{ minWidth: '300px' }}>
										<InputLabel>Choose Section</InputLabel>
										<Select
											value={selectedSection}
											onChange={handleSectionSelect}
											label="section"
											inputProps={{
												name: 'section',
												id: 'section-native',
											}}>
											{sections.map((section, index) => (
												<MenuItem value={section.id} key={index}>
													{section.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<Button
										variant="contained"
										color="primary"
										className={classes.iconButton}
										onClick={handleSectionAdd}
										disabled={addingSection ? true : false}>
										{addingSection ? (
											<CircularProgress color="inherit" size="1.5rem" />
										) : (
											<Tooltip
												title="add Section to this subcategory"
												placement="top">
												<div>Add</div>
											</Tooltip>
										)}
									</Button>
									<Button
										variant="contained"
										color="primary"
										className={classes.iconButton}
										onClick={() => setDialogOpen(true)}
										disabled={creatingSection ? true : false}>
										{creatingSection ? (
											<CircularProgress color="inherit" size="1.5rem" />
										) : (
											<Tooltip
												title="Create Section and add to this subcategory"
												placement="top">
												<div>create new</div>
											</Tooltip>
										)}
									</Button>
								</div>
								<div className={classes.subsectionsHeader}>
									<Typography>
										{subcategorySections.length
											? 'Sections under this subcategory'
											: null}
									</Typography>
								</div>
								<div className={classes.subsectionsContainer}>
									{
										<DragDropContext
											onDragEnd={({ draggableId, source, destination }) => {
												const sectionArr = subcategorySections
												if (destination) {
													sectionArr.splice(
														destination.index,
														0,
														sectionArr.splice(source.index, 1)[0]
													)
												}
												setSubcategorySections(sectionArr)
											}}>
											<Droppable droppableId="droppable-1">
												{(provided, snnapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.droppableProps}>
														{subcategorySections.map(
															(subcategorySection, index) => (
																<Draggable
																	key={subcategorySection.id}
																	id={index}
																	draggableId={
																		('draggable-', subcategorySection.id)
																	}
																	index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}>
																			<DraggableCard
																				classes={classes}
																				name={subcategorySection.section.name}
																				draggable={true}
																				dragHandle={provided.dragHandleProps}
																				handleDelete={() => {
																					subcategorySectionDelete(
																						subcategorySection.id
																					)
																				}}
																			/>
																		</div>
																	)}
																</Draggable>
															)
														)}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									}
								</div>
							</Grid>
						</Grid>
					</FormikForm>
				</Form>
			</Formik>
			<Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
				<DialogTitle>Creat A Section</DialogTitle>
				<Formik
					initialValues={{ name: '' }}
					onSubmit={createSection}
					submitButtonText="Create Section"
					validationSchema={Yup.object({
						name: Yup.string().required('Required'),
					})}>
					<Form
						classes={classes}
						submitButtonText={'Create Section'}
						performingAction={creatingSection}
						onCancel={() => setDialogOpen(false)}>
						<FormikForm>
							<Typography>Name</Typography>
							<Text name="name"></Text>
						</FormikForm>
					</Form>
				</Formik>
			</Dialog>
		</>
	)
}
