
const ContentQueries = {
	create: `
		mutation createContent(
			$title: String
			$url: String
			$position: Int!
			$body: String!
			$section: ID!
			$edition: ID!
			$updated_by: ID
			$created_by: ID
		) {
			createContent(
				input: {
					data: {
						title: $title
						url: $url
						position: $position
						body: $body
						section: $section
						edition: $edition
						updated_by: $updated_by
						created_by: $created_by
					}
				}
			) {
				content {
					id
				}
			}
		}
	`,
	update: `
		mutation addContent(
			$id : ID!,
			$title: String,
			$url: String
			$position: Int!
			$body: String!
			$section: ID!
			$edition: ID!
			$updated_by: ID
			$created_by: ID
		) {
			updateContent(
				input: {
					where : { id : $id },
					data: {
						title: $title
						url: $url
						position: $position
						body: $body
						section: $section
						edition: $edition
						updated_by: $updated_by
						created_by: $created_by
					}
				}
			) {
				content {
					id
				}
			}
		}
	`,
	delete: `
		mutation deleteContent(
			$id : ID!,
		) {
			deleteContent(
				input: {
					where : { id : $id },
				}
			) {
				content {
					id
				}
			}
		}
	`,
	findContentByEdition: `
		query getContent($id: ID!) {
			contents(where: { edition: $id }) {
				title
				position
				body
				section {
					id
					name
				}
				edition {
					id
					title
				}
			}
		}
	`,
	findDuplicates: `
		query getContent($position: Int!, $section: ID!, $edition: ID!) {
			contents(
				where: { position: $position, section: $section, edition: $edition }
			) {
				title
				position
				body
				section {
					id
					name
				}
				edition {
					id
					title
				}
			}
		}
	`,
	findLastposition: `
        query getlastposition($$section: ID!, $edition: ID!){
            contents(
				where: { position: $position, section: $section, edition: $edition },
                sort : "position:desc"
			) {
				position
			}
        }
    `,
}

export default ContentQueries
