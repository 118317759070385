import PageNotFoundImage from "../../../images/assets/404_page_graphic_transparent.svg"

function PageNotFoundStyles(theme) {
  return ({
    root: {
      marginTop: theme.spacing(4),
      backgroundImage: `url(${PageNotFoundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        height: "80vh",
      }
    },
    text: {
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    textHeading: {
      marginBottom: theme.spacing(2),
      textAlign: "center",
      fontSize: "15rem"
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  })
}

export default PageNotFoundStyles