const ButtonQueries = {
	subcategories: `query categoryData(
    $categoryID : ID!
    $SubscribedList : [ID]!
    $long_tail : Boolean!
  ){
    categoriesConnection(where : {id : $categoryID}){
        values : values{
          id
          name
          open : subcategories(where: { restricted_access : false } , sort : "name:ASC"){
            id
            name
            editions(limit :1, where : {archived : false , long_tail : $long_tail }){
              id
            }
          }
          subscribed : subcategories(where: {restricted_access : true, id_in :$SubscribedList }, sort : "name:ASC"){
            id
            name
            editions(limit :1, where : {archived : false , long_tail : $long_tail}){
              id
            }
          }
        }
      }
    }
  `,
	categories: `
    query {
      categories{
        id
        name
      }
    }
  `,
	buttons: `query getSubCategories($longTail: Boolean, $categoryId: ID) {
    category (where: {id: $categoryId}) {
      subCategory {
        id
        name
        edition (where: { longTail: $longTail, archived: false }) {
          id
        }
      }
    }
  }`,
}

export default ButtonQueries