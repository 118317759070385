import React, { Component } from "react"
import { withStyles, Container } from "@material-ui/core"

import {
    Page,
    CardContainer,
    ButtonContainer,
    LoadMoreButton,
    Info,
} from "../../blocks"
import _ from "lodash"
import { GraphqlRequest } from "../../services"
import { CategoryPageQueries } from "../../strapiQueries"
import { commonPageStyles } from "../../styles"
import { filterUnauthorizedEditions } from "../../helpers"
import categoryLinks from "../../../redirectData/categories.json"

const styles = (theme) => commonPageStyles(theme)

class CCCategory extends Component {
    state = {
        category: null,
        featured: null,
        feed: null,

        hasNextPage: true,
        endCursor: "",
        loadingFeed: false,
    }

    strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

    getInitialData = async () => {
        const {
            openDialog,
            match: { params },
            products,
            history,
            location,
        } = this.props

        const dataPath = location.pathname.replace("/extended-content", "").replace(/\/$/, "")
        
        if (dataPath in categoryLinks) {
            history.push(`/extended-content/${categoryLinks[dataPath]}`)
            return
        }

        try {
            if (isNaN(params.categoryId)) {
                window.location.href = "/error404"
                return
            }

            const categoryDataRes =
                await this.strapiGraphqlRequestClient.request(
                    CategoryPageQueries.getCategoryData,
                    {
                        categoryId: params.categoryId,
                    }
                )

            if (!categoryDataRes.category) {
                window.location.href = "/error404"
                return
            }

            this.setState(
                {
                    category: categoryDataRes.category,
                },
                () => {
                    window.analytics.page(
                        `Curious Corner - ${this.state.category.name} Category`,
                        {
                            title: `Curious Corner - ${this.state.category.name} | Frontier Research`,
                        }
                    )
                }
            )

            const featuredDataRes =
                await this.strapiGraphqlRequestClient.request(
                    CategoryPageQueries.getFeatured,
                    {
                        categoryId: params.categoryId,
                        subscribedList: products,
                        longTail: true,
                    }
                )

            const data = featuredDataRes.categoriesConnection.values[0]

            const featuredEditions = []

            const { open, subscribed } = data

            open.forEach((subcategory) => {
                featuredEditions.push(...subcategory.editions)
            })

            subscribed.forEach((subcategory) => {
                featuredEditions.push(...subcategory.editions)
            })

            this.setState({
                featured: _.orderBy(featuredEditions, ["date"], ["desc"]),
            })

            const feedDataRes = await this.strapiGraphqlRequestClient.request(
                CategoryPageQueries.getFeed,
                {
                    categoryId: params.categoryId,
                    longTail: true,
                    start: 0,
                    limit: 10,
                }
            )

            const count = feedDataRes.editionsConnection.values.length

            const feedEditions = filterUnauthorizedEditions(
                feedDataRes.editionsConnection.values,
                products
            )

            this.setState({
                feed: feedEditions,
                endCursor: this.state.endCursor + count,
                hasNextPage: count > 9,
            })
        } catch (error) {
            console.log(error)
            openDialog(error)
        }
    }

    handleLoadMore = async () => {
        const {
            openDialog,
            match: { params },
            products,
        } = this.props
        const { endCursor, feed } = this.state

        this.setState({ loadingFeed: true })

        try {
            const feedDataRes = await this.strapiGraphqlRequestClient.request(
                CategoryPageQueries.getFeed,
                {
                    categoryId: params.categoryId,
                    longTail: false,
                    start: endCursor,
                    limit: 10,
                }
            )

            const count = feedDataRes.editionsConnection.values.length

            const newFeed = filterUnauthorizedEditions(
                feedDataRes.editionsConnection.values,
                products
            )

            this.setState({
                feed: [...feed, ...newFeed],
                endCursor: this.state.endCursor + count,
                hasNextPage: count > 9,
                loadingFeed: false,
            })
        } catch (error) {
            openDialog(error)
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    componentDidUpdate(prevProps) {
        const {
            match: { params },
        } = this.props

        if (params.categoryId !== prevProps.match.params.categoryId) {
            this.setState({ hasNextPage: true })

            this.getInitialData()
        }
    }

    render() {
        const { category, featured, feed, hasNextPage, loadingFeed } =
            this.state
        const { classes, products } = this.props

        const breadcrumbs = [
            {
                content: "Curious Corner",
                href: "/extended-content",
            },
            {
                content: category ? category.name : "Loading...",
                href: null,
            },
        ]

        return (
            <Page
                className={classes.breadcrumbsContainer}
                title={`Curious Corner - ${category ? category.name : ""}`}
                breadcrumbs={breadcrumbs}
            >
                <Container
                    className={classes.breadcrumbsContainer}
                    maxWidth="lg"
                >
                    <Info
                        classes={classes}
                        title="In this section:"
                        content={
                            category ? category.CC_description : "Loading..."
                        }
                    />
                </Container>

                <Container className={classes.contentContainer} maxWidth="lg">
                    <CardContainer
                        classes={classes}
                        cardList={featured}
                        cardType="featured"
                        heading={category ? `Featured ${category.name}:` : null}
                    />
                    <ButtonContainer
                        classes={classes}
                        heading={
                            category
                                ? `Jump to Subcategories under Curious Corner ${category.name}:`
                                : null
                        }
                        category={category ? category : null}
                        longTail={true}
                        products={products}
                    />
                    <CardContainer
                        classes={classes}
                        cardList={feed}
                        cardType="feed"
                        heading="Recent and Beyond:"
                    />
                    <LoadMoreButton
                        classes={classes}
                        hasNextPage={hasNextPage}
                        handleLoadMore={this.handleLoadMore}
                        loadingFeed={loadingFeed}
                    />
                </Container>
            </Page>
        )
    }
}

export default withStyles(styles)(CCCategory)
