import _ from 'lodash'

function makeSubstring(fullTextObject, max) {

  const plainText = fullTextObject ? fullTextObject.replace(/<[^>]+>/g, '') : ""

  const substring = _.replace(plainText.slice(0,max), /\\n/g, ' ') + '...'

  return substring
}

export default makeSubstring