import React, { useEffect } from 'react'
import {
  Container,
  withStyles,
  Typography,
  Button,
  Link,
  Box,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { Page } from "../../blocks"
import { PageNotFoundStyles } from "../../styles"

const styles = (theme) => (PageNotFoundStyles(theme))

const PageNotFound = ({ classes }) => {

  useEffect(() => {
    // Segment page load tracking code
    window.analytics.page("Page Not Found", {
      title: "Page Not Found | Frontier Research"
    })
  }, [])
  
  return (
    <Page title={`Page not Found`}>
      <Container className={classes.root} maxWidth="md">
        <Box>
          <Typography
            variant="h1"
            className={classes.textHeading}
            gutterBottom
          >
            <strong>404</strong>
          </Typography>

          <Typography 
            variant="h3"
            className={classes.text}
            gutterBottom
          >
            We couldn't find the page you requested <span role="img" aria-label="thinking-emoji">🤔</span>.
          </Typography>

          <Typography
            variant="subtitle1"
            className={classes.text}
            gutterBottom
          >
            Click 
            
            <Button variant="contained" color="primary" className={classes.button}>
              <Link color="inherit" to="/" component={RouterLink}>
                here 
              </Link>
            </Button>
            
            to go back to the homepage.
          </Typography>
        </Box>
      </Container>
    </Page>
  )
}

export default withStyles(styles)(PageNotFound)