import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  makeStyles,
  Container,
  Box,
  InputBase,
  Typography,
  Card,
  CardContent,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  Index,
  Highlight,
} from "react-instantsearch-dom";
import { algoliaClient } from "../../services";
import { Page, Info } from "../../blocks";
import { SanitizeHTML } from "../../utilities";
import { SearchStyles } from "../../styles";

const useStyles = makeStyles((theme) => ({ ...SearchStyles(theme) }));

// custom search box
const SearchBox = ({ currentRefinement, refine, classes }) => (
  <div className={classes.searchBox}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <InputBase
      autoFocus
      placeholder="Search…"
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
    />
  </div>
);

// custom results container (hits)
const Hits = ({ hits, classes }) =>
  hits.length > 0 ? (
    <Container maxWidth="lg" className={classes.hits}>
      {hits.map((hit) => {
        const {
          objectID,
          _highlightResult,
          date,
          _snippetResult,
          subcategory,
        } = hit;

        const { value: title } = _highlightResult.title;

        const link = `/${subcategory && subcategory.category.id}/${
          subcategory && subcategory.id
        }/${objectID}`;

        return (
          <Link className={classes.hit} to={link} target="_blank">
            <Card variant="elevation">
              <CardContent className={classes.hitContent}>
                <Box className={classes.info}>
                  <Typography className={classes.date}>
                    {moment(date).format("Do MMM YYYY")}
                  </Typography>
                </Box>
                <Typography
                  component="h2"
                  className={classes.title}
                  gutterBottom
                >
                  <SanitizeHTML html={title} />
                </Typography>
                {_snippetResult &&
                  _snippetResult.contents.map(({ title, body }) => (
                    <div>
                      {title && (
                        <Typography className={classes.newsContentTitle}>
                          {title.matchLevel === "full" && (
                            <SanitizeHTML html={title.value} />
                          )}
                        </Typography>
                      )}

                      <Typography className={classes.content}>
                        {body.matchLevel === "full" && (
                          <SanitizeHTML html={body.value} />
                        )}
                      </Typography>
                    </div>
                  ))}
              </CardContent>
            </Card>
          </Link>
        );
      })}
    </Container>
  ) : (
    <Info
      classes={classes}
      content="<p>No matching results :(</p>
        <p>Try different keywords?</p>"
    />
  );

// connect the custom search box and 'hits' component using the connectors
const CustomSearchBox = connectSearchBox(SearchBox);
const CustomHits = connectHits(Hits);

// select search API key for the correct app on Algolia depending on environment
const apiKey =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ALGOLIA_DEV_SEARCH_API_KEY
    : process.env.REACT_APP_ALGOLIA_PROD_SEARCH_API_KEY;

export default function Search() {
  const classes = useStyles();

  return (
    <Page title="Search">
      <Container maxWidth="lg">
        <InstantSearch
          searchClient={algoliaClient(apiKey)}
          indexName={"regularContent"}
        >
          <Container className={classes.inputs}>
            <CustomSearchBox
              searchAsYouType={true}
              showLoadingIndicator={true}
              translations={{ placeholder: "Search..." }}
              classes={classes}
            />
          </Container>
          {
            <Index indexName={"regularContent"}>
              <CustomHits classes={classes} />
            </Index>
          }
        </InstantSearch>
      </Container>
    </Page>
  );
}
