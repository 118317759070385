import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { Grid, Typography } from '@material-ui/core'
import { CategoryQueries } from '../../strapiQueries'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import { usePathHistory } from '../../helpers'
import { 
	Form,
	Text,
	DraggableCard,
	Switch,
	RichText
} from '../../blocks'

import { commonFormStyles } from '../../styles'
import { GraphqlRequest } from '../../services'

const useStyles = makeStyles(theme => ({ ...commonFormStyles(theme) }))


	let initialValues = {
		name : '',
		description: '' ,
		CC_description:'',
		published: false,
	}

export default function CategoryForm ({ action, values , category , subcategories }) {
	const classes = useStyles()

	const pathHistory = usePathHistory()
	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	const handleSubmit = async (values) => {

		const {name , description , CC_description , published} = values

		const published_at = published ? new Date().toISOString() : null

		const inputvars = {
			id: category, //negelected when creating a category
			name,
			description,
			CC_description,
			published_at
		}

		try {
			if (action === 'add') {
				await strapiGraphqlRequestClient.request(
					CategoryQueries.addCategory,
					inputvars
				)
			}else {
				await strapiGraphqlRequestClient.request(
					CategoryQueries.update,
					inputvars
				)

			}
				
			pathHistory.goBack()

		} catch (error) {
				console.log(error)
		}
	
	}	

	return (
		<Formik
			initialValues={action === 'add' ? { ...initialValues } : { ...values }}
			onSubmit={handleSubmit}
			validationSchema={Yup.object({
				name: Yup.string().required('Required'),
			})}>
			<Form
				classes={classes}
				submitButtonText={action === 'add' ? 'Add Category' : 'Save Changes'}
			>
				<FormikForm>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<div className={classes.formField}>
								<Typography>Name</Typography>
								<Text name="name"></Text>
							</div>
							<div className={classes.formField}>
								<Typography>Description</Typography>
								<RichText name="description" type="description"></RichText>
							</div>
							<div className={classes.formField}>
								<Typography>CC Description</Typography>
								<RichText name="CC_description" type="description"></RichText>
							</div>
							<div className={classes.formField}>
								<Typography>Published</Typography>
								<Switch name="published" />
							</div>
						</Grid>
						<Grid item xs={6}>
							<div className={classes.subsectionsHeader}>
								<Typography>
									{action === 'add' ? (
										<Alert severity="info">
											<Typography>
												Use the subcategory panel, on admin menu to add subcategories.
											</Typography>
										</Alert>
									) : (
										'Subcategories under this category :'
									)}
								</Typography>
							</div>
							<div className={classes.subsectionsContainer}>
								{subcategories.map((item, index) => (
									<DraggableCard
										classes={classes}
										name={item.name}
										key={index}
									/>
								))}
							</div>
						</Grid>
					</Grid>
				</FormikForm>
			</Form>
		</Formik>
	)
}
