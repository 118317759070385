export default function commonPageStyles(theme) {
  return {
		breadcrumbsContainer: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		alert: {
			width: '80%',
			margin: '3% auto',
			fontSize: '1rem',
			borderColor: theme.palette.secondary.main,
			lineHeight: '1.5rem',
			'& p': {
				margin: 0,
			},
		},
		alertTitle: {
			fontWeight: '600',
		},
		contentContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			alignItems: 'center',
		},
		buttonContainer: {
			width: '100%',
			textTransform: 'uppercase',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
		},
		categoryName: {
			fontSize: '1rem',
			fontWeight: '600',
			width: '100%',
			lineHeight: '0.1rem',
			textAlign: 'center',
			margin: theme.spacing(4, 0),
			borderBottom: '1px solid #ddd',
		},
		linkWrapper: {
			textDecoration: 'none',
			color: 'inherit',
		},
		styledSpan: {
			background: theme.palette.background.default,
			padding: '5px 20px',
			border: '1px solid transparent',
			borderBottom: '1px solid #ddd',
			transition: 'border-color .5s',
			'&:hover': {
				border: '1px solid #ddd',
			},
		},
		cardContainer: {
			width: '100%',
			display: 'flex',
			flexFlow: 'row wrap',
		},
		loadMoreButton: {
			marginTop: theme.spacing(3),
		},
		heading: {
			fontFamily: 'Source Sans Pro',
			textTransform: 'uppercase',
			fontSize: '1.2rem',
			fontWeight: 700,
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				fontSize: '1.5rem',
				marginTop: theme.spacing(4),
				marginBottom: theme.spacing(4),
			},
		},
		subHeading: {
			fontFamily: 'Source Sans Pro',
			fontSize: '1rem',
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				fontSize: '1.3rem',
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(3),
			},
		},
	}
}