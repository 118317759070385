import React from 'react'
import { Button} from '@material-ui/core'
import { Container, CircularProgress } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'

export default function Form ({
  classes,
  children,
  submitButtonText,
  handleSubmit,
	performingAction,
	onCancel
}) {

	const pathHistory = useHistory()

	const handleCancel = onCancel
		? onCancel
		: () => {
				pathHistory.goBack()
		  }

  const  { submitForm } = useFormikContext()

  return (
		<Container className={classes.root}>
			{children}

			<div className={classes.buttonGroup}>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={submitForm}
					disabled={performingAction ? true : false}>
					{performingAction ? (
						<CircularProgress color="inherit" size="1.5rem" />
					) : (
						submitButtonText
					)}
				</Button>

				<Button
					variant="outlined"
					color="secondary"
					className={classes.button}
					onClick={handleCancel}
					disabled={performingAction ? true : false}>
					Cancel
				</Button>
			</div>
		</Container>
	)
}