const TagQueries = {
	addTag: `
		mutation addTag($name: String!) {
			createTag(input: { data: { name: $name } }) {
				tag {
					name
					id
				}
			}
		}
	`,
	getList: `
		query getAllTags($sort:String) {
			tags(sort: $sort){
				id
				name
			}
		}
	`,
	assignAnEdition: `
		mutation assignEdition($tag: ID!, $editions: [ID]! , ) {
			updateTag(input: {
				where: { id: $tag },
				data: { editions: $editions }
			}){
				tag {
					id
					editions{
						id
						title
					}
				}
			}
		}
	`,
}

export default TagQueries