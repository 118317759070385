import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  PrivacyPolicy,
  Archives,
  CC,
  CCCategory,
  CCSubcategory,
  Search,
  CategoryFormPage,
  SubcategoryFormPage,
  EditionFormPage,
  Tags,
  Category,
  Subcategory,
  Edition,
  Dashboard,
  PageNotFound,
  Admin,
} from '../../pages'

export default function Routes ({ openDialog, userRole, user , openSnackbar, products}) {

  const routes = [
		{ path: '/privacy-policy', component: PrivacyPolicy },
		{ path: '/archives', component: Archives, products: products },
		{ path: '/error404', component: PageNotFound },

		{ path: '/extended-content', component: CC, products: products },
		{
			path: '/extended-content/:categoryId',
			component: CCCategory,
			userRole: userRole,
			products: products,
		},
		{
			path: '/extended-content/:categoryId/:subcategoryId',
			component: CCSubcategory,
			userRole: userRole,
			products: products,
		},

		{ path: '/search', component: Search, products: products },
		{
			path: '/admin/category',
			component: Admin,
			userRole: userRole,
			openDialog: openDialog,
			openSnackbar: openSnackbar,
		},
		{
			path: '/admin/subcategory',
			component: Admin,
			userRole: userRole,
			openDialog: openDialog,
			openSnackbar: openSnackbar,
		},
		{
			path: '/admin/edition',
			component: Admin,
			userRole: userRole,
			openDialog: openDialog,
			openSnackbar: openSnackbar,
		},
		{
			path: '/admin',
			component: Admin,
			userRole: userRole,
			openDialog: openDialog,
			openSnackbar: openSnackbar,
		},
		{ path: '/admin/category/add', component: CategoryFormPage },
		{ path: '/admin/category/edit/:id', component: CategoryFormPage },
		{
			path: '/admin/subcategory/add',
			component: SubcategoryFormPage,
			openSnackbar: openSnackbar,
		},
		{
			path: '/admin/subcategory/edit/:id',
			component: SubcategoryFormPage,
			openSnackbar: openSnackbar,
		},
		{ path: '/admin/edition/add', component: EditionFormPage },
		{ path: '/admin/edition/edit/:id', component: EditionFormPage },		
		{ path: '/tags/:tag', component: Tags, products: products },
		{
			path: '/:categoryId',
			component: Category,
			userRole: userRole,
			products: products,
		},
		{
			path: '/:categoryId/:subcategoryId',
			component: Subcategory,
			userRole: userRole,
			products: products,
		},
		{
			path: '/:categoryId/:subcategoryId/:editionId',
			component: Edition,
			userRole: userRole,
			user: user,
			products: products,
			openSnackbar: openSnackbar,
		},
		{ path: '/', component: Dashboard, products: products },
		{ component: PageNotFound },
	]

  return (
		<Switch>
			{routes.map((route, index) => (
				<Route
					path={route.path ? route.path : null}
					render={(props) => (
						<route.component
							openDialog={openDialog}
							userRole={route.userRole ? route.userRole : null}
							openSnackbar={openSnackbar}
							user={route.user ? route.user : null}
							products={route.products ? route.products : null}
							{...props}
						/>
					)}
					exact
					key={index}></Route>
			))}
		</Switch>
	)
}