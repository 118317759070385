import React from "react"

import { SanitizeHTML } from "../../utilities"

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

export default function Info({ classes, title, content }) {
	return (
		<Alert
			severity="info"
			variant="outlined"
			icon={false}
			className={classes.alert}>
			<AlertTitle className={classes.alertTitle}>
				<SanitizeHTML html={title || ''} />
			</AlertTitle>
			<SanitizeHTML html={content} />
		</Alert>
	)
}