const ArchivesQueries = {
	getFeed: `
    query getFeed(
      $subcategoryId : ID
      $start : Int!,
      $limit : Int!,
    ){
      editionsConnection(
        start : $start, limit : $limit,
        where : {  archived: true , subcategory : { id : $subcategoryId }} ,
        sort : "date:DESC"   
      ){
        values : values{
          id
          title
          subtitle
          subcategory{
            id
            name
            restricted_access
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
}
export default ArchivesQueries