import React from 'react'
import { Container } from "@material-ui/core"
import { Helmet } from "react-helmet"

import { Footer, CustomBreadcrumbs, } from "../../blocks"

export default function Page ({
  className,
  title,
  breadcrumbs,
  children
}) {
  return (
    <div>
      <Helmet>
        <title>{ title } | Frontier Research</title>
      </Helmet>

      { // no breadcrumbs in Homepage and PageNotFound page
        breadcrumbs &&
        <Container className={className}>
          <CustomBreadcrumbs items={breadcrumbs} />
        </Container>
      }

      { children }

      <Footer />
    </div>
  )
}