const DeleteQueries = {
	editionSection: `
		mutation deleteEditionSection(
			$id : ID!
		) {
			deleteEditionSection(
				input: {
					where : { id : $id }
				}
			) {
				editionSection {
					id
					edition {
						id
						title
					}
					section {
						id
						name
					}
				}
			}
		}
	`,
	file: `
    mutation deleteFile(
      $id : ID!
    ){
      deleteFile(
        input: {
          where : { id : $id }
        }
      ){
        file{
					id
				}
      }
    }
  `,
	content: `
    mutation deleteContent(
      $id : ID!
    ){
      deleteContent(
        input: {
          where : { id : $id }
        }
      ){
        content{
					id
				}
      }
    }
  `,
	authorEdition: `
		mutation deleteAuthorEdition(
      $id : ID!
    ){
      deleteAuthorEdition(
        input: {
          where : { id : $id }
        }
      ){
        authorEdition{
					id
				}
      }
    }
	`,
	edition: `
		mutation deleteEdition(
      $id : ID!
    ){
      deleteEdition(
        input: {
          where : { id : $id }
        }
      ){
        edition{
					id
				}
      }
    }
	`,
	attachment: `
		mutation deleteAttachment(
      $id : ID!
    ){
      deleteAttachment(
        input: {
          where : { id : $id }
        }
      ){
        attachment{
					id
				}
      }
    }
	`,
}

export default DeleteQueries