export default function AdminStyles(theme) {
  return {
		container: {
			width: '100%',
			marginTop: theme.spacing(4),
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
			alignItems: 'flex-start',
		},
		tab: {
			paddingTop: '25%',
			paddingBottom: '25%',
		},
		horizontalTab: {

		},
		buttonGroup: {
			textAlign: 'right',
		},
		addItem: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			margin: theme.spacing(2, 0),
			marginTop: '0',
		},
		addItemButton: {
			margin: theme.spacing(0, 2),
		},
		pagination: {
			width: 'auto',
			display: 'flex',
			justifyContent: 'center',
			marginTop: theme.spacing(1),
		},
		content: {
			height: '420px',
		},
		spinner: {
			height: '240px',
			display: 'grid',
			placeItems: 'center',
		},
		tableControls: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		select: {
			marginRight: theme.spacing(3),
			minWidth: '150px'
		},
	}
}