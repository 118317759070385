const EditionSectionQueries = {
	create: `
		mutation addEditionSection(
			$position: Int!
			$edition: ID!
			$section: ID!
		) {
			createEditionSection(
				input: {
					data: { position: $position, edition: $edition, section: $section }
				}
			) {
				editionSection {
					id
					edition {
						id
						title
					}
					section {
						id
						name
					}
				}
			}
		}
	`,
	update: `
		mutation addEditionSection(
			$id : ID!
			$position: Int
			$edition: ID
			$section: ID
		) {
			updateEditionSection(
				input: {
					where : { id : $id } ,
					data: { position: $position, edition: $edition, section: $section }
				}
			) {
				editionSection {
					id
					edition {
						id
						title
					}
					section {
						id
						name
					}
				}
			}
		}
	`,
	delete: `
		mutation deleteEditionSection(
			$id : ID!
		) {
			deleteEditionSection(
				input: {
					where : { id : $id }
				}
			) {
				editionSection {
					id
					edition {
						id
						title
					}
					section {
						id
						name
					}
				}
			}
		}
	`,
	findEditionSectionByEdition: `
		query getEditionSections($id: String!) {
			editionSections(where: { edition: $id }) {
				id
				edition {
					id
					title
				}
				section {
					id
					name
				}
			}
		}
	`,
	findDuplicates: `
		query findEditionSection(
			$edition: ID!
		 	$section: ID!
		) {
			editionSections(where: { 
				edition: $edition 
				section: $section 
			}) {
					id
					position
					edition {
						id
						title
					}
					section {
						id
						name
					}
			}
		}
	`,
}

export default EditionSectionQueries

