import React, { Component } from 'react'
import * as Sentry from '@sentry/react' // Sentry for error logging
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import readingTime from 'reading-time'

// firebase auth
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/performance'

// custom
import settings from './settings'
import theme from './theme'
import { MuiThemeProvider, CircularProgress, Snackbar } from '@material-ui/core'
import { Login } from './components/pages'
import {
  PrimarySearchAppBar,
  ResetPasswordDialog,
  CustomDialog,
  Routes
} from './components/blocks'

import { getHermesData } from './components/helpers'
import './App.css'

import { ScrollToTop } from './components/utilities'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GraphqlRequest } from './components/services'
import { SubCategoryQueries } from './components/strapiQueries'


// Initializing firebase
firebase.initializeApp(settings.credentials.firebase)

const auth = firebase.auth()
auth.useDeviceLanguage()

if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line no-unused-vars
  const performance = firebase.performance()
}

class App extends Component {
	_isMounted = false

	state = {
		isAuthReady: false,
		isPerformingAuthAction: false,
		isSignedIn: false,

		user: null,
		userRole: null,
		subscribedProducts: [],

		snackbar: {
			open: false,
			autoHideDuration: 0,
			message: '',
		},

		dialog: {
			open: false,
			title: '',
			content: '',
			errorObject: '',
		},

		resetPasswordDialog: {
			open: false,
		},
	}

	emailLinkSignin = (email) => {
		this.setState(
			{
				isPerformingAuthAction: true,
			},
			() => {
				auth
					.signInWithEmailLink(email, window.location.href)
					.then((result) => {
						const {
							user: { displayName, email },
						} = result

						window.localStorage.removeItem('emailForSignIn')

						this.openSnackbar(`Signed in as: ${displayName || email}`)
					})
					.catch((reason) => {
						Sentry.captureException(reason)
						const { code, message } = reason

						switch (code) {
							case 'auth/invalid-email':
							case 'auth/user-disabled':
							case 'auth/user-not-found':
							case 'auth/wrong-password':
								this.openSnackbar(message)
								break

							default:
								this.openSnackbar(message)
								break
						}
					})
					.finally(() => {
						this.setState({
							isPerformingAuthAction: false,
						})
					})
			}
		)
	}

	async componentDidMount() {
		this._isMounted = true
		
		// Confirm the link is a sign-in with email link.
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			var email = window.localStorage.getItem('emailForSignIn')
			if (!email) {
				// If user opens the link on a different device
				email = window.prompt('Please provide your email for confirmation')
			}

			this.emailLinkSignin(email)
		}

		this.removeAuthObserver = auth.onAuthStateChanged(async (user) => {
			if (this._isMounted) {
				let role
				// Get user role from custom claims
				if (user) {
					const { claims } = await user.getIdTokenResult(true)
					let token = await user.getIdToken(true)

					localStorage.setItem('token', token)

					const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

					role = claims.role

					setInterval(async function(){
						let token = await user.getIdToken(true)
						localStorage.setItem('token', token)
					}, 30000)

          try {
						const url = `${settings.baseURL}/api/users-new/athena/${user.uid}`
						const data = await getHermesData(url, user)

						const productIds = data.products.map((product) => product.id)

						const subscribedItemsRes = await strapiGraphqlRequestClient.request(
							SubCategoryQueries.getSubscribedItems,
							{
								listofProducts : productIds
							}
						)

						const subscribedItemsList = subscribedItemsRes.subcategories.map((item) => (item.id))

						this.setState({ subscribedProducts: subscribedItemsList })
					} catch (err) {
						Sentry.captureException(err)
						console.log(err)
					}

					// Segment indetify call
					window.analytics.identify(user.uid, {
						name: user.displayName,
						email: user.email,
						categories: claims.categories,
					})

					// associate Sentry errors with user data
					Sentry.setUser({ email: user.email })
          
					// freshchat user identification
					window.fcWidget && window.fcWidget.user.setFirstName(user.displayName)
					window.fcWidget && window.fcWidget.user.setEmail(user.email)

				}

				this.setState({
					isAuthReady: true,
					isSignedIn: !!user,
					user,
					userRole: user ? role : null, // Store role as a separate property or it breaks other firebase methods on user
				})
				
			}
		})

		
	}

	async componentWillUnmount() {
		this._isMounted = false

		this.removeAuthObserver()	

	}

	openSnackbar = (message) => {
		this.setState({
			snackbar: {
				open: true,
				autoHideDuration: readingTime(message).time * 2,
				message,
			},
		})
	}

	closeSnackbar = (clearMessage = false) => {
		const { snackbar } = this.state

		this.setState({
			snackbar: {
				message: clearMessage ? '' : snackbar.message,
				open: false,
			},
		})
	}

	openDialog = async (errorObject, title, content) => {


		if(errorObject) {
			if(errorObject.response) {
				if (
					errorObject.response.errors[0].message === 'Invalid token.' ||
					errorObject.response.errors[0].message === 'Forbidden'
				) {
					const user = firebase.auth().currentUser
					let token = await user.getIdToken(true)
					localStorage.setItem('token', token)
					window.location.reload()

					return
				}
			}
		}

		setTimeout(() => {
			this.setState({
				dialog: {
					open: true,
					title: title || 'Oh no! Something went wrong!',
					content:
						content ||
						`Please try reloading the page. If the issue persists, please contact the tech team with the copied error message.`,
					errorObject,
				},
			})
		},1000)

		
	}

	closeDialog = (callback, clearMessage = false) => {
		const { dialog } = this.state

		this.setState(
			{
				dialog: {
					open: false,
					title: clearMessage ? '' : dialog.title,
					content: clearMessage ? '' : dialog.content,
					errorObject: clearMessage ? '' : dialog.errorObject,
				},
			},
			() => {
				if (typeof callback === 'function') {
					callback()
				}
			}
		)
	}

	openResetPasswordDialog = () => {
		this.setState({
			resetPasswordDialog: {
				open: true,
			},
		})
	}

	resetPassword = (emailAddress) => {
		if (!emailAddress) {
			return
		}

		this.setState(
			{
				isPerformingAuthAction: true,
			},
			() => {
				auth
					.sendPasswordResetEmail(emailAddress)
					.then(() => {
						this.closeResetPasswordDialog(() => {
							this.openSnackbar(`Password reset email sent to: ${emailAddress}`)
						})
					})
					.catch((reason) => {
						Sentry.captureException(reason)
						const { code, message } = reason

						switch (code) {
							case 'auth/invalid-email':
							case 'auth/missing-android-pkg-name':
							case 'auth/missing-continue-uri':
							case 'auth/missing-ios-bundle-id':
							case 'auth/invalid-continue-uri':
							case 'auth/unauthorized-continue-uri':
							case 'auth/user-not-found':
								this.openSnackbar(message)
								break

							default:
								this.openSnackbar(message)
								break
						}
					})
					.finally(() => {
						this.setState({
							isPerformingAuthAction: false,
						})
					})
			}
		)
	}

	closeResetPasswordDialog = (callback) => {
		this.setState(
			{
				resetPasswordDialog: {
					open: false,
				},
			},
			() => {
				if (callback && typeof callback === 'function') {
					callback()
				}
			}
		)
	}

	signIn = (emailAddress, password) => {
		const { isSignedIn } = this.state

		if (isSignedIn) {
			return
		}

		if (!emailAddress || !password) {
			return
		}

		this.setState(
			{
				isPerformingAuthAction: true,
			},
			() => {
				auth
					.signInWithEmailAndPassword(emailAddress, password)
					.then((value) => {
						const {
							user: { displayName, email },
						} = value

						this.openSnackbar(`Signed in as: ${displayName || email}`)
					})
					.catch((reason) => {
						Sentry.captureException(reason)
						const { code, message } = reason

						switch (code) {
							case 'auth/invalid-email':
							case 'auth/user-disabled':
							case 'auth/wrong-password':
									this.openSnackbar(message)
									break

							case 'auth/user-not-found':
									this.openSnackbar("There is no user record corresponding to this identifier. Make sure you have entered the correct email address registered on the platform or contact Frontier Team for assistance.")
									break

							default:
								this.openSnackbar(message)
								break
						}
					})
					.finally(() => {
						this.setState({
							isPerformingAuthAction: false,
						})
					})
			}
		)
	}

	signOut = () => {
		const { isSignedIn } = this.state

		if (!isSignedIn) {
			return
		}

		this.setState(
			{
				isPerformingAuthAction: true,
			},
			() => {
				auth
					.signOut()
					.then(() => {
						this.openSnackbar('Signed out')
					})
					.catch((reason) => {
						Sentry.captureException(reason)
						const { code, message } = reason

						switch (code) {
							default:
								this.openSnackbar(message)
								break
						}
					})
					.finally(() => {
						this.setState({
							isPerformingAuthAction: false,
						})
					})
			}
		)
	}

	render() {
		const {
			isAuthReady,
			isPerformingAuthAction,
			isSignedIn,
			snackbar,
			dialog,
			resetPasswordDialog,
			userRole,
			user,
			subscribedProducts
		} = this.state

		return (
			<Router>
				<ScrollToTop />
				<CssBaseline />

				<MuiThemeProvider theme={theme}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							minHeight: '100vh',
							paddingTop: '75px',
							backgroundColor: theme.palette.primary,
						}}>
						{!isAuthReady && <CircularProgress style={{ margin: 'auto' }} />}

						{isAuthReady && (
							<>
								{isSignedIn && (
									<>
										<PrimarySearchAppBar
											isSignedIn={isSignedIn}
											isPerformingAuthAction={isPerformingAuthAction}
											openResetPasswordDialog={this.openResetPasswordDialog}
											userRole={userRole}
											signOut={this.signOut}
											openDialog={this.openDialog}
										/>

										<Routes
											openDialog={this.openDialog}
											userRole={userRole}
											user={user}
											openSnackbar={this.openSnackbar}
											products={subscribedProducts}
										/>
									</>
								)}

								{!isSignedIn && (
									<Login
										signIn={this.signIn}
										openSnackbar={this.openSnackbar}
										isPerformingAuthAction={isPerformingAuthAction}
										openResetPasswordDialog={this.openResetPasswordDialog}
									/>
								)}
							</>
						)}

						<Snackbar
							className="data-hj-suppress"
							autoHideDuration={snackbar.autoHideDuration}
							message={snackbar.message}
							open={snackbar.open}
							onClose={this.closeSnackbar}
						/>

						<CustomDialog dialog={dialog} handleClose={this.closeDialog} />

						<ResetPasswordDialog
							open={resetPasswordDialog.open}
							isPerformingAuthAction={isPerformingAuthAction}
							resetPassword={this.resetPassword}
							onClose={this.closeResetPasswordDialog}
						/>
					</div>
				</MuiThemeProvider>
			</Router>
		)
	}
}

export default App
