import { algoliaClient } from '../services'

// select admin API key for the correct app on Algolia depending on environment
const apiKey = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_ALGOLIA_DEV_ADMIN_API_KEY
  : process.env.REACT_APP_ALGOLIA_PROD_ADMIN_API_KEY

const client = algoliaClient(apiKey)

const algoliaDataUpdate = ({
  objectID,
  title,
  date,
  contents,
  subcategory,
  index,
  operation
}) => {
  const { saveObject, partialUpdateObject, deleteObject } = client.initIndex(index)

  const data = index==="timeTwister" ?
    { objectID, title, date, contents } :
    { objectID, title, date, contents, subcategory }

  // updates indices only in production Algolia app
  // if needed to update indices in dev app as well, will just need to bypass this if() {}
  if (process.env.NODE_ENV !== 'development') {
    if (operation === 'add') {
      saveObject(
        data,
        { autoGenerateObjectIDIfNotExist: true }
      )
      .then(data => console.log(data))
      .catch(err => console.log(err))
    } 
    else if (operation === 'edit') {
      partialUpdateObject(
        data,
        { createIfNotExists: false }
      )
      .then(data => console.log(data))
      .catch(err => console.log(err))
    } 
    else if (operation === 'delete') {
      deleteObject(
        objectID
      )
      .then(data => console.log(data))
      .catch(err => console.log(err))
    }
  }
}

export default algoliaDataUpdate