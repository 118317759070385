const DashboardQueries = {
	getFeatured: `
    query getFeatured(
      $SubscribedList : [ID]!
    ){
      categoriesConnection{
        values : values{
          id
          name
          open : subcategories(where: { restricted_access : false }){
            id
            name
            editions( where : {archived: false , featured : true}){
              id
              title
              subtitle
              subcategory{
                id
                name
                category{
                  id
                  name
                }
              }
              url
              date
              cover_image{
                id
                name
                previewUrl
                url
                formats
              }
              file_content
              featured
              long_tail
              content_summary
              archived
              contents(limit : 1, sort :"created_at:ASC" ){
                id
                body
              }
              author_editions{
                id
                main
                author {
                  id
                  first_name
                  last_name
                }
              }
              tags{
                id
                name
              }
              published_at
            }
          }
          subscribed : subcategories(where: {restricted_access : true, id_in :$SubscribedList }){
            id
            name
            editions( where : {archived: false , featured : true}){
              id
              title
              subtitle
              subcategory{
                id
                name
                category{
                  id
                  name
                }
              }
              url
              date
              cover_image{
                id
                name
                previewUrl
                url
                formats
              }
              file_content
              featured
              long_tail
              content_summary
              archived
              contents(limit : 1, sort :"created_at:ASC" ){
                id
                body
              }
              author_editions{
                id
                main
                author {
                  id
                  first_name
                  last_name
                }
              }
              tags{
                id
                name
              }
              published_at
            }
          }
        }
      }
    }
  `,
}

export default DashboardQueries