import { Component } from 'react';
import PropTypes from 'prop-types';

export default class PermissibleRender extends Component {
    static propTypes = {
        userPermission: PropTypes.string.isRequired,
        requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
        children: PropTypes.element.isRequired,
        renderOtherwise: PropTypes.element,
    };

    checkPermissions() {
        const { userPermission, requiredPermissions } = this.props;

        return requiredPermissions.includes(userPermission)
    }

    render() {
        const { children, userPermission, requiredPermissions, renderOtherwise } = this.props;

        if (!children || !userPermission || !requiredPermissions) {
            return null;
        }

        if (this.checkPermissions()) {
            return children;
        } else if (renderOtherwise) {
            return renderOtherwise;
        }
        return null;
    }
}