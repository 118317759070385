import React from 'react'
import {
  withStyles,
  Paper,
  Grid,
  Typography,
  IconButton,
  ButtonGroup,
  Card,
  CardMedia
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
  row: {
    marginBottom: "10px",
    padding: "10px",
  }
})

const AssociatedItems = ({ modelInstance, includeImage, fullWidth, handleEditClick, handleDeleteClick, showPreview, disableEdit, disableDelete, classes }) => (
  <Paper className={classes.row} style={{ maxWidth: `${fullWidth ? "100%" : "50%"}` }}>
    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      {includeImage ? (
        <Grid item>
          <Card>
            <CardMedia
              component="img"
              height="100"
              alt={`Cover Image: ${modelInstance.fileName}`}
              title={`Cover Image: ${modelInstance.fileName}`}
              src={modelInstance.url}
            />
          </Card>
        </Grid>
      ) : null}

      <Grid item>
        <Typography>{modelInstance.fileName ? modelInstance.fileName : modelInstance.name}</Typography>
      </Grid>

      <Grid item>
        <ButtonGroup>
          {!disableEdit ? (
            <IconButton onClick={(event) => { handleEditClick(event, modelInstance) }}>
              <EditIcon />
            </IconButton>
          ) : null}

          {showPreview ? (
            <IconButton onClick={() => { window.open(modelInstance.url, "_blank") }}>
              <VisibilityIcon />
            </IconButton>
          ) : null}

          {!disableDelete ? (
            <IconButton onClick={(event) => { handleDeleteClick(event, modelInstance) }} disbaled={disableDelete}>
              <DeleteIcon />
            </IconButton>
          ) : null}
        </ButtonGroup>
      </Grid>
    </Grid>
  </Paper>
)

export default withStyles(styles)(AssociatedItems)