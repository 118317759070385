/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect, useMemo } from 'react'
import { CategoryQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'
import { Link } from 'react-router-dom'
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Button,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Chip from '@material-ui/core/Chip'
import { Info, ConfirmationDialog } from '../../blocks'
import { PermissibleRender } from "../../utilities"

function CategoryPanel({
	userRole,
	classes,
	openDialog,
	openSnackbar
}) {
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [categories, setCategories] = useState(null)
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
	const [focusItem, setFocusItem] = useState(null)

	const rowsperPage = 6

	const strapiGraphqlRequestClient = useMemo(
		() => new GraphqlRequest().strapiClient,
		[]
	)

	const fetchData = async () => {
		setLoading(true)
		const queryVars = {
			sort: 'published_at:desc',
		}

		const categories = (
			await strapiGraphqlRequestClient.request(
				CategoryQueries.getAll,
				queryVars
			)
		).categories

		setCategories(categories)
		setLoading(false)
	}

	useEffect(() => {
		const popluateData = async () => {
			try {
				await fetchData()
				setLoading(false)
			} catch (error) {
				openDialog(error)
			}
		}
		popluateData()
	}, [])

	const handlePageChange = (event, value) => {
		setPage(value)
	}

	const handleDelete = (item) => {
		setFocusItem(item)
		setDeleteDialogOpen(true)
	}

	const handleDeleteSubmit = async () => {
		const queryVars = {
			id: focusItem.id,
		}
		try {
			await strapiGraphqlRequestClient.request(
				CategoryQueries.delete,
				queryVars
			)
			openSnackbar(`Category "${focusItem.name}" Deleted Succesfully.`)

		} catch (error) {
			openSnackbar(`Category "${focusItem.name}" Deleting Failed.`)
		}


		setDeleteDialogOpen(false)
		await fetchData()
	}

	return (
		<>
			<div className={classes.addItem}>
				<Typography>{`Add Category`}</Typography>
				<Link to="/admin/category/add">
					<Button className={classes.addItemButton}>
						<AddCircleIcon />
					</Button>
				</Link>
			</div>
			{loading ? (
				<div className={classes.spinner}>
					<CircularProgress />
				</div>
			) : (
				<>
					<TableContainer className={classes.content}>
						<Table>
							<TableBody>
								<>
									{categories.length !== 0 ? (
										<>
											{categories
												.slice((page - 1) * rowsperPage, page * rowsperPage)
												.map((item) => (
													<TableRow key={item.id}>
														<TableCell component="th" scope="row">
															{item.name || item.title}
														</TableCell>
														<TableCell></TableCell>
														<TableCell
															component="th"
															scope="row"
															className={classes.buttonGroup}>
															<Chip
																label={
																	item.published_at ? 'Published' : 'Draft'
																}
															/>
															<Link to={`/admin/category/edit/${item.id}`}>
																<Button>
																	<EditIcon />
																</Button>
															</Link>
															<PermissibleRender
																userPermission={userRole}
																requiredPermissions={["dev", "editor"]}
															>
																<Button onClick={() => handleDelete(item)}>
																	<DeleteIcon />
																</Button>
															</PermissibleRender>
														</TableCell>
													</TableRow>
												))}
										</>
									) : (
										<Info
											classes={classes}
											content="No data under this section :("
										/>
									)}
								</>
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						className={classes.pagination}
						count={Math.ceil(categories.length / rowsperPage)}
						page={page}
						onChange={handlePageChange}
					/>
				</>
			)}
			<ConfirmationDialog
				open={deleteDialogOpen}
				handleClose={() => setDeleteDialogOpen(false)}
				handleSubmit={handleDeleteSubmit}
				alertSeverity={'warning'}
				title="Hold Up!"
				textContent={`Are you sure you want to delete ${focusItem === null ? '' : focusItem.name
					}`}
				alertContent={
					<>
						This will un-publish{' '}
						<strong>
							all associated sub-categories, editions, sections, content and
							attachments
						</strong>{' '}
						under this Category.
						<br />
						<br />
						This action <strong>is</strong> reversible. Contact the Tech Team to
						have it reversed, if you need to.
					</>
				}
				confirmButtonText="Yes, I'm sure"
				cancelButtonText="Cancel"
			/>
		</>
	)
}

export default CategoryPanel
