import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, CircularProgress, Link, Typography } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert'

export default function LoadMoreButton({
  classes,
  hasNextPage,
  handleLoadMore,
  loadingFeed,
  archives
}) {
  return (
    <div className={classes.loadMoreButton}>
      {hasNextPage ?
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoadMore}
        >
          {loadingFeed ? <CircularProgress color="inherit" /> : "Load More"}
        </Button>
        : (
          // if NOT archives page, show link to archives. Else, show link to CC
          <Alert severity="error" variant="outlined" className={classes.alert}>
            <Typography>
              That's all for the moment <span role="img" aria-label="frown face emoji">☹️</span>.
              {' '}
              Visit the <Link to={!archives ? "/archives" : "/extended-content"} component={RouterLink} color="secondary">{!archives ? "Archives" : "Curious Corner"}</Link> for more!
            </Typography>
          </Alert>
        )}
    </div>
  )
}