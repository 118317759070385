function ButtonStyles(theme) {
  return ({
    buttonBox: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap'
    },
    buttonLinkWrapper: {
      textDecoration: "none",
    },
    button: {
      width: 'auto',
      fontFamily: 'Assistant',
      fontWeight: 600,
      textTransform: 'capitalize',
      padding: theme.spacing(1, 3),
      margin: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        fontSize: '1rem',
      },
    },
  })
}

export default ButtonStyles