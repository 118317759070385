import React, { Component } from 'react'
import {
  withStyles,
  Container
} from "@material-ui/core"

import { GraphqlRequest } from '../../services'
import {
  Page,
  CardContainer,
  ButtonContainer,
  LoadMoreButton,
  Info
} from "../../blocks"
import { CCDashboardQueries } from '../../strapiQueries'
import { filterUnauthorizedEditions } from '../../helpers'
import { commonPageStyles } from "../../styles"

const styles = (theme) => (commonPageStyles(theme))

class CC extends Component {
	state = {
		featured: null,
		feed: null,

		hasNextPage: true,
		endCursor: 0,
		loadingFeed: false,
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	getInitialData = async () => {
		const { openDialog, products } = this.props

		try {
			const featuredDataRes = await this.strapiGraphqlRequestClient.request(
				CCDashboardQueries.getFeatured,
			)

			const data = featuredDataRes.editionsConnection.values

			const featuredEditions = filterUnauthorizedEditions(
				data,
				products
			)

			this.setState({
				featured: featuredEditions
			})

			const feedDataRes = await this.strapiGraphqlRequestClient.request(
				CCDashboardQueries.getFeed,
				{
					start: 0,
					limit: 10,
				}
			)

			const count = feedDataRes.editionsConnection.values.length

			const feedEditions = filterUnauthorizedEditions(
				feedDataRes.editionsConnection.values,
				products
			)

			this.setState({
				feed: feedEditions,
				endCursor: this.state.endCursor + count,
				hasNextPage: count > 9,
			})

			window.analytics.page('Curious Corner', {
				title: 'Curious Corner | Frontier Research',
			})
		} catch (error) {
			openDialog(error)
		}
	}

	handleLoadMore = async () => {
		const { openDialog, products } = this.props
		const { endCursor, feed } = this.state

		this.setState({ loadingFeed: true })

		try {
			const feedDataRes = await this.strapiGraphqlRequestClient.request(
				CCDashboardQueries.getFeed,
				{
					start: endCursor,
					limit: 10,
				}
			)

			const count = feedDataRes.editionsConnection.values.length

			const newFeed = filterUnauthorizedEditions(
				feedDataRes.editionsConnection.values,
				products
			)

			this.setState({
				feed: [...feed, ...newFeed],
				endCursor: this.state.endCursor + count,
				hasNextPage: count > 9,
				loadingFeed: false,
			})
		} catch (error) {
			openDialog(error)
		}
	}

	componentDidMount() {
		this.getInitialData()
	}

	render() {
		const { featured, feed, hasNextPage, loadingFeed } = this.state
		const { classes, products } = this.props

		const breadcrumbs = [
			{
				content: 'Curious Corner',
				href: null,
			},
		]

		return (
			<Page
				className={classes.breadcrumbsContainer}
				title="Curious Corner"
				breadcrumbs={breadcrumbs}>
				<Container className={classes.breadcrumbsContainer} maxWidth="lg">
					<Info
						classes={classes}
						title="Welcome to Frontier's <strong>Curious Corner!</strong>"
						content="Our economic, equity strategy and sector research that we think would only interest a small sub-set of our clients."
					/>
				</Container>

				<Container className={classes.contentContainer} maxWidth="lg">
					<CardContainer
						classes={classes}
						cardList={featured}
						cardType="featured"
						heading="Featured Content:"
					/>
					<ButtonContainer
						classes={classes}
						heading="Subcategories under Curious Corner:"
						longTail={true}
						products={products}
					/>
					<CardContainer
						classes={classes}
						cardList={feed}
						cardType="feed"
						heading="Recent and Beyond:"
					/>
					<LoadMoreButton
						classes={classes}
						hasNextPage={hasNextPage}
						handleLoadMore={this.handleLoadMore}
						loadingFeed={loadingFeed}
					/>
				</Container>
			</Page>
		)
	}
}

export default withStyles(styles)(CC)