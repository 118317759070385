
const AuthorEditionQueries = {
	create: `
		mutation addAuthorEdition($main: Boolean!, $author: ID!, $edition: ID!) {
			createAuthorEdition(
				input: { data: { main: $main, author: $author, edition: $edition } }
			) {
				authorEdition {
					id
					main
					edition {
						title
						id
					}
					author {
						id
						first_name
						last_name
					}
				}
			}
		}
	`,
	findAuthorEdition: `
		query getAuthorEdition($author: ID!, $edition: ID!) {
			authorEditions(where: { author: $author, edition: $edition }) {
				id
				main
				edition {
					title
					id
				}
				author {
					id
					first_name
					last_name
				}
			}
		}
	`,
	delete: `
		mutation deleteAuthorEdition($id : ID!){
			deleteAuthorEdition(input: { where: { id : $id } }){
				authorEdition {
					id
				}
			}
		}
	`,
}

export default AuthorEditionQueries
