const CategoryQueries = {
	getAllPublished: `
		query{
			categories(publicationState: LIVE ) {
				id
				name
				published_at
			}
		}
	`,
	archiveSubcategories: `
		query withSubcategories( $subscribedList: [ID]! ) {
			categoriesConnection{
        values : values{
          id
          name
        	subcategories(where: { _or : [ {restricted_access : false} , {restricted_access : true, id_in : $subscribedList }] } , sort : "name:ASC"){
            id
            name
            editions(where : { archived : true }, limit :1){
              id
            }
          }
        }
      }
		}
	`,
	getAll: `
		query getCategoryAll( $sort:String) {
			categories(publicationState: PREVIEW,  sort: $sort ) {
				id
				name
				published_at
			}
		}
	`,
	getAllWithSubcategories: `
		query {
			categories(publicationState: PREVIEW, sort : "published_at:desc") {
				id
				name
				subcategories{
					id
					name
				}				
			}
		}
	`,
	addCategory: `
		mutation addCategory(
			$name: String!
			$description: String
			$CC_description: String
			$published_at: DateTime
		) {
			createCategory(
				input: {
					data: {
						name: $name
						description: $description
						CC_description: $CC_description
						published_at: $published_at
					}
				}
			) {
				category {
					id
					name
					description
					CC_description
				}
			}
		}
	`,
	findCategoryByName: `
		query getCategory($name: String!) {
			categories(where: { name: $name }, publicationState: PREVIEW) {
				id
				name
			}
		}
	`,
	findCategoryById: `
		query getCategory($category_id: ID!) {
			category(id: $category_id, publicationState : PREVIEW) {
				name
				id
				description
				CC_description
				subcategories{
					id
					name
				}
				published_at
			}
		}
	`,
	update: `
		mutation updateCategory(
			$id: ID!,
			$name: String!
			$description: String
			$CC_description: String
			$published_at: DateTime
		){
			updateCategory(
				input: {
					where : { id : $id },
					data: {
						name: $name
						description: $description
						CC_description: $CC_description
						published_at: $published_at
					}
				}
			) {
				category {
					id
					name
					description
					CC_description
				}
			}
		}
	
	`,
	delete: `
		mutation deleteCategory(
			$id: ID!,
		){
			deleteCategory ( input : { where : { id : $id } }){
				category{
					id
					name
				}
			}
		}
	`,
}


export default CategoryQueries