import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	spinner: {
		height: '440px',
		display: 'grid',
		placeItems: 'center',
	},
}))


function LoadingSpinner() {

  const classes = useStyles()

  return (
		<div className={classes.spinner}>
			<CircularProgress />
		</div>
	)
}

export default LoadingSpinner
