import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build'
import settings from '../../../../settings'


export default function TextEditor({
	value, // "description "
	changeHandler,
	label, // For editions, this should be the section under which the content belongs
}) {

	class uploadAdapter {
    constructor( loader ) {
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {

              const token = localStorage.getItem('token')
							const data = new FormData()
							data.append('files', file)

							fetch(`${settings.strapiURL}/upload`, {
								method: 'POST',
								headers: {
									Authorization: token ? `Bearer ${token}` : '',
								},
								body: data,
							})
							.then(response => response.json())
							.then((response) => {
								if (!response || response.error) {
									return reject(
										response && response.error
											? response.error.message
											: 'error'
									)
								}
								resolve({
									default: response[0].url,
								})
							})
                
            }
					))
    }

   
	}

	const editorConfiguration = {
		toolbar: {
			items: [
				'heading',
				'|',
				'bold',
				'italic',
				'|',
				'numberedList',
				'bulletedList',
				'|',
				'indent',
				'outdent',
				'|',
				'link',
				'blockQuote',
				'imageUpload',
				'insertTable',
				'mediaEmbed',
				'|',
				'undo',
				'redo',
			],
		},
		image: {
			resizeOptions: [
				{
					name: 'resizeImage:original',
					value: '100',
					icon: 'original',
				},
				{
					name: 'resizeImage:50',
					value: '50',
					icon: 'medium',
				},
				{
					name: 'resizeImage:75',
					value: '75',
					icon: 'large',
				},
			],
			styles: ['full', 'side', 'alignLeft', 'alignRight', 'alignCenter'],
			toolbar: [
				'imageStyle:alignLeft',
				'imageStyle:alignCenter',
				'imageStyle:alignRight',
				'|',
				'imageTextAlternative',
				'resizeImage:50',
				'resizeImage:75',
				'resizeImage:original',
			],
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
		},
	}
  return (
		<CKEditor
			editor={ClassicEditor}
			onChange={changeHandler}
			onReady={(editor) => {
				editor.setData(value? value : '')
				editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
					return new uploadAdapter(loader)
				}
			}}
			config={editorConfiguration}
		/>
	)
}