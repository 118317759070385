const CategoryPageQueries = {
	getCategoryData: `
    query categoryData(
    $categoryId : ID!
  ){
    category ( id : $categoryId) {
      id
      name
      description
      CC_description
    }
  }
  `,
	getFeatured: `
    query getFeatured(
      $categoryId : ID
      $longTail : Boolean!
      $subscribedList : [ID]!
    ){
      categoriesConnection(where : {id : $categoryId}){
        values : values{
          id
          name
          open : subcategories(where: { restricted_access : false }){
            id
            name
            editions( where : {archived: false , featured : true, long_tail : $longTail }){
              id
              title
              subtitle
              subcategory{
                id
                name
                category{
                  id
                  name
                }
              }
              url
              date
              cover_image{
                id
                name
                previewUrl
                url
                formats
              }
              file_content
              featured
              long_tail
              content_summary
              archived
              contents(limit : 1, sort :"created_at:ASC" ){
                id
                body
              }
              author_editions{
                id
                main
                author {
                  id
                  first_name
                  last_name
                }
              }
              tags{
                id
                name
              }
              published_at
            }
          }
          subscribed : subcategories(where: {restricted_access : true, id_in :$subscribedList }){
            id
            name
            editions( where : {archived: false , featured : true , long_tail : $longTail}){
              id
              title
              subtitle
              subcategory{
                id
                name
                category{
                  id
                  name
                }
              }
              url
              date
              cover_image{
                id
                name
                previewUrl
                url
                formats
              }
              file_content
              featured
              long_tail
              content_summary
              archived
              contents(limit : 1, sort :"created_at:ASC" ){
                id
                body
              }
              author_editions{
                id
                main
                author {
                  id
                  first_name
                  last_name
                }
              }
              tags{
                id
                name
              }
              published_at
            }
          }
        }
      }
    }
  `,
	getFeed: `
    query getFeatured(
      $categoryId : ID
      $longTail : Boolean!
      $start : Int!,
      $limit : Int!,
    ){
      editionsConnection(
        start : $start, limit : $limit,
        where : { long_tail : $longTail , archived : false , featured : false , subcategory : { category :  { id : $categoryId } } },
        sort : "date:DESC"   
      ){
        values : values{
          id
          title
          subtitle
          subcategory{
            id
            name
            restricted_access
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
}

export default CategoryPageQueries