import React, { useEffect, useState, useCallback } from "react"
import { Link as RouterLink, withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react' // for error logging
import {
  makeStyles,
  AppBar,
  Toolbar,
  Link,
  IconButton,
  InputBase,
  Hidden,
  ListItem,
  ListItemText
} from "@material-ui/core"
import SettingsIcon from '@material-ui/icons/Settings';
import frontierLogo from "../../../images/logos/frontier-logo-with-padding.png"

import {
  MoreVert as MoreIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
  AccountCircle
} from "@material-ui/icons"


import { PermissibleRender } from '../../utilities'
import { NavBarStyles } from "../../styles"
import DrawerMenu from "./DrawerMenu"
import OptionsMenu from "./OptionsMenu"
import { CategoryQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'


const useStyles = makeStyles((theme) => (NavBarStyles(theme)))

function PrimarySearchAppBar(props) {
  const [menuItems, setMenuItems] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const { openDialog } = props

  const fetchCategories = useCallback(async () => {

    const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

    try {
      const fetchCategoriesList = await strapiGraphqlRequestClient.request(
        CategoryQueries.getAllPublished
      )

      const data = fetchCategoriesList.categories

      // hashmap to store categories from db { categoryID: { name, link }, ... }
      let categories = {}
      
      let menuItems = []

      // populate 'categories'
      data.forEach((category) => {
        categories[category.id] = {
          name: category.name,
          link: `/${category.id}`,
        }
      })
      
      menuItems[0] = categories[1] // core reports
      menuItems[1] = categories[3] // frontier videos
      menuItems[2] = categories[5] // webinar summary notes
      menuItems[4] = { name: 'Curious Corner', link: '/extended-content' }
      menuItems[5] = categories[4] // grab bag
      menuItems[6] = { name: 'Archives', link: '/archives', }
      
      setMenuItems(menuItems)
    } catch (error) {
      Sentry.captureException(error)
      openDialog(error)
    }
  }, [openDialog])


  useEffect(() => {
    async function fetchCategoryData() {
      await fetchCategories()
    }
    fetchCategoryData()
  }, [fetchCategories])

  const isMenuOpen = Boolean(anchorEl)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOptionsMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOptionsMenuClose = () => {
    setAnchorEl(null)
  }

  const signOut = () => {
    props.signOut()
  }

  const openResetPassword = () => {
    props.openResetPasswordDialog()
  }

  const goToSearch = () => {
    props.history.push({
      pathname: "/search"
    })
  }

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={`${classes.appBar} ${open ? classes.appBarShift : null}`}>
        <Toolbar style={{ display: 'flex', justifyContent: "space-between" }}>
          <Link to='/' component={RouterLink}>
            <img
              className={classes.logo}
              src={frontierLogo}
              alt="Frontier Research Logo"
            />
          </Link>

          <IconButton
            className={`${classes.menuButton} ${open ? classes.hide : null}`}
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>

          <div className="icons">
            <IconButton
              color="default"
              aria-label="search"
              onClick={goToSearch}
              className={classes.navIcon}
            >
              <SearchIcon />
            </IconButton>

            <PermissibleRender
              userPermission={props.userRole}
              requiredPermissions={["dev", "editor", "member", "remoteNews", "remoteMailing"]}
            >
              <Link to='/admin/edition' component={RouterLink}>
                <IconButton className={`${classes.addButton} ${classes.navIcon}`}>
                  <SettingsIcon />
                </IconButton>
              </Link>
            </PermissibleRender>

            <IconButton
              aria-label="Open user account menu"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleOptionsMenuOpen}
              color="default"
              className={classes.navIcon}
            >
              <Hidden xsDown implementation="js">
                <AccountCircle />
              </Hidden>

              <Hidden smUp implementation="js">
                <MoreIcon />
              </Hidden>
            </IconButton>
          </div>
        </Toolbar>

        <Toolbar className={classes.menuBar}>
          {
            menuItems.map(menuItem => (
              <Link to={menuItem.link} className={classes.drawerLink} component={RouterLink} key={menuItem.name}>
                <ListItem className={classes.menuBarLink}>
                  <ListItemText disableTypography primary={menuItem.name} />
                </ListItem>
              </Link>
            ))
          }
        </Toolbar>
      </AppBar>

      <div className={classes.toolbar} />

      <DrawerMenu
        classes={classes}
        handleDrawerClose={handleDrawerClose}
        open={open}
        menuItems={menuItems}
      />

      <OptionsMenu
        anchor={anchorEl}
        id="primary-search-account-menu"
        isOpen={isMenuOpen}
        handleOnClose={handleOptionsMenuClose}
        openResetPassword={openResetPassword}
        signOut={signOut}
      />
    </div>
  )
}

export default withRouter(PrimarySearchAppBar)
