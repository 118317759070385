import React from 'react'
import { Container, TextField } from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

export default function MultiSelectInput({
	name,
	value,
	label,
	changeHandler,
	menuItemsArray,
	className,
	getOptionDisabled,
}) {
	const getOptions = () => {
		const menuItems = []
		if (menuItemsArray) {
			menuItemsArray.map((option) =>
				menuItems.push({
					id: option.id,
					name: option.firstName
						? `${option.firstName} ${option.lastName}`
						: option.name,
				})
			)
		}

		return menuItems
	}

	return (
		<Container className={className}>
			<Autocomplete
				multiple
				required
				id={`${name}-selector`}
				name={name}
				getOptionDisabled={getOptionDisabled}
				onChange={(event, value) => {
					changeHandler(name, value)
				}}
				options={getOptions()}
				getOptionLabel={(option) => option.name}
				value={value}
				filterSelectedOptions
				renderInput={(params) => (
					<TextField
						{...params}
						margin="normal"
						variant="outlined"
						label={label}
						placeholder={`Add ${label}`}
					/>
				)}
			/>
		</Container>
	)
}
