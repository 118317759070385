import React from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import  { List, ListItem, ListItemSecondaryAction, ListItemText, IconButton  } from '@material-ui/core'


function InputItems({list , handleClear}) {

  return (
		<List dense style={{ width: '100%' }}>
			{list.map((item, index) => (
				<ListItem key={index}>
					<ListItemText primary={item.name} />
					<ListItemSecondaryAction>
						<IconButton
							edge="end"
							aria-label="delete"
							onClick={() => {
								handleClear(index)
							}}>
							<ClearIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			))}
		</List>
	)
}

export default InputItems
