import React, { useState } from "react"
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  withStyles
} from "@material-ui/core"
import Alert from '@material-ui/lab/Alert'

import { SingleLineText } from "../FormFields"

const styles = (theme) => ({
  dialogHeight: {
    maxHeight: "90vh"
  },
  extraSecureBox: {
    marginTop: "10px",
    margingBottom: "10px"
  }
})

const ConfirmationDialog = ({
  open, // Boolean to decide when to open the dialog
  handleClose, // Function to close the dialog
  handleSubmit, // Function to submit the dialog or confirm the action
  alertSeverity, // The severity level for the alert box
  title, // Title of the dialog
  textContent, // Text content inside the dialog
  alertContent, // Content inside the alert box
  extraSecurity, // Boolean to decide when to display the input element for the user to type, before being allowed to confirm
  confirmButtonText,
  cancelButtonText,
  classes // Classes from M-UI's withStyles HOC
}) => {
  const [confirmationText, setConfirmationtext] = useState("")

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.dialogHeight }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {textContent}
        </DialogContentText>

        <Alert severity={alertSeverity}>
          <Typography>
            {alertContent}
          </Typography>
        </Alert>

        {extraSecurity ? (
          <SingleLineText
            name="confirmationText"
            value={confirmationText}
            changeHandler={(event) => setConfirmationtext(event.target.value)}
            placeholder="Type the word DELETE to confirm"
            className={classes.extraSecureBox}
          />
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.formButton}
          onClick={handleClose}
        >
          {cancelButtonText}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          disabled={extraSecurity ? confirmationText !== "DELETE" : false}
          onClick={() => { handleSubmit() }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ConfirmationDialog)