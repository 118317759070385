import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  typography: {
    fontFamily: ['Assistant', 'sans-serif'].join(', ')
  },
  palette: {
    primary: { main: '#4A0C0E' },
    secondary: { main: '#CB2026' },
  },
})

export default theme
