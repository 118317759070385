import React, { Component } from "react"
import { withStyles, Container } from "@material-ui/core"

import {
    Page,
    CardContainer,
    ButtonContainer,
    LoadMoreButton,
    Info,
    LoadingSpinner,
} from "../../blocks"
import { GraphqlRequest } from "../../services"
import { SubcategoryPageQueries } from "../../strapiQueries"
import { commonPageStyles } from "../../styles"
import subcategoryLinks from "../../../redirectData/subcategories.json"

const styles = (theme) => commonPageStyles(theme)

class CCSubcategory extends Component {
    state = {
        category: null,
        subcategory: null,
        featured: null,
        feed: null,

        hasNextPage: true,
        endCursor: "",
        loadingFeed: false,

        loadingData: true,
        hasAccess: false,
    }

    strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

    getInitialData = async () => {
        const {
            openDialog,
            match: { params },
            products,
            history,
            location,
        } = this.props

        const dataPath = location.pathname.replace("/extended-content", "").replace(/\/$/, "")

        if (dataPath in subcategoryLinks) {
            history.push(`/extended-content/${subcategoryLinks[dataPath]}`)
            return
        }

        try {
            if (isNaN(params.subcategoryId)) {
                window.location.href = "/error404"
                return
            }
            const subcategoryDataRes =
                await this.strapiGraphqlRequestClient.request(
                    SubcategoryPageQueries.getSubcategoryData,
                    {
                        subcategoryId: params.subcategoryId,
                    }
                )

            if (!subcategoryDataRes.subcategory) {
                window.location.href = "/error404"
                return
            }

            this.setState(
                {
                    subcategory: subcategoryDataRes.subcategory,
                    category: subcategoryDataRes.subcategory.category,
                },
                () => {
                    const {
                        subcategory: { name },
                    } = this.state

                    window.analytics.page(
                        `Curious Corner - ${name} Subcategory`,
                        {
                            title: `Curious Corner - ${name} | Frontier Research`,
                        }
                    )
                }
            )

            if (
                !subcategoryDataRes.subcategory.restricted_access ||
                products.includes(subcategoryDataRes.subcategory.id)
            ) {
                const featuredDataRes =
                    await this.strapiGraphqlRequestClient.request(
                        SubcategoryPageQueries.getFeatured,
                        {
                            subcategoryId: params.subcategoryId,
                            longTail: true,
                        }
                    )

                this.setState({
                    featured: featuredDataRes.editionsConnection.values,
                })

                const feedDataRes =
                    await this.strapiGraphqlRequestClient.request(
                        SubcategoryPageQueries.getFeed,
                        {
                            subcategoryId: params.subcategoryId,
                            longTail: true,
                            start: 0,
                            limit: 10,
                        }
                    )

                const count = feedDataRes.editionsConnection.values.length

                this.setState({
                    feed: feedDataRes.editionsConnection.values,
                    endCursor: this.state.endCursor + count,
                    hasNextPage: count > 9,
                    hasAccess: true,
                    loadingData: false,
                })
            } else {
                this.setState({
                    hasAccess: false,
                    loadingData: false,
                })

                return
            }
        } catch (error) {
            openDialog(error)
        }
    }

    handleLoadMore = async () => {
        const {
            openDialog,
            match: { params },
        } = this.props
        const { endCursor, feed } = this.state

        this.setState({ loadingFeed: true })

        try {
            const feedDataRes = await this.strapiGraphqlRequestClient.request(
                SubcategoryPageQueries.getFeed,
                {
                    subcategoryId: params.subcategoryId,
                    longTail: true,
                    start: endCursor,
                    limit: 10,
                }
            )

            const count = feedDataRes.editionsConnection.values.length

            this.setState({
                feed: [...feed, ...feedDataRes.editionsConnection.values],
                endCursor: this.state.endCursor + count,
                hasNextPage: count > 9,
                loadingFeed: false,
            })
        } catch (error) {
            openDialog(error)
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    componentDidUpdate(prevProps) {
        const {
            match: { params },
        } = this.props

        if (params.subcategoryId !== prevProps.match.params.subcategoryId) {
            this.getInitialData()
        }
    }

    render() {
        const {
            category,
            subcategory,
            featured,
            feed,
            hasNextPage,
            loadingFeed,
            hasAccess,
            loadingData,
        } = this.state
        const {
            classes,
            match: { params },
            products,
        } = this.props

        const breadcrumbs = [
            {
                content: "Curious Corner",
                href: "/extended-content",
            },
            {
                content: category ? category.name : "Loading...",
                href: `/extended-content/${params.categoryId}`,
            },
            {
                content: subcategory ? subcategory.name : "Loading...",
                href: null,
            },
        ]

        return (
            <Page
                className={classes.breadcrumbsContainer}
                title={`Curious Corner - ${
                    subcategory ? subcategory.name : ""
                }`}
                breadcrumbs={breadcrumbs}
            >
                <Container className={classes.contentContainer} maxWidth="lg">
                    {loadingData ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {hasAccess ? (
                                <>
                                    <CardContainer
                                        classes={classes}
                                        cardList={featured}
                                        cardType="featured"
                                        heading={
                                            category
                                                ? `Featured ${category.name}:`
                                                : null
                                        }
                                    />
                                    <ButtonContainer
                                        classes={classes}
                                        heading={
                                            category
                                                ? `Jump to other Subcategories under Curious Corner ${category.name}:`
                                                : null
                                        }
                                        category={category ? category : null}
                                        longTail={true}
                                        currentSubcategoryId={
                                            params.subcategoryId
                                        }
                                        products={products}
                                    />
                                    <CardContainer
                                        classes={classes}
                                        cardList={feed}
                                        cardType="feed"
                                        heading="Recent and Beyond:"
                                    />
                                    <LoadMoreButton
                                        classes={classes}
                                        hasNextPage={hasNextPage}
                                        handleLoadMore={this.handleLoadMore}
                                        loadingFeed={loadingFeed}
                                    />
                                </>
                            ) : (
                                <Info
                                    classes={classes}
                                    title="Unauthorized!"
                                    content={`
                                        <p>If you are unable to view the page, this content might not be accessible to you, due to your current subscription status with Frontier. To subscribe, contact us at <a href="mailto:clientconnect@frontiergroup.info">clientconnect@frontiergroup.info</a>.</p>
                                        <br />
                                        <p>If you have already subscribed to this service and still not able to view - try refreshing in a couple of minutes as sometimes due to issues in network you may not be able to access this.
                                        Contact us on <a href="tel:+94114373477">011 4373477</a> for any assistance required.</p>
                                    `}
                                />
                            )}
                        </>
                    )}
                </Container>
            </Page>
        )
    }
}

export default withStyles(styles)(CCSubcategory)
