import React, { Component } from "react"
import * as Sentry from '@sentry/react' // for error logging
import {
  withStyles,
  Container,
} from "@material-ui/core"

import { Page } from "../../blocks"
import { CategoryForm } from "../../forms"
import { commonPageStyles } from '../../styles'
import { GraphqlRequest } from '../../services'
import { CategoryQueries } from '../../strapiQueries'


const styles = (theme) => (commonPageStyles(theme))

class CategoryFormPage extends Component {
  state = {
    category: false ,// Category id
    loading: true,
    formvalues: null,
    subcategories : []
  }

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

  getCurrentValues = async () => {
    const { openDialog, match: { params } } = this.props

    try {
			const res = await this.strapiGraphqlRequestClient.request(
				CategoryQueries.findCategoryById,
				{ category_id: params.id }
			)

      const { name, description, CC_description, published_at , subcategories} = res.category

			let formvalues = {
				name,
				description: description === null ? '' : description,
				CC_description: CC_description === null ? '' : CC_description,
				published: published_at === null ? false : true,
			}

			this.setState({ category: res.category.id , loading: false , formvalues,subcategories })
		} catch (err) {
			Sentry.captureException(err)
      openDialog(err)
      console.log(err) // GraphQL response errors
		}
  }

  componentDidMount() {
    const { match: { params } } = this.props

    if (params.id) {
      this.getCurrentValues()
    } else {
      this.setState({
				category: false,
				loading: false,
        formvalues: null,
        subcategories: []
			})
    }
  }


  render() {
    const { category, loading, formvalues, subcategories } = this.state

    const { classes } = this.props

    const breadcrumbs = [
			{
				content: 'Admin',
				href: '/admin',
			},
			{
				content: category
					? `Editing ${formvalues.name} Category`
					: 'Add a Category',
				href: null,
			},
		]

    return (
			<Page
				className={classes.breadcrumbsContainer}
				title={`${
					category ? `Editing ${formvalues.name} Category` : 'Add a Category'
				}`}
				breadcrumbs={breadcrumbs}>
				<Container className={classes.contentContainer} maxWidth="lg">
					{
						// 'action' prop is for submit button text in the form component
					}
					{!loading && (
						<CategoryForm
							action={`${category ? `edit` : `add`}`}
							values={formvalues}
							category={category}
              subcategories = {subcategories}
						/>
					)}
				</Container>
			</Page>
		)
  }
}

export default withStyles(styles)(CategoryFormPage)

