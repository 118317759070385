export default function TagsStyles(theme) {
  return ({
    formControl: {
      minWidth: 240,
      marginRight: theme.spacing(2)
    },
    formControlContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    select: {
      "&:after": {
        border: "0"
      },
    },
    menuItemGroupTitle: {
      fontWeight: 600,
      textAlign: 'center',
      padding: theme.spacing(1, 0)
    }
  })
}