import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { Form, Autocomplete } from '../../blocks'
import {
	Typography,
	makeStyles,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogContentText,
} from '@material-ui/core'
import { commonFormStyles } from '../../styles'

const useStyles = makeStyles((theme) => ({
	SectionFormLayout: {
		display: 'flex',
		alignItems: 'center',
	},
	sectionFromText : {
		marginRight: '10px'
	},
	...commonFormStyles(theme),
}))

//Simple dialog form to get a name of a new thing
export default function SectionSelect({
	onClose,
	dialogState,
	formState,
	onSubmit,
	onCancel,
	sectionList,
	initialValues,
}) {
	const classes = useStyles()

	return (
		<Dialog onClose={onClose} open={dialogState}>
			<DialogTitle>Add Sections to Edition</DialogTitle>
			<DialogContent>
				<DialogContentText>Add all the sections you need for this edition in the order you need them to be.</DialogContentText>
				<Formik initialValues={initialValues} onSubmit={onSubmit}>
					<Form
						classes={classes}
						submitButtonText={'Add Sections'}
						performingAction={formState}
						onCancel={onCancel}>
						<FormikForm>
							<div className={classes.formField} style={{ width: '500px' }}>
								<div className={classes.SectionFormLayout}>
									<Typography className={classes.sectionFromText}>
										Sections
									</Typography>
								</div>
								<Autocomplete
									name="sections"
									items={sectionList}
									variant="outlined"
								/>
							</div>
						</FormikForm>
					</Form>
				</Formik>				
			</DialogContent>
		</Dialog>
	)
}
