function EditionStyles(theme) {
  return {
		linkWrapper: { textDecoration: 'none' },
		badge: {
			marginLeft: '10px',
			fontWeight: 'bold',
		},
		breadcrumbsContainer: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		pageWrapper: {
			width: '80%',
			display: 'flex',
			flexDirection: 'column-reverse',
			margin: '1% auto',
			[theme.breakpoints.down('md')]: {
				width: '100%',
			},
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
		cardInfoBox: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			margin: theme.spacing(1, 0, 0, 0),
		},
		subcategory: {
			textTransform: 'uppercase',
		},
		cardInfoText: {
			fontSize: '1rem',
			padding: theme.spacing(1, 0, 1, 0),
			'& p': {
				fontFamily: 'Assistant',
				margin: 0,
				color: theme.palette.grey[800],
			},
		},
		cardTitle: {
			fontWeight: 600,
			marginTop: theme.spacing(1),
			fontSize: '1.6rem',
			lineHeight: '1.8rem',
			[theme.breakpoints.up('sm')]: {
				fontSize: '1.8rem',
				lineHeight: '2rem',
			},
		},
		cardBody: {
			marginTop: theme.spacing(1),
		},
		content: {
			fontFamily: 'Assistant',
			color: theme.palette.grey[800],
			lineHeight: '1.6rem',
			fontSize: '1rem',
			[theme.breakpoints.up('sm')]: {
				fontSize: '1.1rem',
			},
		},
		listStyles: {
			marginBottom: theme.spacing(1),
		},
		image: {
			width: '100%',
			height: '100%',
		},
		video: {
			width: '100%',
			aspectRatio: "1.777",
			[theme.breakpoints.up('sm')]: {

			},
		},
		contextMenuContainer: {
			// padding: theme.spacing(1),
			display: 'flex',
			justifyContent: 'space-around',
			marginBottom: theme.spacing(1),
		},
		contextMenuButton: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			fontSize: '0.8rem',
			textTransform: 'capitalize',
		},
		dialogHeight: {
			maxHeight: '90vh',
		},
		formButton: {
			marginRight: theme.spacing(2),
		},
		alert: {
			width: '80%',
			margin: '3% auto',
			fontWeight: '600',
			fontSize: '1rem',
			borderColor: theme.palette.secondary.main,
			'& p': {
				margin: 0,
			},
		},
	}
}

export default EditionStyles