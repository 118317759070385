import React, { Component } from "react"
import * as Sentry from '@sentry/react' // for error logging
import {
	Container,
	TextField,
	CircularProgress,
	Button,
	Grid,
	Typography,
	Dialog,
	DialogContent,
	DialogActions,
	InputAdornment,
	IconButton
} from "@material-ui/core"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from "@material-ui/core/styles"
import firebase from "firebase/app"

import logo from "../../../images/logos/frontier-logo-with-padding.png"
import { LoginStyles } from "../../styles"

const styles = (theme) => (LoginStyles(theme))

class Login extends Component {
	state = {
		email: "",
		password: "",

		open: false, // Email link form dialog open/closed
		loading: false, // Variable to decide whether to show a loading animation or not.
		showPassword: false,
		viewToggle: false,
		passwordCursorPosition: 0
	}

	passwordRef = React.createRef()

	componentDidMount() {
		// Segment page load tracking code
		window.analytics.page("Login", {
			title: "Login | Frontier Research"
		})
	}

	handleInputChange = (event) => {
		const { target } = event
		const value = target.type === "checkbox" ? target.checked : target.value
		const { name } = target

		this.setState({
			[name]: value,
		})

		if (name === "password") {
			this.setState({
				passwordCursorPosition: target.selectionStart
			})
		}
	}

	handleEmailPasswordSubmit = (event) => {
		event.preventDefault()

		const { email, password } = this.state
		const { signIn } = this.props

		// Track that the user actually submitted the email + password
		window.analytics.track('User Attempted Login with Password', { email })

		signIn(email, password)
	}

	handleChooseEmailSignIn = event => {
		this.setState({ open: true })

		window.analytics.track('User Clicked Email-Link Signin Button')
	}

	handleCloseEmailSignIn = event => {
		this.setState({ open: false })
	}

	handleEmailSubmit = (event) => {
		event.preventDefault()

		this.setState({ loading: true }) // Show a loading animation

		const { email } = this.state
		const { openSnackbar } = this.props

		const url = window.location.href // Taking the url from the window object allows us to dynamically get whatever url we need.
		const title = 'Login | Frontier Research'
		const name = 'Login'

		// Track that the user actually submitted the email
		window.analytics.track('User Requested Signin Link', { email, name, title, url })

		// Checks if the user already has a Firebase account
		firebase.auth().fetchSignInMethodsForEmail(email)
			.then(providers => {
				if (providers.length > 0) {
					// Proceed to send sign-in link
					const actionCodeSettings = {
						// URL you want to redirect back to
						url,
						handleCodeInApp: true,
					}

					firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
						.then(() => {
							openSnackbar('Link sent to your email! Please check your inbox.')
							window.localStorage.setItem('emailForSignIn', email)
							window.analytics.track(`Sign-in link sent`, { email, name, title, url })
						})
						.catch(reason => {
							Sentry.captureException(reason)
							const { code, message } = reason

							switch (code) {
								default:
									openSnackbar(message)
									break
							}
						})
					this.setState({ open: false, loading: false })
				} else {
					// Throw error if email invalid
					openSnackbar('Invalid email. No user exists under the email you provided.')
					this.setState({ open: false, loading: false })
				}
			})
			.catch(reason => {
				Sentry.captureException(reason)
				const { message } = reason

				openSnackbar(message)

				this.setState({ open: false, loading: false })
			})
	}

	handleClickShowPassword = () => {
		const { showPassword } = this.state
		this.setState({
			showPassword: !showPassword,
			viewToggle: true
		})
	}

	setPasswordCursor = () => {
		const { passwordCursorPosition, viewToggle } = this.state

		if (viewToggle) {
			this.passwordRef.current.querySelector('input').setSelectionRange(passwordCursorPosition, passwordCursorPosition)
			this.setState({
				viewToggle: false
			})
		}
	}

	handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	render() {
		const { email, password, open, loading, showPassword } = this.state
		const {
			isPerformingAuthAction,
			openResetPasswordDialog,
			classes,
		} = this.props

		return (
			<Container className={classes.loginPage}>
				<Grid
					container
					item
					className={classes.loginFormContainer}
					direction="column"
					sm={7}
					xs={12}
					justifyContent="center"
					alignItems="center">
					<img src={logo} alt="Frontier Logo" className={classes.companyLogo} />
					<form onSubmit={this.handleEmailPasswordSubmit}>
						<Grid
							item
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							className={classes.block}>
							<Typography className={classes.text}>
								If you are a first time user, you'll find login instructions in
								the email that directed you here.
								<br />
								Make sure to use the same email address that you receive our communications on, as other emails will not have access.
							</Typography>

							<Typography className={classes.text}>
								If you have trouble logging in, please drop a quick email to{' '}
								<a href="mailto:clientconnect@frontiergroup.info">
									<strong>clientconnect@frontiergroup.info</strong>
								</a>{' '}
								or contact <strong>0114373477</strong>
							</Typography>
							<TextField
								required
								autoFocus
								type="email"
								name="email"
								onChange={this.handleInputChange}
								value={email}
								label="Email"
								margin="dense"
								variant="outlined"
								className={classes.textField}
							/>

							<TextField
								required
								type={showPassword ? 'text' : 'password'}
								name="password"
								onChange={this.handleInputChange}
								onSelect={this.setPasswordCursor}
								ref={this.passwordRef}
								value={password}
								label="Password"
								margin="dense"
								variant="outlined"
								className={classes.textField}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={this.handleClickShowPassword}
												onMouseDown={this.handleMouseDownPassword}
												edge="end">
												{showPassword ? (
													<Visibility className={classes.iconButton} />
												) : (
													<VisibilityOff className={classes.iconButton} />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							<Typography
								className={classes.forgotPassword}
								onClick={() => openResetPasswordDialog()}>
								Forgot Password?
							</Typography>

							{isPerformingAuthAction ? (
								<CircularProgress className={classes.submitButtonSection} />
							) : (
								<Button
									className={classes.submitButtonSection}
									type="submit"
									variant="contained"
									color="primary"
									size="large"
									disabled={!(email.length > 2 && password.length > 2)}
									onClick={this.handleEmailPasswordSubmit}>
									Login
								</Button>
							)}
						</Grid>
					</form>

					<Grid
						item
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						className={`${classes.block} ${classes.signinLinkBlock}`}>
						<Typography className={classes.text}>
							For <strong>password-free</strong> sign in, click the button below
							to get a link you can use to access Athena.
						</Typography>
						<Typography className={classes.text}>
							<em>(<strong> In case you dont recieve this email with the link, </strong> 
							please do check your spam folder as well as it may get directed there.
							If any further issue please call us on the above given contact details. )</em>
						</Typography>
						{isPerformingAuthAction ? (
							<CircularProgress className={classes.submitButtonSection} />
						) : (
							<Button
								className={classes.submitButtonSection}
								type="submit"
								variant="contained"
								color="primary"
								size="large"
								onClick={this.handleChooseEmailSignIn}>
								Get Link
							</Button>
						)}
					</Grid>

					<Dialog
						open={open}
						onClose={this.handleCloseEmailSignIn}
						aria-labelledby="form-dialog-title">
						<DialogContent>
							<Typography>
								Please use the same email address that you receive our
								communications on, as other emails will not have access.
							</Typography>
							<TextField
								required
								autoFocus
								fullWidth
								type="email"
								name="email"
								onChange={this.handleInputChange}
								value={email}
								label="Email"
								margin="normal"
								variant="outlined"
							/>
						</DialogContent>
						<DialogActions className={classes.submitButtonSection}>
							<Button onClick={this.handleCloseEmailSignIn}>Cancel</Button>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={!email.length > 2}
								onClick={this.handleEmailSubmit}>
								{loading ? <CircularProgress color="inherit" /> : 'Submit'}
							</Button>
						</DialogActions>
					</Dialog>
				</Grid>
			</Container>
		)
	}
}

export default withStyles(styles)(Login)
