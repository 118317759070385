function LoginStyles(theme) {
	return {
		loginPage: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'absolute',
			top: 0,
			left: 0,
			color: '#000000',
			maxWidth: '100vw',
			minHeight: '100vh',
		},
		loginFormContainer: {
			boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
			borderRadius: '10px',
			textAlign: 'center',
			padding: theme.spacing(1),
			maxWidth: '95%',
		},
		companyLogo: {
			width: '20%',
			height: '20%',
			marginBottom: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				width: '25%',
				height: '25%',
			},
		},
		forgotPassword: {
			color: 'grey',
			cursor: 'pointer',
			fontSize: '0.9rem',
		},
		submitButtonSection: {
			margin: theme.spacing(1),
		},
		block: {
			padding: theme.spacing(2),
		},
		signinLinkBlock: {
			width: '90%',
			marginBottom: theme.spacing(1),
			backgroundColor: '#eee',
		},
		textField: {
			width: '30ch',
			marginBottom: theme.spacing(1),
		},
		text: {
			marginBottom: theme.spacing(2),
			fontSize: '1rem',
		},
		iconButton: {
			fontSize: '1.2rem',
		},
	}
}

export default LoginStyles
