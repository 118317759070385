import React, { Component } from "react"
import * as Sentry from "@sentry/react" // for error logging
import { Container, withStyles, Box } from "@material-ui/core"

import {
  Page,
  ArticleCard,
  AdminOptions,
  ConfirmationDialog,
  LoadingBackdrop,
  Info,
} from "../../blocks"
import { makeBreadcrumbs, algoliaDataUpdate } from "../../helpers"
import { GraphqlRequest } from "../../services"
import { EditionPageQueries, DeleteQueries } from "../../strapiQueries"
import { EditionStyles, commonPageStyles } from "../../styles"
import editionLinks from "../../../redirectData/editions.json"

const styles = (theme) => ({
  ...EditionStyles(theme),
  ...commonPageStyles(theme),
})

class Edition extends Component {
  state = {
    editionData: null,
    longTail: null,
    archived: null,

    loading: false,
    mutationProgress: 0, // Progress of the graphCMS mutation query.

    openDialog: false,
    hasAccess: false,
  }

  strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

  getEdition = async () => {
    const {
      match: { params },
      openDialog,
      products,
      history,
      location,
    } = this.props

    const dataPath = location.pathname.replace(/\/$/, "")

    if (dataPath in editionLinks) {
      history.push(`/${editionLinks[dataPath]}`)
      return
    }

    try {
      if (isNaN(params.editionId)) {
        window.location.href = "/error404"
        return
      }
      const editionRes = await this.strapiGraphqlRequestClient.request(
        EditionPageQueries.getEdition,
        {
          editionId: params.editionId,
        }
      )
      // const res = await this.strapiGraphqlRequestClient.request(EditionPageQueries.getAllEditionsForAlgolia);
      // console.log(res.editions)

      const data = editionRes.editionsConnection.values

      if (!data.length) {
        window.location.href = "/error404"
        return
      }

      if (
        !data[0].subcategory.restricted_access ||
        products.includes(data[0].subcategory.id)
      ) {
        this.setState(
          {
            hasAccess: true,
            editionData: data[0],
            longTail: data[0].long_tail,
            archived: data[0].archived,
          },
          () => {
            window.analytics.page(this.state.editionData.title, {
              title: `${this.state.editionData.title} | Frontier Research`,
            })
          }
        )
      } else {
        this.setState(
          {
            hasAccess: false,
            editionData: data[0],
            longTail: data[0].long_tail,
            archived: data[0].archived,
          },
          () => {
            window.analytics.page(this.state.editionData.title, {
              title: `${this.state.editionData.title} | Frontier Research`,
            })
          }
        )
      }
    } catch (err) {
      Sentry.captureException(err)
      openDialog(err)
      console.log(err) // GraphQL response errors
    }
  }

  deleteEdition = async () => {
    const {
      editionData: {
        attachment,
        author_editions,
        contents,
        cover_image,
        edition_sections,
        id: editionId,
      },
    } = this.state

    this.setState({
      loading: true,
      openDialog: false,
      mutationProgress: 10,
    })

    const ids = {
      editionSections: [],
      contents: [],
      attachment: null,
      authorEditions: [],
      files: [],
    }

    try {
      if (attachment) ids.attachment = parseInt(attachment.id)

      author_editions.forEach((item) =>
        ids.authorEditions.push(parseInt(item.id))
      )
      contents.forEach((item) => ids.contents.push(parseInt(item.id)))
      edition_sections.forEach((item) =>
        ids.editionSections.push(parseInt(item.id))
      )

      if (cover_image) ids.files.push(parseInt(cover_image.id))

      if (attachment)
        ids.files.push(
          ...attachment.media.map((item) => parseInt(item.id))
        )

      for (const content of ids.contents) {
        await this.strapiGraphqlRequestClient.request(
          DeleteQueries.content,
          {
            id: content,
          }
        )
      }

      for (const editionSection of ids.editionSections) {
        await this.strapiGraphqlRequestClient.request(
          DeleteQueries.editionSection,
          {
            id: editionSection,
          }
        )
      }

      this.setState({ mutationProgress: 30 })

      for (const authorEdition of ids.authorEditions) {
        await this.strapiGraphqlRequestClient.request(
          DeleteQueries.authorEdition,
          {
            id: authorEdition,
          }
        )
      }

      this.setState({ mutationProgress: 40 })

      for (const file of ids.files) {
        await this.strapiGraphqlRequestClient.request(
          DeleteQueries.file,
          {
            id: file,
          }
        )
      }

      this.setState({ mutationProgress: 70 })

      await this.strapiGraphqlRequestClient.request(
        DeleteQueries.attachment,
        {
          id: ids.attachment,
        }
      )

      this.setState({ mutationProgress: 90 })

      await this.strapiGraphqlRequestClient.request(
        DeleteQueries.edition,
        {
          id: editionId,
        }
      )

      this.props.history.goBack()
    } catch (err) {
      console.log(err)
    }

    // delete algolia record
    algoliaDataUpdate({
      objectID: editionId,
      index: 'regularContent',
      operation: 'delete'
    })

    //TODO Add code here
    this.setState({ loading: false, openDialog: false })
  }

  componentDidMount() {
    this.getEdition()
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props

    if (params.editionId !== prevProps.match.params.editionId) {
      this.getEdition()
    }
  }

  handleDialogOpen = () => {
    this.setState({ openDialog: true })
  }
  handleDialogClose = () => {
    this.setState({ openDialog: false })
  }

  render() {
    const {
      editionData,
      longTail,
      archived,
      openDialog,
      loading,
      mutationProgress,
      hasAccess,
    } = this.state
    const {
      classes,
      match: { params },
      userRole,
      user,
      openSnackbar,
    } = this.props

    return (
      <Page
        className={classes.breadcrumbsContainer}
        title={`${editionData ? editionData.title : "Loading..."}`}
        breadcrumbs={makeBreadcrumbs(
          longTail,
          archived,
          editionData,
          params
        )}
      >
        <Container maxWidth="lg">
          {editionData && (
            <AdminOptions
              userRole={userRole}
              editLink={`/admin/edition/edit/${editionData ? editionData.id : null
                }`}
              deleteHandler={this.handleDialogOpen}
              disabled={editionData ? false : true}
              modelName="Edition"
            />
          )}
          {!editionData || hasAccess ? (
            <>
              <Box className={classes.pageWrapper}>
                <ArticleCard
                  cardData={editionData}
                  category={
                    editionData
                      ? editionData.subcategory.category
                        .name
                      : null
                  }
                  classes={classes}
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Box>
            </>
          ) : (
            <Info
              classes={classes}
              title="Unauthorized!"
              content={`
                  <p>If you are unable to view the page, this content might not be accessible to you, due to your current subscription status with Frontier. To subscribe, contact us at <a href="mailto:clientconnect@frontiergroup.info">clientconnect@frontiergroup.info</a>.</p>
                  <br />
                  <p>If you have already subscribed to this service and still not able to view - try refreshing in a couple of minutes as sometimes due to issues in network you may not be able to access this.
                  Contact us on <a href="tel:+94114373477">011 4373477</a> for any assistance required.</p>
              `}
            />
          )}
        </Container>

        <LoadingBackdrop
          loading={loading}
          mutationProgress={mutationProgress}
        />

        <ConfirmationDialog
          open={openDialog}
          handleClose={this.handleDialogClose}
          handleSubmit={this.deleteEdition}
          extraSecurity={true}
          alertSeverity={"warning"}
          title="Hold Up!"
          textContent={`Are you sure you want to delete "${editionData ? editionData.title : null
            }"`}
          alertContent={
            <>
              This will delete{" "}
              <strong>
                all associated content and attachments
              </strong>
              . This action is <strong>not reversible.</strong>
            </>
          }
          confirmButtonText="Yes, I'm sure"
          cancelButtonText="Cancel"
        />
      </Page>
    )
  }
}

export default withStyles(styles)(Edition)
