import React from 'react'
import {
	FormControl,
	Select,
	MenuItem,
	Input,
	Chip,
	FormHelperText,
} from '@material-ui/core'
import { useField } from 'formik'

export default function SelectField ({ classes, name, multiple,items,changeHandler, disabled , ...otherProps}) {
	const [field, meta] = useField(name)

	let handleChange = (e) => {
		field.onChange(name)(e.target.value.toUpperCase())
	}

	if(changeHandler) {
		handleChange = (e) => {
			field.onChange(name)(e.target.value.toUpperCase())
			changeHandler(e.target.value)
		}
	}


	
	// additional props if multiple prop is provided
	const multiSelectProps = multiple === undefined
	? {}
	: {
		multiple,
		input: <Input />,
		renderValue: (selected) => (
		<div>
			{selected.map((value) => (
			<Chip key={value} label={value} />
			))}
		</div>
		)
	}

	const configField = {
		...field,
	...multiSelectProps,
		...otherProps,
	}


	if (meta && meta.touched && meta.error) {
		configField.error = true
		configField.helperText = meta.error
	}

	return (
			<FormControl error= {configField.error}>
				<Select {...configField} onChange={handleChange} disabled={disabled ? disabled : false}>
					{items.map((item, index) => (
						<MenuItem value={item.id} key={index}>
							{item.name}
						</MenuItem>
					))}
				</Select>
				{configField.error && <FormHelperText>{configField.helperText}</FormHelperText>}
			</FormControl>
	)
}
