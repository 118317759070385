function FeedCardStyles(theme) {
  return ({
    root: {
      width: "96%",
      height: 'auto',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up("md")]: {
        height: '250px',
      },
    },
    textContent: {
      width: "100%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      color: '#f2f2f2'
    },
    badge: {
      color: '#f2f2f2',
      borderColor: '#f2f2f2'
    },
    featuredImageContainer: {
      height: 'auto',
      [theme.breakpoints.up("md")]: {
        height: '150px',
      },
    },
    description: {
      display: 'none',
      [theme.breakpoints.up("md")]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100px',
        padding: theme.spacing(1, 2),
        overflowY: 'hidden',
      },
    }
  })
}

export default FeedCardStyles