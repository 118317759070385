import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core"

export default function ResetPasswordDialog ({ isPerformingAuthAction, open, onClose, resetPassword }) {
  const [email, setEmail] = useState('')

  const handleKeyPress = (event) => {
    const { key, altKey, ctrlKey, metaKey, shiftKey } = event

    if (altKey || ctrlKey || metaKey || shiftKey) {
      return
    }

    if (key === "Enter") {
      event.preventDefault()

      resetPassword()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExited={() => setEmail('')}
      onKeyPress={handleKeyPress}
    >
      <DialogTitle>Reset your password</DialogTitle>

      <DialogContent>
        <DialogContentText>
          You will receive an email containing instructions on how to reset
          your password.
        </DialogContentText>

        <form>
          <TextField
            required
            variant="outlined"
            autoComplete="email"
            type="email"
            name="emailAddress"
            fullWidth
            autoFocus
            helperText="This should be your registered email address"
            margin="normal"
            onChange={e => setEmail(e.target.value)}
            placeholder="Email Address"
            value={email}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button 
          variant="outlined"
          color="secondary" 
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!email || isPerformingAuthAction}
          onClick={() => resetPassword(email)}
        >
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  )
}
