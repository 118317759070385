import React, { useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  ClickAwayListener,
  Tooltip
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonStyles: {
    margin: theme.spacing(2)
  },
  tooltipStyles: {
    fontSize: '1rem',
    padding: '4px 10px'
  }
}))

const CustomDialog = ({ dialog, handleClose }) => {
  const classes = useStyles()
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = () => setOpenTooltip(false)

  const handleTooltipOpen = () => setOpenTooltip(true)

  const copyError = (e, error) => {
    e.persist() // to access e properties asynchronously

    if (!navigator.clipboard) {
      // use doc.execCommand() method which is now deprecated
      let tempTextArea = document.createElement('textarea')
      tempTextArea.innerHTML = error
      document.body.appendChild(tempTextArea)
      tempTextArea.select()
      let success = document.execCommand('copy')
      document.body.removeChild(tempTextArea)

      if (success) handleTooltipOpen()
      else alert(`Could not copy error message :(`)
    } else {
      navigator.clipboard.writeText(error)
        .then(() => handleTooltipOpen())
        .catch(() => alert(`Could not copy error message :(`));
    }
  }
  
  return (
    <Dialog
      open={dialog.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialog.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            open={openTooltip}
            placement="left"
            PopperProps={{
              disablePortal: true,
            }}
            leaveDelay={500}
            onClose={handleTooltipClose}
            title="Error copied!"
              classes={{ tooltip: classes.tooltipStyles }}
          >
            <Button
              onClick={e => copyError(e, dialog.errorObject)}
              color="primary"
              variant="contained"
              className={classes.buttonStyles}
            >
              Copy error message
            </Button>
          </Tooltip>
        </ClickAwayListener>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          className={classes.buttonStyles}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog