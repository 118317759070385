import React, { Component } from 'react'
import * as Sentry from '@sentry/react' // for error logging
import {
  withStyles,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  CircularProgress
} from "@material-ui/core"

import { GraphqlRequest } from "../../services"
import {
  Page,
  CardContainer,
  LoadMoreButton
} from "../../blocks"
import { CategoryQueries, ArchivesQueries } from '../../strapiQueries'
import { commonPageStyles, TagsStyles } from "../../styles"
import { filterUnauthorizedEditions } from '../../helpers'

const styles = (theme) => ({ ...commonPageStyles(theme), ...TagsStyles(theme) })

class Archives extends Component {
	state = {
		subcategoryData: [],
		feed: [],
		subcategoryId: 'all',
		loadingFilteredData: true,

		hasNextPage: true,
		endCursor: 0,
		loadingFeed: false,
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient


	getSubcategories = async () => {
		const { openDialog, products } = this.props

		try {
			const subcategoryDataRes = await this.strapiGraphqlRequestClient.request(
				CategoryQueries.archiveSubcategories,
				{
					subscribedList: products,
				}
			)

			const filteredData = subcategoryDataRes.categoriesConnection.values.map(
				(category) => ({
					...category,
					subcategories: category.subcategories.filter(
						(subcategory) => subcategory.editions.length > 0
					),
				})
			)

			this.setState(
				{
					subcategoryData: filteredData,
				},
				() => this.getinitialFeedData()
			)
		} catch (err) {
			Sentry.captureException(err)
			openDialog(err)
			console.log(err) // GraphQL response errors
		}
	}

	getinitialFeedData = async () => {
		const { openDialog, products } = this.props
		const { subcategoryId, endCursor } = this.state
    

		try {
      let feedDataRes

      if(subcategoryId === 'all') {
        feedDataRes = await this.strapiGraphqlRequestClient.request(
					ArchivesQueries.getFeed,
					{
						start: endCursor,
						limit: 10,
					}
				)
      }else {
        feedDataRes = await this.strapiGraphqlRequestClient.request(
					ArchivesQueries.getFeed,
					{
						subcategoryId,
						start: endCursor,
						limit: 10,
					}
				)

      }
			
			const feedEditions = filterUnauthorizedEditions(
				feedDataRes.editionsConnection.values,
				products
			)


			this.setState(
				{
					feed: feedEditions,
					hasNextPage: feedDataRes.editionsConnection.values.length > 9,
					endCursor: feedDataRes.editionsConnection.values.length,
					loadingFeed: false,
					loadingFilteredData: false,
				},
				() => {
					const subTitle =
						subcategoryId === 'all'
							? `All`
							: `${this.state.feed[0].subcategory.name}`

					// Segment page load tracking code
					window.analytics.page(`Archives - ${subTitle}`, {
						title: `Archives - ${subTitle} | Frontier Research`,
					})
				}
			)
		} catch (error) {
      console.log("archives Page | loading Feed" , error)
			openDialog(error)
    }


	}

	handleLoadMore = async  () => {
		const { openDialog, products } = this.props
		const { endCursor, feed, subcategoryId } = this.state

		this.setState({ loadingFeed: true })

    
		try {
			const feedDataRes = await this.strapiGraphqlRequestClient.request(
				ArchivesQueries.getFeed,
				{
					start: endCursor,
					limit: 10,
					subcategoryId: subcategoryId === 'all' ? undefined : subcategoryId,
				}
			)

			const count = feedDataRes.editionsConnection.values.length

			const newFeed = filterUnauthorizedEditions(
				feedDataRes.editionsConnection.values,
				products
			)


			this.setState({
				feed: [...feed, ...newFeed],
				hasNextPage: count === 10,
				endCursor: endCursor + count ,
				loadingFeed: false,
				loadingFilteredData: false,
			})
		} catch (error) {
			openDialog(error)
			console.log('archives Page | loading more', error)
		}
	}

	handleChange = (event) => {
		this.setState({
			subcategoryId: event.target.value,
      endCursor : 0
		})
	}

	componentDidMount() {
		this.getSubcategories()
	}

	componentDidUpdate(prevProps, prevState) {
		const { subcategoryId } = this.state

		if (subcategoryId !== prevState.subcategoryId) {
      
      this.setState({ loadingFilteredData: true } , () => {
        	this.getinitialFeedData()
      })

		}
	}

	render() {
		const {
			feed,
			loadingFilteredData,
			hasNextPage,
			loadingFeed,
			subcategoryData,
			subcategoryId,
		} = this.state
		const { classes } = this.props


		const breadcrumbs = [
			{
				content: 'Archives',
				href: null,
			},
		]

		return (
			<Page
				className={classes.breadcrumbsContainer}
				title="Archives"
				breadcrumbs={breadcrumbs}>
				<Container className={classes.breadcrumbsContainer} maxWidth="lg">
					<div className={classes.formControlContainer}>
						<FormControl variant="filled" className={classes.formControl}>
							<InputLabel>Filter by subcategory</InputLabel>
							<Select
								value={subcategoryId}
								onChange={this.handleChange}
								className={classes.select}>
								<MenuItem aria-label={'all'} key={'all'} value={'all'}>
									All
								</MenuItem>

								{subcategoryData &&
									subcategoryData.map((category) => {
										return [
											<Divider />,

											<Typography className={classes.menuItemGroupTitle}>
												{category.name}
											</Typography>,

											<Divider />,

											category.subcategories.map((subcategory) => (
												<MenuItem
													aria-label={subcategory.name}
													key={subcategory.id}
													value={subcategory.id}>
													{subcategory.name}
												</MenuItem>
											)),
										]
									})}
							</Select>
						</FormControl>
						{loadingFilteredData ? (
							<CircularProgress color="primary" size={20} thickness={3} />
						) : null}
					</div>
				</Container>

				<Container className={classes.contentContainer} maxWidth="lg">
					<CardContainer classes={classes} cardList={feed} />
					<LoadMoreButton
						classes={classes}
						hasNextPage={hasNextPage}
						handleLoadMore={this.handleLoadMore}
						loadingFeed={loadingFeed}
						archives
					/>
				</Container>
			</Page>
		)
	}
}

export default withStyles(styles)(Archives)