const SubCategoryQueries = {
	getAllPublished: `
		query{
			subcategories{
				id
				name
			}
		}
	`,
	getAll: `
		query getSubcategoryAll($sort:String) {
			subcategories(publicationState: PREVIEW, sort: $sort ) {
				id
				name
				published_at
				category{
          id
					name
        }
			}
		}
	`,
	getList: `
		query getSubcategoryAll($sort:String) {
			subcategories(publicationState: PREVIEW, sort: $sort ) {
				id
				name
			}
		}
	`,
	add: `
		mutation addSubCategory(
			$name: String!
			$description: String
			$CC_description: String
			$restricted: Boolean
			$Category_id: ID!
			$published_at: DateTime
		) {
			createSubcategory(
				input: {
					data: {
						name: $name
						description: $description
						CC_description: $CC_description
						category: $Category_id
						restricted_access : $restricted
						published_at: $published_at
					}
				}
			) {
				subcategory {
					id
					name
					description
					CC_description
					category {
						id
						name
					}
				}
			}
		}
	`,
	update: `
		mutation updateSubcategory(
			$subcategory_id: ID!
			$name: String!
			$description: String
			$CC_description: String
			$restricted: Boolean
			$Category_id: ID!
			$published_at: DateTime
		) {
			updateSubcategory(
				input: {
					where : { id : $subcategory_id },
					data: {
						name: $name
						description: $description
						CC_description: $CC_description
						category: $Category_id
						restricted_access : $restricted
						published_at: $published_at
					}
				}
			) {
				subcategory {
					id
					name
					description
					CC_description
					category {
						id
						name
					}
				}
			}
		}
	`,
	findSubcategoryById: `
		query getSubategory($subcategory_id: ID!) {
			subcategory(id: $subcategory_id, publicationState : PREVIEW) {
				name
				id
				description
				CC_description
				subcategory_sections(sort : "default_position:ASC"){
					id
					default_position
					section{
            id
            name
          }
				}
				published_at
				category {
						id
				}
				restricted_access
				hermes_product_ids{
					id	
					product_id
				}	
			}
		}
	`,
	findSubCategoryByName: `
		query getSubCategory($name: String!) {
			subcategories(where: { name: $name }) {
				id
				name
			}
		}
	`,
	delete: `
		mutation deleteSubcategory(
			$id: ID!,
		){
			deleteSubcategory( input : { where : { id : $id } }){
				subcategory{
					id
					name
				}
			}
		}
	`,
	getSubscribedItems: `
	query getSubscribedItems(
		$listofProducts : [ID]!
	){
			subcategories (where : { restricted_access : true , hermes_product_ids : { product_id_in : $listofProducts } }){
				id
				name
			}
		}
	`,
	getOpenItems: `
	query{
			subcategories( where : { restricted_access : false }){
				id
				name
			}
		}
	`,
}

export default SubCategoryQueries