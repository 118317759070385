import axios from 'axios'

async function getHermesData(url, user) {
  const token = await user.getIdToken()

  const headers = {
    'x-auth-token': token,
    'Content-Type': 'application/json'
  }

  const { data } = await axios.get(url, {
    headers
  })

  return data
}

export default getHermesData