import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect} from 'react'
import { commonPageStyles, AdminStyles } from '../../styles'
import { Container, Tabs, Tab, Paper } from '@material-ui/core'
import { PermissibleRender } from '../../utilities'
import { Page, Info } from '../../blocks'
import CategoryPanel from './CategoryPanel'
import SubcategoryPanel from './SubcategoryPanel'
import EditionPanel from './EditionPanel'

import { useLocation , useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => {
  return { ...commonPageStyles(theme), ...AdminStyles(theme) }	
})


function Admin({ userRole, openDialog , openSnackbar}) {
	const [activeTab, setactiveTab] = useState('CATEGORY')

	const location = useLocation()
	const history = useHistory()
	
	useEffect(() => {
		switch (location.pathname) {
			case '/admin/category':
				setactiveTab('CATEGORY')
				break
			case '/admin/subcategory':
				setactiveTab('SUBCATEGORY')
				break
			case '/admin/edition':
				setactiveTab('EDITION')
				break
			default:
				setactiveTab('CATEGORY')
				break
		}
	}, [location.pathname])

	


	const classes = useStyles()
	const tabs = ['CATEGORY', 'SUBCATEGORY', 'EDITION']
	const tabLinks = ['category', 'subcategory', 'edition']

	const handleTabChange = (event, newValue) => {
		history.push(`/admin/${tabLinks[newValue]}`)
	}

	const breadcrumbs = [
		{
			content: `Admin`,
			href: null,
		},
	]

	return (
		<Page
			className={classes.breadcrumbsContainer}
			title="Admin Interface"
			breadcrumbs={breadcrumbs}>
			<Container className={classes.container}>
				{
					// PermissibleRender in case a non-priviledged user navigates to this route
				}
				<PermissibleRender
					userPermission={userRole}
					requiredPermissions={['dev', 'editor', 'member']}
					renderOtherwise={
						<Info
							classes={classes}
							content="You don't have permission to view this page !"
						/>
					}>
					<Paper>
						<Tabs
							orientation="vertical"
							value={tabs.indexOf(activeTab)}
							onChange={handleTabChange}
							indicatorColor="secondary"
							centered>
							{tabs.map((key, index) => (
								<Tab
									label={key}
									className={classes.tab}
									id={index}
									key={index}
								/>
							))}
						</Tabs>
					</Paper>
					{
						<div style={{ width: '80%' }}>
							{activeTab === 'CATEGORY' && (
								<CategoryPanel
									userRole={userRole}
									classes={classes}
									openDialog={openDialog}
									openSnackbar={openSnackbar}
								/>
							)}
							{activeTab === 'SUBCATEGORY' && (
								<SubcategoryPanel
									userRole={userRole}
									classes={classes}
									openDialog={openDialog}
									openSnackbar={openSnackbar}
								/>
							)}
							{activeTab === 'EDITION' && (
								<EditionPanel
									userRole={userRole}
									classes={classes}
									openDialog={openDialog}
									openSnackbar={openSnackbar}
								/>
							)}
						</div>
					}
				</PermissibleRender>
			</Container>
		</Page>
	)
}

export default Admin
