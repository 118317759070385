import React from 'react';
import {
  Container,
  TextField,
  InputLabel,
} from '@material-ui/core'

export default function SingleLineText({ name, value, label, changeHandler, placeholder, className }) {
  return (
    <Container className={className}>
      <InputLabel>{label}</InputLabel>
      <TextField fullWidth name={name} value={value} onChange={changeHandler} placeholder={placeholder} />
    </Container>
  )
}