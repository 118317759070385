import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  ButtonGroup,
  Tooltip,
  Button,
  withStyles
} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { PermissibleRender } from "../../utilities"

// Load styles
const styles = (theme) => ({
  adminOptions: {
    marginBottom: theme.spacing(2),
  }
})

const AdminOptions = ({ userRole, editLink, deleteHandler, modelName, disabled, classes }) => (
  <PermissibleRender
    userPermission={userRole}
    requiredPermissions={["dev", "editor", "member"]}
  >
    <ButtonGroup variant="text" aria-label="text primary button group" className={classes.adminOptions}>
      <Button color="primary" component={RouterLink} to={editLink} disabled={disabled}>
        <Tooltip title={`Edit this ${modelName}`}>
          <EditIcon />
        </Tooltip>
      </Button>

      <PermissibleRender
        userPermission={userRole}
        requiredPermissions={["dev", "editor"]}
      >
      <Button color="secondary" disabled={disabled} onClick={() => deleteHandler()}>
        <Tooltip title={`Delete this ${modelName}`}>
          <DeleteIcon />
        </Tooltip>
      </Button>
      </PermissibleRender>
    </ButtonGroup>
  </PermissibleRender>
)

export default withStyles(styles)(AdminOptions)