export default function filterUnauthorizedEditions (editions, susbcribedSubcategories) {

  const filteredEditions = []

  editions.forEach((edition) => {
		if (!edition.subcategory.restricted_access) {
			filteredEditions.push(edition)
		} else if (susbcribedSubcategories.includes(edition.subcategory.id)) {
			filteredEditions.push(edition)
		}
	})

  return filteredEditions

}