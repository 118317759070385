const SubcategoryPageQueries = {
	getSubcategoryData: `
    query subcategoryData(
    $subcategoryId : ID!
  ){
    subcategory ( id : $subcategoryId) {
      id
      name
      category {
        id
        name
        description
      }
      restricted_access
    }
  }
  `,
	getFeatured: `
    query getFeatured(
      $subcategoryId : ID!
      $longTail : Boolean!
    ){
      editionsConnection(
        where : { long_tail : $longTail , featured : true ,archived : false, subcategory : { id : $subcategoryId } },
        sort : "date:DESC"   
      ){
        values : values{
          id
          title
          subtitle
          subcategory{
            id
            name
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          contents(limit : 1, sort :"created_at:ASC" ){
            id
            body
          }
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
	getFeed: `
    query getFeatured(
      $subcategoryId : ID!
      $longTail : Boolean!
      $start : Int!,
      $limit : Int!,
    ){
      editionsConnection(
        start : $start, limit : $limit,
        where : { archived : false , featured : false , subcategory : { id : $subcategoryId } , long_tail : $longTail },
        sort : "date:DESC"   
      ){
        values : values{
          id
          title
          subtitle
          subcategory{
            id
            name
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
}

export default SubcategoryPageQueries
