// We only need to return the src attribute of the embed code, as the rest is handled by Material UIs 
// CardMedia component.
function getVideoEmbedUrl(videoLink, cardMediaRef) {
  let width = 0

  if(cardMediaRef.current){
    width = cardMediaRef.current.offsetWidth
  }

  if (/youtube.com/.test(videoLink)) {
    let videoID = videoLink.split("v=")[1]
    const ampersandPosition = videoID.indexOf("&")
    if (ampersandPosition !== -1) {
      videoID = videoID.substring(0, ampersandPosition)
      return `https://www.youtube.com/embed/${videoID}`
    }
    return `https://www.youtube.com/embed/${videoID}`
  } else if (/vimeo.com/.test(videoLink)) {
    const splitVideoLink = videoLink.split("/")

    // The last part of the split array contains the Video ID
    let videoID = splitVideoLink[splitVideoLink.length - 1]

    return `https://player.vimeo.com/video/${videoID}`
  } else if (/facebook.com/.test(videoLink)) {
    let videoID // Variable to store video ID

    // Find the part of the URL that has the video ID - it should be just a string of numbers
    videoLink.split("/").map(urlPart => {
      if (/^\d+/.test(urlPart)) return videoID = urlPart
      return videoID
    })

    // Return the video Embed URL for facebook videos
    return `https://www.facebook.com/plugins/video.php?height=${Math.round(width*(1/1.777))}&href=https%3A%2F%2Fwww.facebook.com%2FFrontierResearch%2Fvideos%2F${videoID}%2F&show_text=0&width=${width}&t=0`
  }
}
export default getVideoEmbedUrl