/* eslint-disable eqeqeq */
import { GraphqlRequest } from '../../../services'
import { AuthorEditioQueries } from '../../../strapiQueries'

async function updateAuthorEditions(
	editionId,
	existingAuthorEditions,
	mainTeam,
	supportTeam
) {
	const existingSupportTeam = []
	const existingMainTeam = []

	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient


	existingAuthorEditions.forEach((item) => {
		if (item.main) {
			existingMainTeam.push({
				id: item.id,
				author: item.author.id,
			})
		} else {
			existingSupportTeam.push({
				id: item.id,
				author: item.author.id,
			})
		}
	})

	const updatedMainTeam = mainTeam.map((item) => item.id)
	const updatedSupportTeam = supportTeam.map((item) => item.id)

	const updatedAuthorEditions = []
	const AuthorEditionsToDelete = []

	for (const item of updatedMainTeam) {
		const filteredArr = existingMainTeam.filter(
			(record) => item == record.author
		)

		if (filteredArr.length) {
			updatedAuthorEditions.push(filteredArr[0].id)
		} else {
			const createdId = await createAuthorEdition(editionId, item, true)
			if (createdId) {
				updatedAuthorEditions.push(createdId)
			}
		}
	}

	for (const item of updatedSupportTeam) {
		const filteredArr = existingSupportTeam.filter(
			(record) => item == record.author
		)

		if (filteredArr.length) {
			updatedAuthorEditions.push(filteredArr[0].id)
		} else {
			const createdId = await createAuthorEdition(editionId, item, false)
			if (createdId) {
				updatedAuthorEditions.push(createdId)
			}
		}
	}

	existingAuthorEditions.forEach((item) => {
		if (!updatedAuthorEditions.includes(item.id)) {
			AuthorEditionsToDelete.push(item.id)
		}
	})

	for (const item of AuthorEditionsToDelete) {
		await strapiGraphqlRequestClient.request(
			AuthorEditioQueries.delete,
			{ id: item }
		)
	}
}

const createAuthorEdition = async (edition, author, main) => {

	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	const queryVars = {
		author,
		edition,
		main: main,
	}

	try {
		const createdRes = await strapiGraphqlRequestClient.request(
			AuthorEditioQueries.create,
			queryVars
		)
		return createdRes.createAuthorEdition.authorEdition.id
	} catch (err) {
		console.log(err)
		return null
	}
}

export default updateAuthorEditions
