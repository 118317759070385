function commonCardStyles(theme) {
  return ({
    linkWrapper: { 
      width: '100%',
      textDecoration: "none",
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        width: '50%',
      },
    },
    cardInfoBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'space-around',
      alignItems: "flex-start",
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(.25, 0)
      },
    },
    cardInfoTopRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: "center",
    },
    cardInfoText: {
      fontSize: "0.7rrem",
      lineHeight: '1.2em',
      [theme.breakpoints.up("sm")]: {
        fontSize: ".9rem",
      },
    },
    categoryText: {
      textTransform: "uppercase",
      fontWeight: 'bold',
    },
    badge: {
      fontWeight: 'bold',
      height: 'auto', // by default this is 24 and is too tall for mobile
    },
    cardTitle: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "1.2rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.4rem",
        lineHeight: "1.6rem",
      },
    },
    cardSubTitle: {
      display: 'none',
      [theme.breakpoints.up("sm")]: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        fontSize: "1.2rem",
      },
    },
  })
}

export default commonCardStyles