import React, {useState , useContext, useEffect } from 'react'
import { commonFormStyles } from '../../../styles'
import { Grid, Typography, Button, TextField } from '@material-ui/core'
// TODO - import DragHandleIcon from '@material-ui/icons/DragHandle'
import { makeStyles } from '@material-ui/core/styles'
import {
	AddCircle as AddCircleIcon,
	Delete as DeleteIcon,
} from '@material-ui/icons'
import {
	DraggableCard,
} from '../../../blocks'
import TextEditor from '../Components/TextEditor'
import SectionContext from '../SectionContext'


const useStyles = makeStyles((theme) => ({
	...commonFormStyles(theme),
}))



function SectionForm({ section, SectionPosition }) {
	const classes = useStyles()

	const { name } = section

	const { editionSections, setEditionSections } = useContext(SectionContext)

	const onDelete = () => {
		const newState = editionSections

		newState.splice(SectionPosition , 1 )
		setEditionSections([...newState])
	}

	const addContentBlock = () => {
		const newState = editionSections

		newState[SectionPosition]['content'].push({
			id: null,
			title: '',
			url: '',
			body: '',
		})
		setEditionSections([...newState])
	}

	return (
		<Grid item xs={12}>
			<div className={classes.subsectionsContainer}>
				<DraggableCard classes={classes} name={name} handleDelete={onDelete} draggable={false} />

				{
					// content under this section
				}
				{editionSections[SectionPosition]['content'].map((content, index) => (
					<ContentBlock
						classes={classes}
						SectionPosition={SectionPosition}
						position={index}
						key={index}
					/>
				))}
				<div className={classes.subsectionsHeader}>
					<Typography>Content</Typography>
					<Button onClick={addContentBlock}>
						<AddCircleIcon />
					</Button>
				</div>
			</div>
		</Grid>
	)
}

const ContentBlock = ({ classes, SectionPosition, position }) => {
	const { editionSections, setEditionSections, ttEdition } =
		useContext(SectionContext)
	const [body, setBody] = useState('')
	const [title, setTitle] = useState('')
	const [url, setUrl] = useState('')

	//Populate data from Edition from state
	useEffect(() => {
		setBody(editionSections[SectionPosition]['content'][position]['body'])
		setTitle(editionSections[SectionPosition]['content'][position]['title'])
		setUrl(editionSections[SectionPosition]['content'][position]['url'])
	}, [editionSections, SectionPosition, position])


	const handleTextEditorChange = (event, editor) => {

		const data = editor.getData()
		setBody(data)

		const newState = editionSections
		newState[SectionPosition]['content'][position]['body'] = data

		setEditionSections([...newState])
	}

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
		const newState = editionSections
		newState[SectionPosition]['content'][position]['title'] = e.target.value

		setEditionSections([...newState])
	}

	const handleUrlChange = (e) => {
		setUrl(e.target.value)
		const newState = editionSections
		newState[SectionPosition]['content'][position]['url'] = e.target.value

		setEditionSections([...newState])
	}

	const handleDelete = () => {
		const newState = editionSections

		newState[SectionPosition]['content'].splice(position, 1)
		setEditionSections([...newState])
	}

	return (
		<>
			<div
				className={`${classes.subsectionsContainer} ${classes.borderedContainer}`}>
				{/* ToDo : Draggable 

						<Button className={classes.contentAction}>
						<DragHandleIcon />
						</Button>
				*/}
				{ttEdition && (
					<>
						<div className={classes.formField}>
							<Typography>Title</Typography>
							<TextField
								name="title"
								value={title}
								onChange={handleTitleChange}
							/>
						</div>
						<div className={classes.formField}>
							<Typography>Url</Typography>
							<TextField name="url" value={url} onChange={handleUrlChange} />
						</div>
					</>
				)}

				<div
					style={{ width: '90%' , margin:'20px auto' }}>
					<TextEditor value={body} changeHandler={handleTextEditorChange} />
				</div>
				<Button className={classes.contentAction} onClick={handleDelete}>
					<DeleteIcon fontSize="small" color="primary" />
				</Button>
			</div>
		</>
	)
}


export default SectionForm
