import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import {
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link,
  Hidden,
} from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

export default function DrawerMenu ({ classes, handleDrawerClose, open, menuItems }) {

  const drawer = (classes, handleDrawerClose) => (
		<div>
			<div className={classes.toolbar}>
				<Hidden xsDown implementation="js">
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</Hidden>
			</div>

			<Divider />

			<List>
				<Link color="inherit" to="/" component={RouterLink}>
					<ListItem
						button
						className={classes.drawerListItem}
						onClick={handleDrawerClose}>
						<ListItemText primary={'Home'} />
					</ListItem>
				</Link>

				{menuItems.map((menuItem, index) => (
					<React.Fragment key={index}>
						<Link
							color="inherit"
							key={index}
							to={menuItem.link}
							component={RouterLink}>
							<ListItem
								button
								className={classes.drawerListItem}
								onClick={handleDrawerClose}>
								<ListItemText primary={menuItem.name} />
							</ListItem>
						</Link>
					</React.Fragment>
				))}
			</List>
		</div>
	)

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor='left'
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: `${open ? classes.drawerOpen : null} ${!open ? classes.drawerClose : null}`,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer(classes, handleDrawerClose)}
      </Drawer>
    </div>
  )
}