import React from 'react'
import { Switch } from '@material-ui/core'
import { useField } from 'formik'

export default function SwitchField({ name, ...otherProps }) {
	const [field, meta] = useField(name)

	const configField = {
		...field,
		...otherProps,
    checked: field.value
	}

	if (meta && meta.touched && meta.error) {
		configField.error = true
		configField.helperText = meta.error
	}

	return <Switch {...configField} />
}
