export default function commonFormStyles(theme) {
  return ({
    root: {
      margin: theme.spacing(5, 0)
    },
    buttonGroup: {
      margin: theme.spacing(4, 0),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    button: {
      margin: theme.spacing(3, 0, 0, 4),
      minWidth: '100px',
    },
    iconButton: {
      margin: theme.spacing(3, 0, 0, 2),
    },
    formField: {
      display: 'grid',
      gridTemplateColumns: '30% 70%',
      margin: theme.spacing(2),
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    checkBoxGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(3),
    },
    subsectionsHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: theme.spacing(1)
    },
    subsectionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignContent: 'flex-start',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      border: "1px solid #ccc"
    },
    subcategoryCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#CCCCCC',
      margin: theme.spacing(1, 0),
      width: '75%',
      fontFamily: ['Assistant', 'sans-serif'].join(', '),
      fontSize: '0.9rem',
      fontWeight: 600
    },
    borderedContainer: {
      border: '1px solid #CCC',
      marginBottom: theme.spacing(3) // added since the details grid and sections grid overlaps with each other
    },
    contentAction: {
      alignSelf: 'flex-end'
    }
  })
}