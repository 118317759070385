import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import moment from "moment"
import pluralize from "pluralize"
import _ from "lodash"

import { makeAuthorString, makeSubstring } from '../../helpers'
import { commonCardStyles, FeaturedCardStyles } from "../../styles"

const useStyles = makeStyles(theme => ({ ...commonCardStyles(theme), ...FeaturedCardStyles(theme) }))

export default function FeaturedCard ({ cardData, category }) {
  const classes = useStyles()

  const imageFillStyle = {
		background: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${
			cardData.cover_image ? cardData.cover_image.url : null
		})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	}

  return (
		<>
			{!cardData && <Skeleton variant="rect" width="100%" />}

			<Link
				className={classes.linkWrapper}
				to={`/${category.id}/${cardData.subcategory.id}/${cardData.id}`}>
				<Card className={classes.root} variant="elevation">
					<CardContent
						className={`${classes.textContent} ${classes.featuredImageContainer}`}
						style={imageFillStyle}>
						<Box className={classes.cardInfoBox}>
							<div className={classes.cardInfoTopRow}>
								<Chip
									label={_.upperCase(cardData.subcategory.name)}
									className={`${classes.cardInfoText} ${classes.badge}`}
									size="small"
									variant="outlined"
								/>
								<Typography
									className={`${classes.cardInfoText} ${classes.categoryText}`}>
									{pluralize.singular(category.name)}
								</Typography>
							</div>
							<Typography
								className={classes.cardInfoText}
								style={{ marginLeft: 'auto' }}>
									{ // display new date for "Here's where to find"
										cardData.id === "266" 
											? moment(new Date()).format('Do MMM YYYY')
											: moment(
													cardData.date ? cardData.date : cardData.published_at
												).format('Do MMM YYYY')
									}
							</Typography>
						</Box>
						<Typography
							component="h2"
							className={classes.cardTitle}
							gutterBottom>
							{cardData.title}
						</Typography>
						<Typography className={classes.cardSubTitle} gutterBottom>
							{cardData.subtitle}
						</Typography>
						<Box className={classes.cardInfoBox}>
							<Typography className={classes.cardInfoText}>
								{makeAuthorString(
									cardData.author_editions
										.filter((item) => item.main)
										.map((item) => item.author),
									cardData.author_editions
										.filter((item) => !item.main)
										.map((item) => item.author),
									cardData.subcategory
								)}
							</Typography>
						</Box>
					</CardContent>
					<Box className={classes.description}>
						<Typography component="p">
							{makeSubstring(cardData.contents[0] && cardData.contents[0].body, 150)}
						</Typography>
					</Box>
				</Card>
			</Link>
		</>
	)
}
