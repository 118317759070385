const AttachmentQueries = {
	create: `
		mutation addattachment(
			$media: [ID]
			$edition: ID!
			$created_by: ID
			$updated_by: ID
		) {
			createAttachment(
				input: {
					data: {
						media: $media
						edition: $edition
						created_by: $created_by
						updated_by: $updated_by
					}
				}
			) {
				attachment {
					id
					media{
							id
							name
					}
				}
			}
		}
	`,
	update: `
		mutation updateAttachment(
			$id : ID!
			$media: [ID]
			$edition: ID
			$created_by: ID
			$updated_by: ID
		) {
			updateAttachment(
				input: {
					where : { id : $id },
					data: {
						media: $media
						edition: $edition
						created_by: $created_by
						updated_by: $updated_by
					}
				}
			) {
				attachment {
					id
					media{
							id
							name
					}
				}
			}
		}
	`,
	findAttachmentByEdition: `
		query getAttachment($edition: ID!) {
			attachments(where: { edition: $edition }) {
				id
				media {
					id
					name
				}
			}
		}
	`,
}

export default AttachmentQueries