import React, { Component } from "react"
import { Container, withStyles } from "@material-ui/core"
import {
    Page,
    CardContainer,
    ButtonContainer,
    ConfirmationDialog,
    LoadingBackdrop,
    LoadMoreButton,
    AdminOptions,
    LoadingSpinner,
    Info,
} from "../../blocks"
import { GraphqlRequest } from "../../services"
import { SubcategoryPageQueries } from "../../strapiQueries"
import { commonPageStyles } from "../../styles"
import subcategoryLinks from "../../../redirectData/subcategories.json"

const styles = (theme) => commonPageStyles(theme)

class Subcategory extends Component {
    state = {
        category: null,
        subcategory: null,
        featured: [],
        feed: [],
        hasAccess: false,
        loading: false,
        mutationProgress: 0,
        openDialog: false,
        loadingData: true,
        hasNextPage: true,
        endCursor: 0,
        loadingFeed: false,
    }

    strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

    getInitialData = async () => {
        const {
            openDialog,
            match: { params },
            products,
            history,
            location,
        } = this.props

        const dataPath = location.pathname.replace(/\/$/, "")

        if (dataPath in subcategoryLinks) {
            history.push(`/${subcategoryLinks[dataPath]}`)
            return
        }

        try {
            if (isNaN(params.subcategoryId)) {
                window.location.href = "/error404"
                return
            }

            const subcategoryDataRes =
                await this.strapiGraphqlRequestClient.request(
                    SubcategoryPageQueries.getSubcategoryData,
                    {
                        subcategoryId: params.subcategoryId,
                    }
                )

            if (!subcategoryDataRes.subcategory) {
                window.location.href = "/error404"
                return
            }

            this.setState(
                {
                    subcategory: subcategoryDataRes.subcategory,
                    category: subcategoryDataRes.subcategory.category,
                },
                () => {
                    const {
                        subcategory: { name },
                    } = subcategoryDataRes
                    window.analytics.page(`${name} Page`, {
                        title: `${name} | Frontier Research`,
                    })
                }
            )

            if (
                !subcategoryDataRes.subcategory.restricted_access ||
                products.includes(subcategoryDataRes.subcategory.id)
            ) {
                const featuredDataRes =
                    await this.strapiGraphqlRequestClient.request(
                        SubcategoryPageQueries.getFeatured,
                        {
                            subcategoryId: params.subcategoryId,
                            longTail: false,
                        }
                    )

                this.setState({
                    featured: featuredDataRes.editionsConnection.values,
                })

                const feedDataRes =
                    await this.strapiGraphqlRequestClient.request(
                        SubcategoryPageQueries.getFeed,
                        {
                            subcategoryId: params.subcategoryId,
                            longTail: false,
                            start: 0,
                            limit: 10,
                        }
                    )

                const count = feedDataRes.editionsConnection.values.length

                this.setState({
                    feed: feedDataRes.editionsConnection.values,
                    endCursor: this.state.endCursor + count,
                    hasNextPage: count > 9,
                    hasAccess: true,
                    loadingData: false,
                })
            } else {
                this.setState({
                    hasAccess: false,
                    loadingData: false,
                })

                return
            }
        } catch (error) {
            openDialog(error)
        }
    }

    unpublishSubCategory = async () => {
        // const { subcategory: { id: subCategoryId, category: { id: parentId } } } = this.state
        this.setState({
            loading: true,
            openDialog: false,
            mutationProgress: 10,
        })
        //TODO
        // Unpublish the SubCategory

        // Get the ids for sections and editions from the return value of the unpublished SubCategory

        // Unpublish Sections

        // Unpublish Editions

        // Get ids for the content under the unpublished editions and unpublish those contents

        // Move back to the main category
        this.setState({ loading: false, mutationProgress: 100 })
        // this.props.history.push(`/${parentId}`)
    }

    handledialogOpen = () => {
        this.setState({ openDialog: true })
    }

    handleDialogClose = () => {
        this.setState({ openDialog: false })
    }

    handleLoadMore = async () => {
        const {
            openDialog,
            match: { params },
        } = this.props
        const { endCursor, feed } = this.state

        this.setState({ loadingFeed: true })

        try {
            const feedDataRes = await this.strapiGraphqlRequestClient.request(
                SubcategoryPageQueries.getFeed,
                {
                    subcategoryId: params.subcategoryId,
                    longTail: false,
                    start: endCursor,
                    limit: 10,
                }
            )

            const count = feedDataRes.editionsConnection.values.length

            this.setState({
                feed: [...feed, ...feedDataRes.editionsConnection.values],
                endCursor: this.state.endCursor + count,
                hasNextPage: count > 9,
                loadingFeed: false,
            })
        } catch (error) {
            openDialog(error)
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    componentDidUpdate(prevProps) {
        const {
            match: { params },
        } = this.props

        if (params.subcategoryId !== prevProps.match.params.subcategoryId) {
            this.getInitialData()
        }
    }

    render() {
        const {
            subcategory,
            category,
            featured,
            feed,
            loading,
            mutationProgress,
            openDialog,
            hasNextPage,
            loadingFeed,
            loadingData,
            hasAccess,
        } = this.state

        const {
            classes,
            match: { params },
            userRole,
            products,
        } = this.props

        const breadcrumbs = [
            {
                content: category ? category.name : "Loading...",
                href: `/${params.categoryId}`,
            },
            {
                content: subcategory ? subcategory.name : "Loading...",
                href: null,
            },
        ]

        return (
            <Page
                className={classes.breadcrumbsContainer}
                title={`${subcategory ? subcategory.name : "Loading..."}`}
                breadcrumbs={breadcrumbs}
            >
                <Container
                    className={classes.breadcrumbsContainer}
                    maxWidth="lg"
                >
                    <AdminOptions
                        userRole={userRole}
                        editLink={`/admin/subcategory/edit/${
                            subcategory ? subcategory.id : null
                        }`}
                        deleteHandler={this.handledialogOpen}
                        disabled={subcategory ? false : true}
                        modelName="Sub-Category"
                    />
                </Container>

                <Container className={classes.contentContainer} maxWidth="lg">
                    {loadingData ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {hasAccess ? (
                                <>
                                    <CardContainer
                                        classes={classes}
                                        cardList={featured}
                                        cardType="featured"
                                        heading={
                                            category
                                                ? `Featured ${category.name}:`
                                                : null
                                        }
                                    />
                                    <ButtonContainer
                                        classes={classes}
                                        heading={
                                            category
                                                ? `Jump to other Subcategories under ${category.name}:`
                                                : null
                                        }
                                        category={category ? category : null}
                                        longTail={false}
                                        currentSubcategoryId={
                                            params.subcategoryId
                                        }
                                        products={products}
                                    />
                                    <CardContainer
                                        classes={classes}
                                        cardList={feed}
                                        cardType="feed"
                                        heading="Recent and Beyond:"
                                    />
                                    <LoadMoreButton
                                        classes={classes}
                                        hasNextPage={hasNextPage}
                                        handleLoadMore={this.handleLoadMore}
                                        loadingFeed={loadingFeed}
                                    />
                                </>
                            ) : (
                                <Info
                                    classes={classes}
                                    title="Unauthorized!"
                                    content={`
                                        <p>If you are unable to view the page, this content might not be accessible to you, due to your current subscription status with Frontier. To subscribe, contact us at <a href="mailto:clientconnect@frontiergroup.info">clientconnect@frontiergroup.info</a>.</p>
                                        <br />
                                        <p>If you have already subscribed to this service and still not able to view - try refreshing in a couple of minutes as sometimes due to issues in network you may not be able to access this.
                                        Contact us on <a href="tel:+94114373477">011 4373477</a> for any assistance required.</p>
                                    `}
                                />
                            )}
                        </>
                    )}
                </Container>

                <LoadingBackdrop
                    loading={loading}
                    mutationProgress={mutationProgress}
                />

                <ConfirmationDialog
                    open={openDialog}
                    handleClose={this.handleDialogClose}
                    handleSubmit={this.unpublishSubCategory}
                    alertSeverity={"warning"}
                    title="Hold Up!"
                    textContent={`Are you sure you want to un-publish "${
                        subcategory ? subcategory.name : null
                    }"`}
                    alertContent={
                        <>
                            This will also un-publish{" "}
                            <strong>
                                all associated editions, sections, content and
                                attachments
                            </strong>{" "}
                            under this Sub-Category.
                            <br />
                            <br />
                            This action <strong>is</strong> reversible. Contact
                            the Tech Team to have it reversed, if you need to.
                        </>
                    }
                    confirmButtonText="Yes, I'm sure"
                    cancelButtonText="Cancel"
                />
            </Page>
        )
    }
}

export default withStyles(styles)(Subcategory)
