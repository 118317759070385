import React from 'react'
import { useField } from 'formik'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

const AutocompleteField = ({
	classes,
	name,
	items,
	variant,
	...otherProps
}) => {
	const [field, meta, helpers] = useField(name)

	const config = {}

	if (meta && meta.touched && meta.error) {
		config.error = true
		config.helperText = meta.error
	}

	return (
		<Autocomplete
			multiple
			{...field}
			onChange={(_, value) => {
				helpers.setValue(value)
			}}
			filterSelectedOptions
			options={items}
			getOptionLabel={(option) => option.name}
			onBlur={() => helpers.setTouched(true)}
			renderInput={(props) => (
				<TextField
					{...props}
					variant={variant}
					helperText={config.helperText}
					error={config.error}
				/>
			)}
		/>
	)
}



export default AutocompleteField