import { NoEncryption } from "@material-ui/icons";

export default function SearchStyles(theme) {
  return ({
    searchBox: {
      width: '50%',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#CCCCCC',
      "&:hover": {
        backgroundColor: '#AAAAAA',
      },
      display: 'flex',
      alignItems: 'center',
			margin: '0 1em'
    },
    searchIcon: {
      padding: theme.spacing(1),
      pointerEvents: "none",
      display: "flex",
    },
    inputRoot: {
      width: '90%',
			fontSize: ".9rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      }
    },
    inputInput: {
      padding: '.8em',
      color: theme.palette.common.black,
      width: "100%",
    },
		alert: {
			width: '80%',
			margin: '3% auto',
			fontSize: '1.1rem',
			borderColor: theme.palette.secondary.main,
			lineHeight: '1.5rem',
			'& p': {
				margin: 0,
			},
		},
		alertTitle: {
			fontWeight: '600',
		},
    hits: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      [theme.breakpoints.up("md")]: {
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
      },
    },
    hit: {
      width: '100%',
      textDecoration: "none",
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        width: '49%',
      }
    },
    hitContent: {
      width: "100%",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    inputs: {
      display: 'flex',
      justifyContent: 'center',
			alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    switch: {
      display: 'flex',
      justifyContent: 'space-around',
			alignItems: 'center',
      fontFamily: 'Assistant',
			fontSize: ".9rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      }
    },
		card: {
			width: '100%'
		},
    info: {
      display: "flex",
			marginBottom: '1em'
    },
    date: {
      fontSize: "0.7rem",
      lineHeight: '1.2em',
			marginLeft: 'auto',
      [theme.breakpoints.up("sm")]: {
        fontSize: ".9rem",
      },
    },
    title: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "1.1rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.2rem",
        lineHeight: "1.15rem",
      },
    },
    newsContentTitle: {
			fontSize: "1rem",
      fontWeight: 'bold'
    },
		content: {
			fontSize: "1rem",
		}
  })
}