/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react'
import { CategoryQueries } from '../../strapiQueries'
import { EditionQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'
import { Link } from 'react-router-dom'
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Button,
	MenuItem,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Chip from '@material-ui/core/Chip'
import { Info, ConfirmationDialog } from '../../blocks'
import { PermissibleRender } from "../../utilities"
import { algoliaDataUpdate } from "../../helpers"

function EditionPanel({ userRole, classes, openDialog, openSnackbar }) {
	const [page, setPage] = useState(1)
	const [pageCount, setPageCount] = useState(0)
	const [loading, setLoading] = useState(true)
	const [editions, setEditions] = useState([])
	const [selectedCategory, setSelectedCategory] = useState('all')
	const [subcategorySelect, setSubcategorySelect] = useState('all')
	const [selectData, setSelectData] = useState([])
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
	const [focusItem, setFocusItem] = useState({})

	const rowsperPage = 6

	const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient


	//fetch categories and subcategories for filtering
	const fetchSelectData = async () => {
		const categoriesWithSubcategories = (
			await strapiGraphqlRequestClient.request(
				CategoryQueries.getAllWithSubcategories
			)
		).categories

		return categoriesWithSubcategories
	}

	//get edition count used for pagination accoring to the states of category and subcategory select
	const getEditionCount = async () => {
		const countQueryVars = {}

		if (selectedCategory !== 'all') {
			if (subcategorySelect !== 'all') {
				countQueryVars.where = {
					subcategory: { id: parseInt(subcategorySelect) },
				}
			} else {
				countQueryVars.where = {
					subcategory: { category: parseInt(selectedCategory) },
				}
			}
		}

		const fetchedEditionCount = (
			await strapiGraphqlRequestClient.request(
				EditionQueries.editionCount,
				countQueryVars
			)
		).editionsConnection.aggregate.count

		return fetchedEditionCount
	}

	//fetch editions accoring to selected fields and page
	const getEditions = async () => {
		const fetchEditionsVars = {
			sort: 'date:desc',
			start: (page - 1) * rowsperPage,
			limit: rowsperPage,
		}

		if (selectedCategory !== 'all') {
			if (subcategorySelect !== 'all') {
				fetchEditionsVars.subcategory = { id: parseInt(subcategorySelect) }
			} else {
				fetchEditionsVars.subcategory = { category: parseInt(selectedCategory) }
			}
		}

		const fetchedEditions = (
			await strapiGraphqlRequestClient.request(
				EditionQueries.getEditionsAdmin,
				fetchEditionsVars
			)
		).editions

		return fetchedEditions
	}

	//get subcategory list according to the selected category
	const getsubcategoryList = () => {
		if (selectedCategory === 'all') {
			return []
		}

		let subcategories

		for (const category of selectData) {
			if (category !== null && category.id === selectedCategory) {
				if (category.subcategories !== null) {
					subcategories = category.subcategories
				}
				break
			}
		}
		return subcategories
	}

	//used to populate initial data
	useEffect(() => {
		const populateData = async () => {
			const selectdata = await fetchSelectData()
			setSelectData(selectdata)

			const editionCount = await getEditionCount()
			setPageCount(Math.ceil(editionCount / rowsperPage))

			const editions = await getEditions()
			setEditions(editions)

			setLoading(false)
		}

		populateData()
	}, [])

	//when page changes
	useEffect(() => {
		const updatePage = async () => {
			setLoading(true)

			const editions = await getEditions()
			setEditions(editions)

			setLoading(false)
		}

		updatePage()
	}, [page])

	//when selecting filter is chosen
	useEffect(() => {
		const updateData = async () => {
			setLoading(true)

			const editionCount = await getEditionCount()
			setPageCount(Math.ceil(editionCount / rowsperPage))

			const editions = await getEditions()
			setEditions(editions)

			setLoading(false)
		}

		updateData()
	}, [selectedCategory, subcategorySelect])

	const handleCategorySelect = async (event) => {
		setSelectedCategory(event.target.value)
		setSubcategorySelect('all')
		setPage(1)
	}

	const handleSubcategorySelect = async (event) => {
		setSubcategorySelect(event.target.value)
		setPage(1)
	}

	const handlePageChange = async (event, value) => {
		setPage(value)
	}

	const handleDelete = (item) => {
		setFocusItem(item)
		setDeleteDialogOpen(true)
	}

	const handleDeleteSubmit = async () => {
		const queryVars = {
			id: focusItem.id,
		}
		try {
			await strapiGraphqlRequestClient.request(
				EditionQueries.delete,
				queryVars
			)
			openSnackbar(`Edition "${focusItem.title}" Deleted Succesfully.`)
		} catch (error) {
			openSnackbar(`Edition "${focusItem.title}" Deleting Failed.`)
			console.log('Edition Deletion Error', error)
		}

    // delete algolia record
    algoliaDataUpdate({
      objectID: focusItem.id,
      index: 'regularContent',
      operation: 'delete'
    })

		setDeleteDialogOpen(false)
		setLoading(true)

		const editions = await getEditions()
		setEditions(editions)

		setLoading(false)
	}

	return (
		<>
			<div className={classes.tableControls}>
				<div>
					<FormControl className={classes.select}>
						<InputLabel>Category</InputLabel>
						<Select
							value={selectedCategory}
							onChange={handleCategorySelect}
							label="Category"
							inputProps={{
								name: 'cataegory',
								id: 'category',
							}}>
							<MenuItem aria-label="All" value={'all'}>
								All
							</MenuItem>
							{selectData.map((category, index) => (
								<MenuItem value={category.id} key={index}>
									{category.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl disabled={selectedCategory === 'all' ? true : false}>
						<InputLabel>SubCategory</InputLabel>
						<Select
							value={subcategorySelect}
							onChange={handleSubcategorySelect}
							label="Subategory"
							inputProps={{
								name: 'subcataegory',
								id: 'subcategory',
							}}>
							<MenuItem aria-label="all" value={'all'}>
								All
							</MenuItem>
							{getsubcategoryList().map((subcategory, index) => (
								<MenuItem value={subcategory.id} key={index}>
									{subcategory.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className={classes.addItem}>
					<Typography>{`Add Edition`}</Typography>
					<Link to="/admin/edition/add">
						<Button className={classes.addItemButton}>
							<AddCircleIcon />
						</Button>
					</Link>
				</div>
			</div>
			{loading ? (
				<div className={classes.spinner}>
					<CircularProgress />
				</div>
			) : (
				<>
					<TableContainer className={classes.content}>
						<Table>
							<TableBody>
								<>
									{editions.length !== 0 ? (
										<>
											{editions.map((item) => (
												<TableRow key={item.id}>
													<TableCell component="th" scope="row">
														<Link
															to={`/${item.subcategory.category.id}/${item.subcategory.id}/${item.id}`}
															style={{
																textDecoration: 'none',
																color: 'inherit',
															}}>
															{item.name || item.title}
														</Link>
													</TableCell>
													<TableCell></TableCell>
													<TableCell
														component="th"
														scope="row"
														className={classes.buttonGroup}>
														<Chip
															label={item.published_at ? 'Published' : 'Draft'}
														/>
														<Link to={`/admin/edition/edit/${item.id}`}>
															<Button>
																<EditIcon />
															</Button>
														</Link>
														<PermissibleRender
															userPermission={userRole}
															requiredPermissions={["dev", "editor"]}
														>
															<Button onClick={() => handleDelete(item)}>
																<DeleteIcon />
															</Button>
														</PermissibleRender>
													</TableCell>
												</TableRow>
											))}
										</>
									) : (
										<Info
											classes={classes}
											content="No data under this section :("
										/>
									)}
								</>
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						className={classes.pagination}
						count={pageCount}
						page={page}
						onChange={handlePageChange}
					/>
				</>
			)}
			<ConfirmationDialog
				open={deleteDialogOpen}
				handleClose={() => setDeleteDialogOpen(false)}
				handleSubmit={handleDeleteSubmit}
				alertSeverity={'warning'}
				title="Hold Up!"
				textContent={`Are you sure you want to delete ${focusItem === null ? '' : focusItem.title
					}`}
				alertContent={<>This will permenantly delete this edition</>}
				confirmButtonText="Yes, I'm sure"
				cancelButtonText="Cancel"
			/>
		</>
	)
}

export default EditionPanel
